import React from "react";

export function dynamicColors() {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgba(" + r + "," + g + "," + b + ",0.5)";
};
export const borderColors = [
  'rgba(14, 79, 111, 1)',       // Blue  
  'rgba(0, 176, 78, 1)',       // Green
  'rgba(178, 212, 224, 1)',       // Light Blue
  'rgba(212, 55, 144, 1)',       // Dark Pink
  'rgba(253, 192, 197, 1)',       // Light Pink
  'rgba(0, 0, 0, 1)',       // Black 
  // the blue, green, light blue , dark pink, light pink, and then black
  'rgba(102, 16, 242, 1)',      // Purple
  'rgba(111, 66, 193, 1)',      // Indigo
  'rgba(232, 62, 140, 1)',      // Pink
  'rgba(220, 53, 69, 1)',       // Red
  'rgba(253, 126, 20, 1)',      // Orange
  'rgba(255, 193, 7, 1)',       // Yellow
  'rgba(32, 201, 151, 1)',      // Teal
  'rgba(23, 162, 184, 1)',      // Cyan
  'rgba(52, 58, 64, 1)',        // Dark Gray
  'rgba(108, 117, 125, 1)',     // Gray
  'rgba(253, 0, 0, 1)',         // Crimson
  'rgba(255, 20, 147, 1)',      // Deep Pink
  'rgba(255, 69, 0, 1)',        // Orange Red
  'rgba(255, 165, 0, 1)',       // Orange
  'rgba(255, 140, 0, 1)',       // Dark Orange
  'rgba(255, 215, 0, 1)',       // Gold
  'rgba(50, 205, 50, 1)',       // Lime Green
  'rgba(0, 128, 0, 1)',         // Green
];
export const backgroundColors= [
  'rgba(14, 79, 111,0.5)',       // Blue  
  'rgba(0, 176, 78, 0.5)',       // Green
  'rgba(178, 212, 224, 0.5)',       // Light Blue
  'rgba(212, 55, 144, 0.5)',       // Dark Pink
  'rgba(253, 192, 197, 0.5)',       // Light Pink
  'rgba(0, 0, 0, 0.5)',       // Black 
  
  'rgba(102, 16, 242, 0.5)',      // Purple
  'rgba(111, 66, 193, 0.5)',      // Indigo
  'rgba(232, 62, 140, 0.5)',      // Pink
  'rgba(220, 53, 69, 0.5)',       // Red
  'rgba(253, 126, 20, 0.5)',      // Orange
  'rgba(255, 193, 7, 0.5)',       // Yellow
  'rgba(32, 201, 151, 0.5)',      // Teal
  'rgba(23, 162, 184, 0.5)',      // Cyan
  'rgba(52, 58, 64, 0.5)',        // Dark Gray
  'rgba(108, 117, 125, 0.5)',     // Gray
  'rgba(253, 0, 0, 0.5)',         // Crimson
  'rgba(255, 20, 147, 0.5)',      // Deep Pink
  'rgba(255, 69, 0, 0.5)',        // Orange Red
  'rgba(255, 165, 0, 0.5)',       // Orange
  'rgba(255, 140, 0, 0.5)',       // Dark Orange
  'rgba(255, 215, 0, 0.5)',       // Gold
  'rgba(50, 205, 50, 0.5)',       // Lime Green
  'rgba(0, 128, 0, 0.5)',         // Green
];
export function hexToRgbA(hex, alpha){
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+alpha+')';
  }
  throw new Error('Bad Hex color code' + hex);
}
// const [chartState, setChartState] = useState(undefined);
export function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}
export function randomScalingFactor() {
  return (randomNumber(188,479)/100).toFixed(2);
};


// sort array ascending
export const asc = arr => arr.sort((a, b) => a - b);

export const sum = arr => arr.reduce((a, b) => a + b, 0);

export const mean = arr => sum(arr) / arr.length;

// sample standard deviation
export const std = (arr) => {
    const mu = mean(arr);
    const diffArr = arr.map(a => (a - mu) ** 2);
    return Math.sqrt(sum(diffArr) / (arr.length - 1));
};

export const quantile = (arr, q) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

export const NumberComponent = React.forwardRef(function NumberComponent(
  props,
  ref
) {
  const { value } = props;
  return (
    <div {...props} ref={ref}>
      {`${value.toFixed(2)}`}
    </div>
  );
});
export const PriceComponent = React.forwardRef(function PriceComponent(
  props,
  ref
) {
  const { value } = props;
  return (
    <div {...props} ref={ref}>
      {value > 0 ? `$${value.toFixed(2)}` : `---`}
    </div>
  );
});
export const printSize = (size) => {
  return size&&size.trim().length > 0 ? size : "---";
};
export const price100g = (price, standardSize) =>{
  return  standardSize!==0?(+price/standardSize)*100:0;
}
export const PercentComponent = React.forwardRef(function PercentComponent(
  props,
  ref
) {
  const { value, notdot4zero } = props;
  return (
    <div {...props} ref={ref}>
      {value > 0 ? `${(+value*100).toFixed(2)}%` : `${(notdot4zero?'0.00%':'---')}`}
    </div>
  );
});

 //Find mode in array number
export const findMode = (arr)=> {
  var numMapping = {};
  var greatestFreq = 0;
  var mode;
  arr.forEach(function findMode(number) {
      numMapping[number] = (numMapping[number] || 0) + 1;

      if (greatestFreq < numMapping[number]) {
          greatestFreq = numMapping[number];
          mode = number;
      }
  });
  return +mode;
}

const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}
export function convertToViewDate(inputDate){
  const tempDate = new Date(inputDate) 
  const date = tempDate.getDate();
  const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][tempDate.getMonth()]
  const year = tempDate.getFullYear();
  return `${month} ${date}${nth(date)}, ${year}`;
} 
export const multiSearchAnd = (text, subString) => (
  subString.trim().replace(/\s/g, ' ').split(' ').every((el) => {
    return text.match(new RegExp(el,"i"))
  })
)
export function getMean (array) {
  if (Array.isArray(array)){
    const n = array.length
    return array.reduce((a, b) => a + b,0) / n
  }else{
    return 0
  }
}
export function getStandardDeviation (array) {
  if (array.length-1>0){
    const n = array.length-1
    const mean = getMean(array)
    return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b,0) / n)
  }else{
    return 0
  } 
}
export function truncate( str, n, useWordBoundary ){
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n-1); // the original check
  return (useWordBoundary 
    ? subString.substr(0, subString.lastIndexOf(" ")) 
    : subString) + "...";
};

export function displayTags(tagsString) {
  var tagsArray = tagsString.split(', ');
  var container = document.getElementById('tags-container');

  tagsArray.forEach(function(tag) {
      var tagElement = document.createElement('span');
      tagElement.textContent = tag;
      tagElement.style.margin = '5px';
      tagElement.style.padding = '5px';
      tagElement.style.border = '1px solid #ddd';
      tagElement.style.borderRadius = '5px';
      tagElement.style.backgroundColor = '#f0f0f0';
      container.appendChild(tagElement);
  });
}