import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import MLink from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import axios from "axios";
import envConfig from "../bin/env.config";

import { TextField } from "@material-ui/core";
import { Copyright,RightPanel } from "../components/Common/Common";

import { useDispatch, useSelector } from "react-redux";
// import {
//     setAuthToken
//   } from "../store/actions/sessionAction";

const useStyles = makeStyles((theme) => ({  
  container: {
    padding: "0",
    margin: "0",    
    display: "flex",
    width:"100%",
    // height:'100vh',
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }
  },
 
  containerLeft:{    
    display: "flex",
    backgroundColor:'#204F6C',
    width:"50%",
    height:'100vh',
    alignItems: "center",
    alignContent:"center",
    justifyContent: "center",
    justifyItems:"center",
    color:"white",
    [theme.breakpoints.down("sm")]: {
      width:"100%",
    }
  },
  contentLeft:{
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",   
  },
  logoLeft:{
    width:"100%",
    paddingBottom:theme.spacing(3),
  },
  containerRight:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent:"center",
    justifyContent: "center",
    justifyItems:"center",
    width:"50%", 
    height:'100vh',
    backgroundColor:theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      width:"100%",
    }
  },   
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),    
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress:{
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '65%',
    marginTop: -12,
    marginLeft: -12,    
  },
  mLink:{
    color:"white",
    whiteSpace: "nowrap"    
  },
  textBox:{
    marginTop: '16px',
    marginBottom: '8px',
    backgroundColor:"white",
    // padding: '18px 14px',
    borderRadius: '4px',
    '& div':{
      backgroundColor:"white",
      borderRadius: '4px',
    },
    '& input':{
      padding: '27px 12px 10px',
      borderBottom:'0'
      // marginTop: '10px',
      // marginBottom: '10px',
    }
  }, 
  mlinkContainer:{
    display: "flex",
    justifyContent:"space-between",
    [theme.breakpoints.down("sm")]: {
      flexWrap:"wrap",
    }
  },
  errorMessage:{
    width:"100%",
    display: "box",
    color:"red",
    fontSize: '1rem'
  }
}));

export default function ResetPasswordRequestForm() {
  const classes = useStyles();
  const [errors,setErrors] = useState({});  
  const [email, setEmail] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const validRequireFields = () =>{
    let requiredErrors = {}
    if (!email) {
      requiredErrors[ 'email' ] = `Please enter correct an email`
    }
    setErrors(requiredErrors);
    return Object.keys(requiredErrors).length===0;
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    };
  const onResetPassword = () => {
    
    
  };
  const renderLeft=()=>{
    return (
      <Container maxWidth="xs">
        <CssBaseline />
        <div className={classes.contentLeft}>      
          {/* <LockOutlinedIcon /> */}
          <img
            src="/resources/images/BC-Analytics-Logo-white-400.png"
            alt="BetterCart Analytics Logo"
            className={classes.logoLeft}
          />  
          <form className={classes.form}  onSubmit={handleSubmit} noValidate >
            {errors['password']&&(<div className={classes.errorMessage} >{errors['password']}</div>)}
            <TextField
              error={errors['email']}
              className={classes.textBox}
              required
              label="Email"
              variant="filled"
              fullWidth
              id="email"                     
              name="email"
              autoFocus
              color="secondary"
            />     
          
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Send an email
              {isWaiting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </Button>           
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        
      </Container>
    );
  }
  
  return(
    <div className={classes.container}>
      <div className={classes.containerLeft}>
          {renderLeft()}
      </div>  
      <div className={classes.containerRight}>
      <RightPanel/>
      </div>
    </div>  

  )
}

