import React from 'react';

const TagsDisplay = ({ tagsString }) => {
    // Split the string into an array of tags
    const tags = tagsString.split(',');

    return (
        <div>
            {tags.map((tag, index) => (
                <span key={index} style={tagStyle}>
                    {tag}
                </span>
            ))}
        </div>
    );
};

// Styling for the tags
const tagStyle = {
    margin: '5px',
    padding: '5px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    backgroundColor: '#f0f0f0',
    whiteSpace: 'nowrap'
};

export default TagsDisplay;
