import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectADeviceDialog from "./SelectADeviceDialog";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  btnSelected: {
    textTransform: "capitalize",
    marginLeft:"10px"
  },
}));
// import StoreIcon from "@material-ui/icons/Store";

export function SelectADevice(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // console.log(productSelected);
  return (
    <>
      <Button
        variant="outlined"
        className={classes.btnSelected}
        color="primary"
        onClick={handleClickOpen}
       endIcon={<ExpandMoreIcon/>}
      >
       Select A Device
      </Button>
      <SelectADeviceDialog
        title={"Select a device"}
        open={open}
        handleClose={handleClose}
        {...props}
      />
    </>
  );
}
export const MemoizedSelectADevice = React.memo(SelectADevice);
