import { combineReducers } from 'redux'
import provinceReducer from './provinceReducer'
import cityReducer from './cityReducer'
import storeReducer from './storeReducer'
import productReducer from './productReducer'
import customerProductReducer from './customerProductReducer'
import categoryReducer from './categoryReducer'
import menuReducer from './menuReducer'
import weekReducer from './weekReducer'
import reportReducer from './reportReducer'
import cartReducer from './cartReducer'
import columnReducer from './columnReducer'
import sessionReducer from './sessionReducer'
import myProductReducer from './myProductReducer'
import swupdate from './swupdate'

export default combineReducers({
  province: provinceReducer,
  city: cityReducer,
  store: storeReducer,
  product: productReducer,
  customerProduct: customerProductReducer,
  category:categoryReducer,
  menu:menuReducer,
  week:weekReducer,
  report:reportReducer,
  cart:cartReducer,
  setting:columnReducer,
  session:sessionReducer,
  myProduct:myProductReducer,
  swupdate:swupdate
})