import {SET_PRODUCTLISTS, SELECT_PRODUCTLIST,PRODUCTLIST_ERROR,SET_SINGLE_PRODUCT_PRICING,RESET_SINGLE_PRODUCT_PRICING} from '../types'
import axios from "axios";
import envConfig from "../../bin/env.config";
export const setSingleProductPricing = (singleProductPricing) => ({
  type: SET_SINGLE_PRODUCT_PRICING,
  payload: singleProductPricing
});
export const resetSingleProductPricing = () => ({
  type: RESET_SINGLE_PRODUCT_PRICING,
});
export const setProductLists = (productLists = []) => ({
  type: SET_PRODUCTLISTS,
  payload: productLists
});

export const selectProductList = (productList) => {
   return (dispatch)=> dispatch( {
        type: SELECT_PRODUCTLIST,
        payload: productList
    })
}


export const fetchProductLists =  () => {
  // const  productLists=[`CANSIM Report`,`Thanksgiving Report`]  
  return (dispatch, getState) => { 
    return new Promise((resolve, reject) => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/reports/report-list`;
      axios.get(url,{
        headers: {
          'Authorization': JSON.stringify(getState().session.token)
        } 
      }).then((result) => {
        let productLists = result.data.data;
        dispatch({
          type: SET_PRODUCTLISTS,
          payload: productLists
        });
        dispatch( {
          type: SELECT_PRODUCTLIST,
          payload: productLists[0]
        })
        resolve(productLists);
      }).catch(error => {
        reject(error);
        dispatch({
          type: PRODUCTLIST_ERROR,
          payload: error,
        });
      })
    });
  }
};