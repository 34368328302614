import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";


import { MemoizedMenu } from "../components/Menu";
import PricePointMap from "../components/SingleReportPricingMap/PricePointMap";
import Toolbar from "../components/SingleReportPricingMap/Toolbar.js";
import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { format,addDays, parseISO } from 'date-fns';
import { useSelector } from "react-redux";
const topMenu = 64;
const toolBar = 142;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },  
  mapContainer: {
    height: `calc(100vh - ${topMenu + toolBar + 1}px)`,
    
  }, 
  contentDataTable: {
    marginTop: 15,
  },
}));
const colors = ['#fdc0c5','#d43790','#b2d4e0','#00b04e','#0e4f6f','#000000']
export default function SingleProductPricingMap() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector(state => state);
  const productSelected = state.product.productSelected;
  const weekSelected = state.week.weekSelected;
  
  const [dataSource, setDataSource] = useState({});
  useEffect(() => {
    
    const fetchDataSource = async () => {      
      setIsLoading(true);
      const toDate = format(addDays(parseISO(weekSelected.first),6),'yyyy-MM-dd');
      const postData = {
        productMatchingGroupID: productSelected
          ? productSelected.id
          : 0,
        to_date: toDate,  
      };
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/price-points`;
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });
      
      //get min max
      let dataPoints = result.data.data;
      var lowest = Number.POSITIVE_INFINITY;
      var highest = Number.NEGATIVE_INFINITY;
      var lowestIndex = 0;
      var highestIndex = 0;
      var tmp;
      for (var i=dataPoints.length-1; i>=0; i--) {
          tmp = dataPoints[i].price;
          if (tmp < lowest){ lowest = tmp; lowestIndex = i}
          if (tmp > highest){ highest = tmp; highestIndex = i}
      }
      //split to 5
      const tempPriceRangeColors = [] 
      const distance = Math.ceil((highest-lowest)*100/5)/100;
      if (distance > 0.01){
        //Incase distance >0.01
        for (var i=0; i<=4; i++) {
          let min = lowest + distance*i;
          let max = lowest + distance*(i+1)-(i==4?0:0.01);
          tempPriceRangeColors.push({
            color:colors[i],
            min: min,
            max: max
          })
        }
      }else{
        tempPriceRangeColors.push({
          color:colors[0],
          min: lowest,
          max: highest
        })
      }     
      
      setDataSource({points: dataPoints, priceRangeColors:tempPriceRangeColors,
         summary:{
            currentMin:dataPoints[lowestIndex],
            currentMax:dataPoints[highestIndex],
         } 
      });

      setIsLoading(false);
    };
    productSelected && fetchDataSource();
  }, [productSelected,weekSelected]);
  // if (!productSelected) {
  //   return <Redirect to="/login" />;
  // }
  return (
    <div className={classes.root}>
      <MemoizedMenu goBackButtonEnable={true} title={"Single Product Price Map  "} 
          selectedProductEnable 
          weekSelectedButtonEnable 
          // addBrandButtonEnable 
          selectedProvinceEnable
          />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {productSelected && (
          <Container maxWidth={false} className={classes.container}>
            <Toolbar productSelected={productSelected} summary={dataSource.summary}/>

            <Divider />
            <Grid container spacing={3} className={classes.mapContainer}>
              {/* Chart */}

              <Grid item xs={12} md={12} lg={12} >
                {/* <ModeRegularPrice
                      storesSelected={props.storesSelected}
                      productSelected={productSelected}
                    /> */}
                {isLoading ? (
                  <Loading />
                ) : (
                  <PricePointMap points={dataSource.points} priceRangeColors={dataSource.priceRangeColors}/>
                )}
              </Grid>
            </Grid>
          </Container>
        )}
      </main>
    </div>
  );
}
