
import {GET_MY_PRODUCTS, GET_MY_PRODUCTS_COMPARISON,MY_PRODUCT_ERROR,SET_SHOW_REPORT_DATA,SET_SHOW_STORE_COUNT_COLUMN} from '../types'
const initialState = {
    myProducts:[],
    myProductComparisons:[],    
    loading:true,
    showReportData:true,
    showStoreCountColumn:false,
}

export default function(state = initialState, action){
    switch(action.type){
        case GET_MY_PRODUCTS:
            return {
                ...state,
                myProducts:action.payload,
                loading:false
            }         
        case GET_MY_PRODUCTS_COMPARISON:
            return {
                ...state,
                myProductComparisons:action.payload,
                loading:false
            } 
        case MY_PRODUCT_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        case SET_SHOW_REPORT_DATA:
                return{
                    ...state,
                    showReportData: action.payload, 
                }    
        case SET_SHOW_STORE_COUNT_COLUMN:
                return{
                    ...state,
                    showStoreCountColumn: action.payload, 
                }    
        default: return state
    }

}