import React, { useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectStoresDialog from "./SelectStoresDialog";
import SelectBannersDialog from "./SelectBannersDialog";
import StoreIcon from "@material-ui/icons/Store";
import VisibilityIcon  from "@material-ui/icons/Visibility";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useDispatch,useSelector } from "react-redux";
import {
  selectByBanner
  } from "../../store/actions/storeAction";


const useStyles = makeStyles((theme) => ({
  root: {
    width:"100%",
    // padding:"0px 10px",
    margin:"8px 0px",
    border:"1px solid white",
    '&:hover':{
      border:"1px solid white",
    }    
},
label:{
    textTransform: "capitalize",
    color:"#fff",
    justifyContent:"space-between",
    // fontSize: '1.25rem',       
    // fontWeight: "500",
    '& >.MuiButton-iconSizeMedium > *:first-child ':{
      fontSize: '2rem',
    }
  }
}));
// import StoreIcon from "@material-ui/icons/Store";

export function SelectByBannerButton(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();  
  const store =  useSelector(state => state.store) 
  const dispatch = useDispatch()
  const handleByBannerSelected = (selectValue) => {
    dispatch(selectByBanner(selectValue));
    closeByBanner();
  };
  const handleByBannerClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeByBanner = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        variant="outlined"
        classes={{root:classes.root, label:classes.label}}
        color="primary"
        onClick={handleByBannerClickOpen}
        endIcon={<ExpandMoreIcon/>}
        startIcon={<VisibilityIcon />}
      >
        {store.byBanner?'By Banner':'By Stores'}
      </Button>
      
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeByBanner}
      >       
        <MenuItem onClick={()=>handleByBannerSelected(true)} >By Banner</MenuItem>
        <MenuItem onClick={()=>handleByBannerSelected(false)}>By Store</MenuItem>
      </Menu>
    </>
  );
}

export const MemoizedSelectStores = React.memo(SelectByBannerButton);
