import React, {useState} from "react";
import { MapContainer, TileLayer, CircleMarker, Popup, LayersControl,LayerGroup,FeatureGroup } from 'react-leaflet';
import '../components/Map/map.css';
// import { Icon } from "leaflet";
import Legend from "../components/Map/Legend";
import data from "../components/Map/data.json";

const popupContent = {
  textAlign: "center",
  height: "350px",
  marginTop: "30px"
};
const popupHead = {
  fontWeight: "bold",
  fontSize: "22px"
};

const popupText = {
  fontSize: "15px",
  marginBottom: "20px"
};

const okText = {
  fontSize: "15px"
};
const priceRangeColors = [{color:'#0e4f6f', min:0, max:1.99},
                          {color:'#00b04e', min:2, max:2.99},
                          {color:'#b2d4e0', min:3, max:3.99},
                          {color:'#d43790',min:4, max:4.99},
                          {color:'#fdc0c5',min:5, max:5.99},
                          {color:'#000000',min:6, max:6.99}]
const getColor = (price)=> {
  for (var i=0; i < priceRangeColors.length; i++) {
    if ((+priceRangeColors[i].min <= price) && (+priceRangeColors[i].max >= price)) {
          return priceRangeColors[i].color;
    }
  }
  // let colorIndex =Math.trunc(price)+1;
  return priceRangeColors[0].color;
}
export default function MapPage() {
  const [map, setMap] = useState(null);

  return (
    <MapContainer 
      center={[52.146973, -106.647034]} //58.9777895,-96.4919846,
      zoom={5} 
      scrollWheelZoom={false}
      whenCreated={setMap}
      >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <LayersControl position="topright">
      <LayersControl.Overlay checked name="Layer group with circles">
          <LayerGroup>
      {data.map((elem, i) => {
            return (
              <CircleMarker
                key={i}
                center={{lat:elem.lat, lng: elem.long}}
                pathOptions={{ color: getColor(elem.price) }}
                fillColor={getColor(elem.price)}
                fillOpacity={0.8}
                radius={5}>
                <Popup className="request-popup">
                  <div style={popupContent}>
                    <img
                      src={elem.company_logo_link}
                      width="150"
                      max-height="70"
                    />
                    <div className="m-2" style={popupHead}>
                      {elem.name}
                    </div>
                    <span style={popupText}>
                      {elem.address}
                    </span>
                    <div className="m-2" style={okText}>
                      ${elem.price.toFixed(2)}
                    </div>
                  </div>
                </Popup>
              </CircleMarker>              
            )
          })}
        </LayerGroup>
        </LayersControl.Overlay>   
      </LayersControl>
          <Legend map={map} priceRangeColors={priceRangeColors}/>
    </MapContainer>
  );
}