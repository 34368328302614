
import {INIT_CART} from '../types'
const initialState = {
    cart:[],
    loading:true,
}

export default function(state = initialState, action){

    switch(action.type){

        case INIT_CART:
            return {
                ...state,
                cart:action.payload,
                loading:false
            }           
        default: return state
    }

}