import React, { useState, useEffect} from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { PriceComponent,PercentComponent } from "../../utility/utility";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";

import HistoricalPricingAllDialog from "./HistoricalPricingAllDialog";

import LineGraph from "../Charts/LineGraph";
import BannersLineGraph from "./BannersLineGraph";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BarChartIcon from '@material-ui/icons/BarChart';
// import RequestPriceUp2DateButton from "../Common/RequestPriceUp2DateButton"
// import TimeAgo from "react-timeago";
// import classes from "*.module.css";
import axios from "axios";
import envConfig from "../../bin/env.config";
import Loading from "../../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  setSingleProductPricing
  } from "../../store/actions/reportAction";
import { getStandardDeviation,getMean,NumberComponent, price100g } from "../../utility/utility";
import { format } from "date-fns";

import ExportButtons  from "../Common/ExportButtons";
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    // maxHeight: "calc(100vh - 128px)",
    maxHeight: "calc(100vh - 232px)",
    boxShadow: "none",
    overflow: "auto"
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
  lineGraphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  content:{
    // overflowX: "hidden",
    // overflowY: "auto"
  },
  trHeader: {
    height: "64px",
  },
  thHeader: {
    backgroundColor: "#DDD",
    fontSize: "1rem",
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
  currentSize: {
    whiteSpace: "nowrap",
  },
  currentUpdatedAt: {
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    whiteSpace: "nowrap",
  },
  storeName: {
    // fontWeight: "bold",
    whiteSpace: "nowrap",
  },
  storeAddress: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: "0.875rem",
    whiteSpace: "nowrap",
  },
}));
const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(246, 246, 246,0.3)',
    // padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


export default function RegularPrice({ 
  companyColours
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [liveProductInputData, setLiveProductInputData] = useState({});
  const [dialogTitle, setDialogTitle] = useState("Historical Pricing");
  const [isCurrent, setIsCurrent] = useState(true);

  const state = useSelector((state) => state);
  const citySelected = state.city.citySelected; 
  const user = state.session.user;

  const settingColumnsArray = state.setting.columns.single_product_report;
  
  const [expanded, setExpanded] = useState('panel1');
  const [isReady, setIsReady] = useState(false);
  const [chartHeight, setChartHeight] = useState(0);
  const [tableContainerMaxHeight, setTableContainerMaxHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  
  // const [includeSalePrices, setIncludeSalePrices] = useState(0);
  const includeSalePrices = 0;
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  // console.log(state)
  const productSelected = state.product.productSelected;
  const storesSelected = state.store.storesSelected
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner
 
  const [dataSource, setDataSource] = useState([]);
  const [bannerDataLineGraph, setBannerDataLineGraph] = useState({
    labels:[],
    bannerDatas:[]
  });
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  // const storesSelected = state.store.storesSelected;
    // console.log(settingColumnsArray);
  const settingColumnsObject = settingColumnsArray.reduce(
    (acc, curr) => ((acc[curr.code] = curr), acc),
    {}
  );
  const countSelectedColumn = settingColumnsArray.filter(
    (obj) => obj.selected
  ).length;
  const printSize = (size) => {
    return size.trim().length > 0 ? size : "---";
  };
  const handleCurrentPriceOpen = (liveProductInput) => {
        
    setLiveProductInputData(liveProductInput);
    setDialogTitle(`Price History - ${liveProductInput.companyName}`);
    setIsCurrent(true)    
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCurrentRegularOpen = (liveProductInput) => {
    setLiveProductInputData(liveProductInput);
    setDialogTitle(`Reported Regular Price History - ${liveProductInput.companyName}`);
    setIsCurrent(false)
    setOpen(true);
  };
  const externalLink = (link, companyId, storeCode) => {
    // console.log(`${link} - ${companyId} - ${storeCode}`);

    let extLink = "";
    if (link) {
      if (companyId === 13 && storeCode.length <= 4) {
        let paths = link.split('/');
        let storeCodeInPath=paths[6];
        extLink = link.replace(storeCodeInPath, storeCode);
        // extLink = link.replace("953", storeCode);
      } else {
        extLink = link;
      }
    }
    return extLink;
  };
  useEffect(() => {
    const fetchDataSource = async () => {      
      setIsLoading(true);
      const postData = {
        productMatchingGroupID: productSelected
          ? productSelected.id
          : 0,
        includeSalePrices: +includeSalePrices, //setAllway true
        storeIDs: storesSelected.map((store) => store.id),
        bannerIDs: bannersSelected,  
        byBanner: byBanner,  
        fromDate: fromDate,
        toDate: toDate,  
      };
      // console.log(postData)
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/regular-price-in-store`;
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });
        let  singleProductPricing = {
          currentMin:{
              value:0,
              chain: '' //company Name
          },
          currentMax:{
              value:0,
              chain: '' //company Name
          },
          historicalMin:{
              value:0,
              chain: '' //company Name
          },
          historicalMax:{
              value:0,
              chain: '' //company Name
          },
          stdList: [], 
          currentPriceList: [], 
        }
        let bannerDataLineGraph ={
          labels:[],
          bannerDatas:[]
        }
        let prepareData = result.data.data.map((row) => {
                                // console.log(row)
                                let retRow = {
                                  ...row,
                                  spps:[],
                                  currentPrice:0,
                                  currentSize:'',
                                  currentStandardSize:'',
                                  currentPrice100g:0,
                                  currentUpdatedAt:'',
                                  indexPrevReg:-1,
                                  extLink:'',
                                  liveProductInput:{},
                                  min_100g:'',
                                  max_100g:'',
                                  most_common_100g:'',
                                  calculated_reg_100g:'',
                                  mean_ea:0,
                                  mean_100g:0,
                                  std:0,
                                  std_100g:0,
                                  // storeAddress:'',
                                  percent_changed:0,// current_price with current_min
                                  percent_changed_100g:0,// current_price with current_min
                                  productName:productSelected.name
                                }
                                let flags = [],
                                  sppsPrices = [],
                                  sppsPrices100 = [],
                                  l = row.spp_record.length,
                                  i,
                                  bannerData={
                                    companyId:0,
                                    companyName:"",
                                    avgPriceMonthData:{}
                                  };
                                // console.log(row);
                                for (i = 0; i < l; i++) {
                                  const items = row.spp_record[i].split(";");
                                  if (flags[items[0]]) continue;
                                  flags[items[0]] = true;
                                  let standardSize = +items[6];
                                  let dbCurrentPrice100g = +items[9];//check if exist in the database
                                  let currentPrice100g = dbCurrentPrice100g?dbCurrentPrice100g:price100g(+items[2], standardSize);
                                  let regularPrice100g = price100g(+items[3], standardSize);
                                  let currentPriceHistorical = Math.round(+items[2] * 100) / 100
                                  let regularPriceHistorical = Math.round(+items[3] * 100) / 100
                                  let currentPriceHistorical100 = Math.round(+currentPrice100g * 100) / 100
                                  let regularPriceHistorical100 = Math.round(+regularPrice100g * 100) / 100
                                  // console.log(Date.parse(items[0]))
                                  retRow.spps.push({
                                    created_at: items[0],
                                    is_onsale: +items[1],
                                    current_price: currentPriceHistorical,
                                    regular_price: regularPriceHistorical,
                                    size: items[5],
                                    standard_size: +items[6],
                                    current_price_100g: currentPriceHistorical100, 
                                    regular_price_100g: regularPriceHistorical100, 
                                    unit: items[8],
                                    product_id: +items[7],
                                  });
                                  
                                  //init singleProductPricing summary report for history
                                  sppsPrices.push(currentPriceHistorical);
                                  sppsPrices100.push(currentPriceHistorical100);
                                  if(singleProductPricing.historicalMin.value==0||singleProductPricing.historicalMin.value>currentPriceHistorical){
                                    singleProductPricing.historicalMin.value = currentPriceHistorical
                                    singleProductPricing.historicalMin.chain = retRow.company                                                               
                                  } 
                                  if(singleProductPricing.historicalMax.value==0||singleProductPricing.historicalMax.value<currentPriceHistorical){
                                    singleProductPricing.historicalMax.value = currentPriceHistorical
                                    singleProductPricing.historicalMax.chain = retRow.company                                                               
                                  }
                                  // banner Line Graph
                                  let monthYearCollected = format(Date.parse(items[0]+"T12:00:00.000Z"),"yyyy-MM-15")
                                  bannerDataLineGraph.labels.push(monthYearCollected) 
                                  bannerData.avgPriceMonthData[monthYearCollected] = bannerData.avgPriceMonthData[monthYearCollected]?Math.round(((bannerData.avgPriceMonthData[monthYearCollected]+currentPriceHistorical)/2)*100)/100:currentPriceHistorical                           
                                }
                                bannerData.companyName = row.company
                                bannerData.companyId = +row.company_id
                                bannerDataLineGraph.bannerDatas.push(bannerData)  
                                let tempDate = format(
                                  Date.parse(retRow.spps[0].created_at+"T12:00:00.000Z"),
                                  "PP"
                                ).split(",");
                                const currentUpdatedAt =
                                  tempDate[0] + " '" + tempDate[1].slice(-2);                          
                                // console.log(retRow.spps[0].created_at)    
                                // console.log(tempDate)    
                                // console.log(currentUpdatedAt)    
                                retRow.currentPrice = retRow.spps[0].current_price;
                                retRow.currentSize = retRow.spps[0].size;
                                retRow.currentStandardSize = retRow.spps[0].standard_size;
                                retRow.currentPrice100g = retRow.spps[0].current_price_100g;
                                retRow.currentUpdatedAt = currentUpdatedAt;                        
      
                                const isPrevReg = (element) => element.regular_price > 0;
                                retRow.indexPrevReg = retRow.spps ? retRow.spps.findIndex(isPrevReg) : -1;
                                retRow.extLink = externalLink(
                                  row.links[0],
                                  +row.company_id,
                                  row.store_code
                                );
                                retRow.liveProductInput = {
                                  companyName:row.company,
                                  storeId:row.store_id,
                                  productId:retRow.spps[0].product_id,
                                  productUrl:retRow.extLink,
                                }
                                retRow.min_100g = price100g(retRow.current_min,retRow.currentStandardSize)
                                retRow.max_100g = price100g(retRow.current_max,retRow.currentStandardSize)
                                retRow.most_common_100g = price100g(retRow.current_mode,retRow.currentStandardSize)
                                retRow.calculated_reg_100g = price100g(retRow.current_max,retRow.currentStandardSize)
                                
                                retRow.reported_reg_ea = retRow.indexPrevReg >= 0? retRow.spps[retRow.indexPrevReg].regular_price:''
      
                                retRow.reported_reg_100g = retRow.indexPrevReg >= 0? price100g(
                                  retRow.spps[retRow.indexPrevReg].regular_price,
                                  retRow.spps[retRow.indexPrevReg].standard_size
                                ):''

                                retRow.reported_current_ea = retRow.indexPrevReg >= 0? +retRow.spps[retRow.indexPrevReg].current_price:0
                                
                                retRow.percent_changed =  retRow.current_min >= 0? ((retRow.current_max-retRow.current_min)/retRow.current_min):'---'
                                retRow.percent_changed_100g =  retRow.current_min_100g >= 0? ((retRow.current_max_100g-retRow.current_min_100g)/retRow.current_min_100g):'---'


                                //init singleProductPricing summary report
                                retRow.mean_ea = sppsPrices.length>1?(Math.round(getMean(sppsPrices) * 100) / 100).toFixed(2):'---';
                                retRow.mean_100g = sppsPrices100.length>1?(Math.round(getMean(sppsPrices100) * 100) / 100).toFixed(2):'---';
                                
                                retRow.std = sppsPrices.length>1?(Math.round(getStandardDeviation(sppsPrices) * 100) / 100).toFixed(2):'---';
                                retRow.std_100g = sppsPrices100.length>1?(Math.round(getStandardDeviation(sppsPrices100) * 100) / 100).toFixed(2):'---';
                                
                                if(singleProductPricing.currentMin.value==0||singleProductPricing.currentMin.value>retRow.currentPrice){
                                  singleProductPricing.currentMin.value = retRow.currentPrice
                                  singleProductPricing.currentMin.chain = retRow.company                                                               
                                } 
                                if(singleProductPricing.currentMax.value==0||singleProductPricing.currentMax.value<retRow.currentPrice){
                                  singleProductPricing.currentMax.value = retRow.currentPrice
                                  singleProductPricing.currentMax.chain = retRow.company                                                               
                                }
                                singleProductPricing.stdList.push(retRow.std)
                                singleProductPricing.currentPriceList.push(retRow.currentPrice)
                                //storeAddress: 
                                // let tempLabels = [...labels]
                                bannerDataLineGraph.labels = [...new Set(bannerDataLineGraph.labels)].sort()
                                // console.log(bannerDataLineGraph)
                                return retRow; 
                              });  

      setDataSource(prepareData);
      dispatch(setSingleProductPricing(singleProductPricing));
      setBannerDataLineGraph(bannerDataLineGraph)
      setIsLoading(false);
    };
    productSelected.id && fetchDataSource();
  }, [productSelected,storesSelected,bannersSelected,byBanner, fromDate, toDate]);
  
  useEffect(() => {




    let countCompany = dataSource.length-7?(dataSource.length-7):0 
    let tmpChartHeight =  countCompany*25+250
    let tmpTableContainerMaxHeight = byBanner?`calc(100vh - ${tmpChartHeight+232}px)`:"calc(100vh - 232px)"
    if(byBanner&&(!expanded)){
      tmpTableContainerMaxHeight= "calc(100vh - 288px)"
    }
    setChartHeight(tmpChartHeight)
    setTableContainerMaxHeight(tmpTableContainerMaxHeight)
    setIsReady(true)
  },[dataSource,byBanner,expanded])
  
  const headersExport=[
    { label: "Company", key: "company" },
    { label: "Store", key: "store" },
    { label: "Address", key: "store_address" },
    { label: "Product", key: "productName" },
    { label: "Size", key: "currentSize" },
    { label: "Standard Size", key: "currentStandardSize" },
    { label: "Price $/ea", key: "currentPrice" },
    { label: "Price $/100g", key: "currentPrice100g" },
    { label: "Min $/ea", key: "current_min" },
    
    { label: "Min $/100g", key: "min_100g" },
    { label: "Max $/ea", key: "current_max" },
    { label: "Max $/100g", key: "max_100g" },
    { label: "% Change $/ea", key: "percent_changed" },
    { label: "% Change $/100g", key: "percent_changed_100g" },

    { label: "Mean $/ea", key: "mean_ea" },
    { label: "Mean $/100g", key: "mean_100g" },    
    { label: "Standard Deviation /ea", key: "std" },
    { label: "Standard Deviation /100g", key: "std_100g" },
    { label: "Most common $/ea", key: "current_mode" },
    { label: "Most common $/100g", key: "most_common_100g" },
    { label: "Calculated Reg $/ea", key: "current_max" },
    { label: "Calculated Reg $/100g", key: "calculated_reg_100g" },
    { label: "Reported Reg $/ea", key: "reported_reg_ea" },
    { label: "Reported Reg $/100g", key: "reported_reg_100g" },
    { label: "Updated at", key: "currentUpdatedAt" },
  ];
  const productName = productSelected.name||'';
  const filename = "single-report-" + productName.replace(/[^a-z0-9]/gi, '_').toLowerCase()+".csv"
  const configExport={
    headers:headersExport,
    data:dataSource,
    filename:filename,
    target:"_blank",
  }
  //calculate height table
 
  return (
    <>
    {isLoading ? (
      <Loading />
    ) : (
    <>
      <HistoricalPricingAllDialog
        productSelected={productSelected}
        liveProductInputData={liveProductInputData}
        title={dialogTitle}
        open={open}
        handleClose={handleClose}
        isCurrent={isCurrent}
      />
      {byBanner&&bannerDataLineGraph.labels.length>0&&(
      <Accordion className={classes.AccordionClass}  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="price-trend-for-timeframe"
          id="price-trend-for-timeframe"
        >
          <BarChartIcon/><Typography variant="inherit" style={{fontSize:'1rem',fontWeight:'500'}}>Price Trend for Timeframe</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BannersLineGraph bannerDataLineGraph={bannerDataLineGraph} companyColours={companyColours}/>
        </AccordionDetails>
      </Accordion>    
      )}
      <TableContainer component={Paper} 
      
      style={{maxHeight:`${tableContainerMaxHeight}`,boxShadow: "none",overflow: "auto" }}
      square>
        <Table stickyHeader aria-label="regular price report">
          <TableHead>
            <TableRow className={classes.trHeader}>
              <TableCell align="left" className={classes.thHeader}></TableCell>
              {settingColumnsArray
                .filter((r) => r.selected)
                .map((row, index) => {
                  if(!(byBanner&&row.code=='spr.store')){
                    return (
                      <TableCell
                        align="center"
                        key={`column_${index}`}
                        className={classes.thHeader}
                        width={row.width}
                      >
                        <Typography variant="inherit" title={row.title}>{row.name}</Typography>
                      </TableCell>
                    );
                  }else{
                    return ('')
                  }                  
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource &&
              dataSource.map((row, index) => {
                return (
                  <TableRow hover key={index}>
                    <TableCell align="center">
                      <Typography variant="inherit" nowrap>
                        {user&&user.user_type_id===1&&row.extLink && (
                          <a href={row.extLink} target="_blank">
                            <IconButton
                              color="primary"
                              aria-label="product on shop"
                              component="span"
                            >
                              <LaunchIcon />
                            </IconButton>
                          </a>
                        )}
                        {/* <RequestPriceUp2DateButton
                            page="Single product pricing"
                            product={productSelected.name + " - id:"+productSelected.id}
                            city={citySelected.name}
                            store={row.company+' - '+row.store}
                            price={row.currentPrice}
                            collected_date={row.currentUpdatedAt}/> */}
                      </Typography>
                    </TableCell>
                    {settingColumnsObject["spr.company"].selected && (
                      <TableCell align="center">
                        {" "}
                        <img
                          className={classes.storeLogo}
                          src={row.company_logo_link}
                        />
                        {}
                      </TableCell>
                    )}
                    {(!byBanner)&&settingColumnsObject["spr.store"].selected && (
                      <TableCell align="left">
                        <div className={classes.storeName}>{row.store}</div>
                        <div className={classes.storeAddress}>
                          {row.store_address}
                        </div>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.pricing_trend"].selected && (
                      <TableCell align="center">
                        <div className={classes.lineGraphContainer}>
                          <LineGraph
                            key={index}
                            storeProductPrices={row.spps}
                          />
                        </div>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.pricing_trend_100g"]
                      .selected && (
                      <TableCell align="center">
                        <div className={classes.lineGraphContainer}>
                          <LineGraph
                            key={index}
                            storeProductPrices={row.spps}
                            columnField='current_price_100g'
                          />
                        </div>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.on_live_site"]&&settingColumnsObject["spr.on_live_site"].selected && (
                      <TableCell align="center">
                        {row.liveProductInput.productUrl&&(
                          // <LiveProduct  {...row.liveProductInput} isBlockType/>
                          <span></span>
                        )}  
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.current_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          className={classes.hoverClickPopUp}
                          value={row.currentPrice}
                          style={{ color: "#204F6C" }}
                          onClick={() => handleCurrentPriceOpen(row.liveProductInput)}
                        />
                        <div className={classes.currentSize}>
                          <b>{printSize(row.currentSize)}</b>
                        </div>
                        <div className={classes.currentUpdatedAt}>
                          {row.currentUpdatedAt}
                        </div>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.current_100g"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          className={classes.hoverClickPopUp}
                          value={row.currentPrice100g}
                          style={{ color: "#204F6C" }}
                          onClick={() => handleCurrentPriceOpen(row.liveProductInput)}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.min_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.current_min}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                   
                    {settingColumnsObject["spr.min_100g"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.min_100g}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.max_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.current_max}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.max_100g"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.max_100g}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                     {settingColumnsObject["spr.percent_changed"].selected && (
                      <TableCell align="center">
                        <PercentComponent
                          value={row.percent_changed}
                          notdot4zero="true"
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.percent_changed_100g"].selected && (
                      <TableCell align="center">
                        <PercentComponent
                          value={row.percent_changed_100g}
                          notdot4zero="true"
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.mean_ea"].selected && (
                      <TableCell align="center">
                        <Typography variant="inherit">{row.mean_ea&&`${row.mean_ea}`}</Typography>
                      </TableCell>
                    )}                   
                   
                    {settingColumnsObject["spr.mean_100g"].selected && (
                      <TableCell align="center">
                        <Typography variant="inherit">{row.mean_100g&&`${row.mean_100g}`}</Typography>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.std"].selected && (
                      <TableCell align="center">
                        <Typography variant="inherit">{row.std_price_ea?`${row.std_price_ea.toFixed(2)}`:'---'}</Typography>
                      </TableCell>
                    )}                   
                   
                    {settingColumnsObject["spr.std_100g"].selected && (
                      <TableCell align="center">
                        <Typography variant="inherit">{row.std_100g&&`${row.std_100g}`}</Typography>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.most_common_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.current_mode}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.most_common_100g"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.most_common_100g}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.calculated_reg_ea"].selected && (
                      <TableCell align="center">
                        <PriceComponent
                          // value={row.estimate_regular_price}
                          value={row.current_max}
                          style={{ color: "#000" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.calculated_reg_100g"]
                      .selected && (
                      <TableCell align="center">
                        <PriceComponent
                          value={row.calculated_reg_100g}
                          style={{ color: "#000" }}
                        />
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.reported_reg_ea"].selected && (
                      <TableCell align="center">
                        {row.indexPrevReg >= 0 && (
                          <>
                            <PriceComponent
                              className={classes.hoverClickPopUp}
                              value={row.spps[row.indexPrevReg].regular_price}
                              style={{ color: "#00b04f" }}
                              onClick={() =>
                                handleCurrentRegularOpen(row.liveProductInput)
                              }
                            />                            
                          </>
                        )}
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.reported_reg_100g"].selected && (
                      <TableCell align="center">
                        {row.indexPrevReg >= 0 && (
                          <>
                            <PriceComponent
                              className={classes.hoverClickPopUp}
                              value={row.regular_price_100g}
                              style={{ color: "#00b04f" }}
                              onClick={() =>
                                handleCurrentRegularOpen(row.liveProductInput)
                              }
                            />
                            {/* <TimeAgo date={spps[indexPrevReg].created_at} /> */}
                          </>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {isReady&&dataSource.length===0 && (
              <>
                <TableRow>
                  <TableCell align="center" colSpan={countSelectedColumn+2} style={{color:'#a39d9d'}} >
                    <br/>
                    <br/>
                    <br/>
                    <b>No data is currently available for this date range and region.</b>
                    <br/>
                    <br/>                    
                    Please try expanding the date range or changing the region.
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </TableCell>
                </TableRow>
              </>
            )}  
          </TableBody>
          <TableFooter>
            
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell colSpan={countSelectedColumn+1} className={classes.tableFooter}>{dataSource.length>0 &&<ExportButtons {...configExport}/>} </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell colSpan={countSelectedColumn + 1}>&nbsp;</TableCell>
            </TableRow> */}
          </TableFooter>
        </Table>
      </TableContainer>
    </>
    )}
    </>
  );
}
