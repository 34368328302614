import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import envConfig from "../../bin/env.config";

import {  useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  
}));

export default function ChangePasswordForm({onClose}) {
  const token = useSelector((state) => state.session.token);
  // console.log(state);
  const classes = useStyles();
  const [inputState, setInputState] = useState({
    password: '',
    password1: ''    
  });
  const [errorState, setErrorState] = useState({
    password: '',
    password1: ''
  });
  

  const handleChange = (event) => {
    const inputName = event.target.name;
    setInputState({
      ...inputState,
      [inputName]: event.target.value,
    });
  };
  const submitHandler = (e) => {
    // Prevent form submission on Enter key
    e.preventDefault();
  };
  const validateForm = () => {
    let errors = { ...errorState };
    if (inputState.password.length === 0 || !inputState.password) {
      errors['password'] = `Password is required`;      
    }else{
      errors['password'] = ''
    }
    if (inputState.password1.length === 0 || !inputState.password1) {
      errors['password1'] = `Retype-password is required`;      
    }else if (inputState.password !== inputState.password1) {
      errors['password1'] = `The retype passwords don't match`;      
    }else{
      errors['password1'] = ''
    }
    setErrorState(errors);
    return Object.values(errors).reduce((t, value ) => { console.log(value); return (!Boolean(value)) && t}, true);
  };
   
  const onChangePassword = () => {
    if (validateForm()) {
      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/users-analytics/change_password`;
      let putData = {
        ...inputState,
      }; // console.log(url);

      axios
        .put(url, putData, {
          headers: {
            Authorization: JSON.stringify(token),
          },
        })
        .then((response) => {
          if(response.data.success){
            onClose();
          }else{
            alert('error');
          }
          // window.location.reload(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };
  return (
    <form className={classes.root} onSubmit={submitHandler}>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>        
          <TextField
              label="New password"
              name="password"
              type="password"
              placeholder="Enter your new password"
              fullWidth
              margin="normal"
              variant="outlined"
              onChange= {handleChange}        
              InputLabelProps={{
                shrink: true
              }}
              error={errorState.password.length>0}
              helperText={errorState.password}
            />
          </FormControl>
         <FormControl fullWidth variant="outlined" className={classes.formControl}>
            <TextField
              label="Re-enter new password"
              name="password1"
              type="password"
              placeholder="Re-enter your new password"
              fullWidth
              margin="normal"
              variant="outlined"
              onChange= { handleChange }        
              InputLabelProps={{
                shrink: true
              }}
              error={errorState.password1.length>0}
              helperText={errorState.password1}
            />        
      </FormControl>
      <FormControl fullWidth variant="outlined" className={classes.formControl}>
        <Button
          color="primary"
          variant="contained"
          fullWidth={true}
          onClick={onChangePassword}
          // disabled={validateForm}
        >
          Submit
        </Button>
      </FormControl>
      {/* </div> */}
    </form>
  );
}
