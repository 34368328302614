import {createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
// import {composeWithDevTools} from 'redux-devtools-extension'
import packageJson from '../../package.json';
import rootReducer from './reducers'


function saveToLocalStorage(state){
      try{
      // console.log(state.product);  
      const serializedState = JSON.stringify(state)
      // localStorage.setItem('bettercartAnalytics',serializedState)
      localStorage.setItem(`bettercartAnalytics.v${packageJson.version}`,serializedState)
    }catch(e){
      //console.log(e)
    }
  }
function loadFromLocalStorage(){
    try{
      localStorage.removeItem("userDataT");
      localStorage.removeItem("liveProducts");
      const serializedState = localStorage.getItem(`bettercartAnalytics.v${packageJson.version}`);
      if(serializedState===null) {
        localStorage.clear();
        return undefined
      }
      return JSON.parse(serializedState);
    }catch(e){
      //console.log(e)
      return undefined;
    }
}

const persistedState = loadFromLocalStorage();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)))
store.subscribe(()=>saveToLocalStorage(store.getState()))
export default store