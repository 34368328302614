import React, { useEffect, useRef } from 'react'
import {Chart} from 'chart.js';
// import "chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js";
export default function DetailChart({ product ,columns}) {
  const chartOptions = {
     
  };
  const labels = [];
  const avgData = [];
  const minData = [];
  const maxData = [];
  var clen = columns.length;
  while (clen--) {
      labels.unshift(columns[clen].name);
      avgData.unshift(product[`avg_${columns[clen].id}`])
      minData.unshift(product[`min_${columns[clen].id}`])
      maxData.unshift(product[`max_${columns[clen].id}`])

  }
  
  const dataSource = {
    labels: labels,
    datasets: [
            {
              label: 'Average',
              data: avgData,
              backgroundColor:'rgba(255, 99, 132, 0.2)',
              borderColor:'rgba(255, 99, 132, 1)',
              borderWidth: 1,
              fill: false,
              // type: 'line',
            }, {
                label: 'Min',
                data: minData,
                backgroundColor:'rgba(54, 162, 235, 0.2)',
                borderColor:'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                fill: false,
            }, {
                label: 'Max',
                data: maxData,
                backgroundColor:'rgba(255, 206, 86, 0.2)',
                borderColor:'rgba(255, 206, 86, 1)',
                borderWidth: 1,
                fill: false,
            },
    ],
  };
  const chartRef = useRef(null);
  
  useEffect(() => {
    let myChart = new Chart(chartRef.current, {
      type: 'line',
     
      // type: 'bar',
      data: dataSource,
      options: {...chartOptions}
    });
    // console.log(dataSource)
    myChart.update();
  }, [product]);

  return (
      <canvas ref={chartRef} style={{backgroundColor:"#f6f6f6", paddingBottom:"5px", paddingTop:"5px"}}/>
  )
}
