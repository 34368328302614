import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from '@material-ui/core/Slide';
// import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import MuiAlert from "@material-ui/lab/Alert";
// function Alert(props) {
//   return <MuiAlert elevation={6} variant="outlined" {...props} />;
// }
const styles = (theme) => ({
  success: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderWidth: "2px",
    borderStyle: "solid",
    backgroundColor: "#fff",
    fontWeight: "bold",
    marginTop: "60px",
    // marginRight: "-10px",
    // width: "220px",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    "& button":{
      marginRight:"-8px"
    }
  },
  error: {
    color: "#ff0000",
    borderColor: "#ff0000",
    borderWidth: "2px",
    borderStyle: "solid",
    backgroundColor: "#fff",
    fontWeight: "bold",
    marginTop: "60px",
    // marginRight: "-10px",
    // width: "220px",
    // overflow: "hidden",
    // textOverflow: "ellipsis",

    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    "& button":{
      marginRight:"-8px"
    }
  },
});
const TransitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class Alert extends Component {
  state = {
    severity: "success",
    open: false,
    message: "BetterCart.ca",
  };
  onClose = () => {
    this.setState({
      open: false,
    });
  };
  doAlert = (severity, message) => {
    this.setState({
      severity: severity,
      open: true,
      message: message,
    });
  };
  render() {
    const { classes } = this.props;
    return (     
      <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={this.state.open}
      autoHideDuration={1200}
      onClose={this.onClose}
      ContentProps={{
        "aria-describedby": "message-id",
        classes: {
          root:
            this.state.severity === "error" ? classes.error : classes.success,
        },
      }}
      message={<span id="message-id">{this.state.message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.onClose}
        >
          <CloseIcon />
        </IconButton>,
      ]}      
      TransitionComponent={TransitionUp}
    />
    );
  }
}
export default withStyles(styles)(Alert);
