import React, {useState, useEffect,useRef} from "react"
import { makeStyles } from "@material-ui/styles";
import IconButton from '@material-ui/core/IconButton';
import Link from "@material-ui/core/Link";

import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';

import MessageDialog from "./MessageDialog";



const useStyles = makeStyles((theme) => ({
    margin: {
      // margin: theme.spacing(1),
    },
  }));

// export default function RequestPriceUp2DateButton({product_matching_group_id, product_id, last_collected_date, store_id}){
export default function RequestPriceUp2DateButton(promps){
    const {icon=true,text="",requested} = promps
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    // const alertPopup = useRef();

    const handleClick = () => {
      setOpen(true);
      // setRequested(true)
    };
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <>
      {icon&&
        <IconButton  onClick={handleClick} aria-label="request price update" size="small" color={requested?"premary":"secondary"} className={classes.margin} title="Request price update">
            <NotificationImportantIcon />
        </IconButton>
      }
      {!icon&&
        <Link color={requested?"premary":"secondary"} href="#" onClick={handleClick}>{text}</Link>
      }
      {/* <Alert ref={alertPopup} /> */}
      <MessageDialog 
        {...promps}       
        open={open}
        handleClose={handleClose}
      />
      </>
    )
}