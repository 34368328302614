import React, { useState,useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {  
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import axios from "axios";
import envConfig from "../../bin/env.config";
import Loading from "../Loading/Loading";
import { PriceComponent,randomNumber } from "../../utility/utility";
import {YourPercent,Discrepancy} from "../Charts/Percent";
import LineGraph from "../Charts/LineGraph";
import { format,addDays, parseISO } from 'date-fns'
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {    
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
   
  },
  rowExpand:{
    '& > *': {
      borderTop:"3px solid green"
    },   
  },
  rowCollapse:{
    '& > *': {
      borderBottom:"3px solid green"
    },
    backgroundColor: "rgba(0, 0, 0, 0.04)",      
  },
  showText:{
    textTransform:'capitalize '
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  lineGraphContainer:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px',
    fontSize: "1rem", 
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
}));

export default function RowDetailExpand({product_matching_group_id}) {
  const classes = useStyles();  
  const [isLoading, setIsLoading] = useState(true);
  const state = useSelector(state => state);

  const fromDate = state.week.weekSelected.first;
  const toDate = format(addDays(parseISO(state.week.weekSelected.first),6),'yyyy-MM-dd');
  // let fromDate = props.weekSelected.first;
  // let toDate = format(addDays(parseISO(props.weekSelected.first),6),'yyyy-MM-dd');
  const storesSelected = state.store.storesSelected
  const postData = {
    from_date: fromDate,
    to_date: toDate,
    storeIDs:  storesSelected
      ?  storesSelected.map((store) => store.id)
      : [],
    product_matching_group_id: product_matching_group_id,
  };
  
  const [dataSource, setDataSource] = useState([]);
  
  useEffect(() => {
    const fetchDataSource = async () => {      
      // console.log('here'+searchText);
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/battleground-detail`;
      const result = await axios.post(url, postData,
                                      {
                                        headers: {
                                          Authorization: state.session.token,
                                        },
                                      });
      
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    
    fetchDataSource();
  }, [storesSelected,product_matching_group_id]);
  return (
    <>
    
    {isLoading ? (
      <Loading />
    ) : (
      <TableContainer className={classes.container} square>
      <Table>
        <TableHead >
          <TableRow  className={classes.trHeader}>
            <TableCell align="left" width="150px"  className={classes.thHeaderFirst}>Company</TableCell>
            <TableCell align="left"  className={classes.thHeader}>Store</TableCell>
            <TableCell width="350px" className={classes.thHeader}/>
            <TableCell align="center"  width="150px" className={classes.thHeader}>Price</TableCell>
            {/* <TableCell align="left"  className={classes.thHeader} width="150px">%</TableCell>            */}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource &&
            dataSource.map((row, index) => {
              var flags = [], spps = [], l = row.spp_record.length, i;
              for( i=0; i<l; i++) {
                const items = row.spp_record[i].split(";"); 
                if( flags[items[0]]) continue;
                  flags[items[0]] = true;
                  spps.push({
                    created_at: items[0],
                    is_onsale: +items[1],
                    current_price: +items[2],
                    regular_price: +items[3],
                })                    
              }
              const currentPrice = spps[0].current_price;
              // const divided = props.row.high_price - props.row.low_price; 
              // const divisor = currentPrice - props.row.low_price;
              // const your_percent= divided===0?0:Math.trunc(100 * ( divisor / divided ))
              const currStore = storesSelected.find((p) => p.id === Number(row.store_id))             

              return (
                <>{currStore&& 
                    <TableRow key={index}> 
                      <TableCell align="center"><img className={classes.storeLogo} src={currStore.company_logo_link}/></TableCell>
                      <TableCell align="left">
                        {currStore.name}<br/>
                        {currStore.address}
                      </TableCell>                      
                      <TableCell align="center" >
                        <div className={classes.lineGraphContainer}>
                        <LineGraph key={index} storeProductPrices={spps}  />
                        </div>
                      </TableCell>
                      <TableCell align="center"> 
                              <PriceComponent
                                value={currentPrice}
                                style={{ color: "#204F6C" }}
                              />
                      </TableCell>
                      {/* <TableCell align="center"><YourPercent percent={your_percent}/></TableCell>    */}
                    </TableRow>
                }</>
              );
            })}
        </TableBody>                
      </Table>
    </TableContainer>
    )}
  
    </>
  );
}

