import {INIT_CART} from '../types'

export const addToCart = (product)=>{
    return (dispatch,getState)=>{
        let cart = getState().cart.cart;
        let temp = cart.filter(item => item.id !== product.id);
        dispatch( {
            type: INIT_CART,
            payload: [...temp, product]
        })
    }    
  }
export const removeFromCart = (product)=>{
    return (dispatch,getState)=>{
        let cart = getState().cart.cart;
        let temp = cart.filter(item => item.id !== product.id);
        dispatch( {
            type: INIT_CART,
            payload: temp
        })
    }    
}
