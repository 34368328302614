import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from '@material-ui/core';
import axios from "axios";
import envConfig from "../../bin/env.config";

import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: "rgba(255,255,0,0.3)",
    padding:'2px 8px 2px 8px',
    fontSize:'0.875rem'
  }, 
}));

export default function ProductAttributesInfo({productId,isPmg=false}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  
  const token = useSelector((state) => state.session.token);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/product/get-attributes`;
      const postData = {
        isPmg: isPmg,
        id: productId,
      };
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: token,
        },
      });
      // console.log(result.data.data)
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    fetchData();

  }, [productId]);
  
  return (
    <p style={{fontSize:'0.75', color:'#757575'}}>
        {dataSource&&dataSource.productModel}
    </p>       
  );
}
