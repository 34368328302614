import React, { useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, TableCell } from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ReportListDetail from "./ReportListDetail";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
const useStyles = makeStyles((theme) => ({}));
const formReducer = (state, event) => {
  if (event.reset) {
    return {
      name: "",
    };
  }
  return {
    ...state,
    [event.name]: event.value,
  };
};
export default function ReportListRowAddNew() {
  const classes = useStyles();
  const [formData, setFormData] = useReducer(formReducer, {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleChange = (event) => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    setTimeout(() => {
      setIsSubmitting(false);
      setFormData({
        reset: true,
      });
    }, 3000);
  };
  return (
    <>
      <TableRow hover className={classes.tableRow}>
        <TableCell></TableCell>
        <TableCell colspan={2}>
          <form onSubmit={handleSubmit}>
            <fieldset disabled={isSubmitting}>
              <label>
                Report Name:&nbsp;
                <input
                  type="text"
                  value={formData.name || ""}
                  onChange={handleChange}
                  disabled={isSubmitting}
                />{" "}
              </label>
            <input type="submit" value="Add New" disabled={isSubmitting} />
            </fieldset>
          </form>
        </TableCell>
        <TableCell align="center"></TableCell>
      </TableRow>
    </>
  );
}
