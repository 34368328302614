import React, {  useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent,DialogActions, } from "../Dialog/DialogCustom";
import LineGraphDetail from "../Charts/LineGraphDetail";
import SaveImageButton from "./SaveImageButton"
import { Helpers } from "../../utility/helpers";
import { toPng } from 'html-to-image';
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  productNameCell: {
    textTransform: "capitalize",
    color: theme.palette.secondary.main,
  },
  productImg: {
    width: "auto",
    maxHeight: "50px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
}));
export function BrandImages({brand}){
  const classes = useStyles();
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  // console.log(brand)
  return (
    <img className={classes.productImg} src={brand.image_path} onError={addDefaultSrc}/>      
    )
}
export default function TrendDetailDialog({
  trendSelected, 
  open,
  handleClose,
}) {
  const classes = useStyles();
  const user = useSelector(state => state.session.user);
  const exportDivRef = useRef(null);
  const exportToImage = useCallback(() => {
    if (exportDivRef.current === null) {
      return
    }
   
    toPng(exportDivRef.current,{backgroundColor:'white'})
      .then(function (dataUrl) {
        Helpers.saveAs(dataUrl, 'brand-trend-detail.png');
      }).catch((err) => {
        console.log(err)
      }) 
  }, [exportDivRef])
  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={open}
      >
        <DialogTitle id="dialog-title" onClose={handleClose}>
         {trendSelected.label}          
        </DialogTitle>
        <DialogContent dividers>
          <div ref={exportDivRef}>
            <LineGraphDetail
              // key={index}
              storeProductPrices={trendSelected.prices}
              columnField={'avg_price'}
            />
          </div>
        </DialogContent>   
        <DialogActions>
              {/* <ExportButtons {...configExport}/> */}
              <SaveImageButton exportFunction={exportToImage}/>
        </DialogActions>    
      </Dialog>
    </div>
  );
}
