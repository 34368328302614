import React, { useState ,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import {
  DialogTitle,
  DialogContent,
  DialogActions
} from "../Dialog/DialogCustom";
import DeviceSearchBox from "./DeviceSearchBox";
// import SearchBox from "./SearchBox";
import { Table, TableHead, TableBody, TableCell, TableRow } from "@material-ui/core";
// import LoadMore from "./LoadMore";
import { multiSearchAnd } from "../../utility/utility";
const useStyles = makeStyles((theme) => ({
  dialogContent:{
    paddingTop:'0px'
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
  productImage: {
    width: 50,
    height: 50,
    // marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    // cursor: "pointer",
  },
  productNameCell: {
    textTransform: "capitalize",
    color: "inherit",
    // "&:hover": {
    //   cursor: "pointer",
    //   backgroundColor: theme.palette.secondary.main,
    //   color: "#fff",
    // },
  },
  priceRecordNumber:{
    textAlign:"right"
  }
  
}));
export function DeviceName({device}){
  return `${device.brand} ${device.name} ${device.size}`
};
export function TableBodyData({dataSource, handleSelected, limit}){
  const classes = useStyles();
  
  return (
    <>
      {dataSource.length === 0 && (
        <TableRow>
          <TableCell colSpan={3}>Didn’t find what you’re looking for?</TableCell>
        </TableRow>
      )}
      {dataSource.length > 0 &&
        dataSource.slice(0, limit).map((row, i) => {
          // let isChecked = selected.id === row.id;
          return (
            <TableRow
              key={i}
              onClick={() => handleSelected(i)}
              className={classes.tableRow}
            >
              <TableCell className={classes.productNameCell}>
                {row.brand}
              </TableCell>
              <TableCell className={classes.productNameCell}>
                {row.model}
              </TableCell>
              <TableCell className={classes.productNameCell}>
                {row.size}
              </TableCell>              
            </TableRow>                    
          );
        })}
        {/* {dataSource.length > limit&&(
              <LoadMore doLoadMore={doLoadMore} />
        )} */}
      </>
  )
}
export default function SelectADeviceDialog({
  devices,
  setSelectedDevice,
  open,
  handleClose,
}) {
  const classes = useStyles();
  const [dataSource, setDataSource] = useState(devices);
  const [searchTerm, setSearchTerm] = useState("");
 
  const handleSelected = (selectedIndex) => {
    setSelectedDevice(dataSource[selectedIndex]);
    handleClose();
  };
  const handleCloseClick = () => {
    handleClose();
    setDataSource(devices);
  };
  useEffect(() => {
    setDataSource(devices)
  }, [devices]);
  const doSearch = (q) => {
    if(q!=""){
      setDataSource(devices.filter(
                      (item) =>
                                (multiSearchAnd(item.brand,q) ||
                                multiSearchAnd(item.model,q) ||
                                multiSearchAnd(item.size,q))
                  )) 
    }
  };
 
  return (   
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleCloseClick}
        aria-labelledby="dialog-title"
        open={open}
      >
        <DialogTitle id="dialog-title" >
          <DeviceSearchBox
            placeholder="Search a device"
            value={searchTerm}
            doSearch={doSearch}
            searchOnChange={false}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Table size="small">
            <TableHead >
                <TableRow>
                  <TableCell>Brand</TableCell>
                  <TableCell>Model</TableCell>
                  <TableCell>Size</TableCell> 
                </TableRow>
            </TableHead>
            <TableBody>
         
              <TableBodyData 
                dataSource={dataSource} 
                handleSelected={handleSelected} 
              />
          
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>    
  );
}
