import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import ReportListRow from "./ReportListRow";
import ReportListRowAddNew from "./ReportListRowAddNew";
const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: "3px solid green",
  },
  tableRow: {
    "& > .MuiTableCell-root": {
      padding: "8px",
    },
  },
  rowExpand: {
    "& > *": {
      borderTop: "3px solid green",
    },
  },
  rowCollapse: {
    "& > *": {
      borderBottom: "3px solid green",
    },
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  showText: {
    textTransform: "capitalize ",
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  lineGraphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  trHeader: {
    height: "64px",
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding: "16px 8px",
    fontSize: "1rem",
  },
  thHeaderFirst: {
    backgroundColor: "#DDD",
    paddingLeft: "24px",
  },
}));
export default function ReportList() {
  const classes = useStyles();
  const productLists = useSelector((state) => state.report.productLists);
  return (
    <TableContainer className={classes.container}>
      <Table>
        <TableBody>
          {productLists &&
            productLists.map((item, index) => {
              return (
                <ReportListRow productList={item}/>
              );
            })}
        </TableBody>
        <TableFooter>
          <ReportListRowAddNew/>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
