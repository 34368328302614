import React,{useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { Checkbox } from "@material-ui/core";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Dialog from "@material-ui/core/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../Dialog/DialogCustom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";


import { useDispatch, useSelector } from "react-redux";
import {
  setColumns
  } from "../../store/actions/columnAction";
 
const useStyles = makeStyles((theme) => ({
  root: {
      marginRight:"10px", 
      padding:"0px 10px",
      borderWidth:"2px",
      '&:hover':{
        borderWidth:"2px",
      }    
  },
  label:{
      textTransform: "capitalize",
      fontSize: '1.25rem',       
      fontWeight: "500",
      '& >.MuiButton-iconSizeMedium > *:first-child ':{
        fontSize: '2rem',
      }
    }
  }));
// import StoreIcon from "@material-ui/icons/Store";

export default function SelectColumns() {
  const classes = useStyles(); 
  const [open, setOpen] = React.useState(false);
  const columns = useSelector(state => state.setting.columns);
  const [dataSource, setDataSource] = useState(columns.month_reports);
  // console.log(columns);
  const dispatch = useDispatch()  
  const handleChange = (event, index) => {
    let dataSourceTemp = [...dataSource];
    // envConfig.DEV_CONSOLE_LOG_PRINT&&console.log(dataSourceTemp); 
    dataSourceTemp[index].selected = event.target.checked 
    setDataSource(dataSourceTemp)
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {    
    dispatch(setColumns({...columns,month_reports:dataSource}));
    setOpen(false);
  };
  return (  
    <>
    <IconButton color="primary" aria-label="Customize Columns" component="span" onClick={handleClickOpen}>
          <i className="material-icons">add_chart</i> 
        </IconButton>
      {/* <Button
        variant="outlined"
        classes={{root:classes.root, label:classes.label}}
        color="default"
        onClick={handleClickOpen}
        startIcon={<SettingsIcon/>}
      >
      </Button>    */}
      {/*  */}
      
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="city-select-title"
        open={open}
      >
        <DialogTitle id="city-select-title" onClose={handleClose}>
          Select the columns to display for this report
        </DialogTitle>
        <DialogContent dividers>
          <Table stickyHeader size="small">
            <TableBody>             
              {dataSource.length > 0 &&
                dataSource.map((row, i) => {
                  return (
                    <TableRow key={i}>                      
                      <TableCell align="left">
                      <FormControlLabel
                        control={
                          <Checkbox
                                          checked={row.selected}
                                          onChange={(event) => handleChange(event, i)}
                                        />
                        }
                        label={row.name}
                      />                       
                      </TableCell>                                                 
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default">
            Cancel
          </Button>
          <Button autoFocus onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>  

    </>
   
  );
}

