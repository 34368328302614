import { useEffect } from "react";
import L from "leaflet";
import 'leaflet-easyprint';

function EasyPrintControl({ map }) {
    
    useEffect(() => {
      if (map) {
        L.easyPrint({
          title: 'Price Map',
      		sizeModes: ['Current', 'A4Landscape', 'A4Portrait'],
      		filename: 'price-map',
      		exportOnly: true,
        }).addTo(map);
      }
    }, [map]); //here add map
    return null;
}

export default EasyPrintControl;