
import {SET_LOG_IN_USER, SET_AUTH_TOKEN, LOG_OUT} from '../types'
const initialState = {
    user: {},
    token: '',
}
export default function(state = initialState, action){
    switch(action.type){
        case SET_LOG_IN_USER:
            return {
                ...state,
                user:action.user,
            }       
        case SET_AUTH_TOKEN:
            return {
                ...state,
                token:action.token,
            }  
        case LOG_OUT:
            return {
                ...state,
                user: null,
                token: null
            }    
        default: return state
    }

}