
import { SELECT_PROVINCE, PROVINCE_ERROR} from '../types'
const initialState = {
    provinceSelected:{
        code: "",
        name: "Canada",
    },
    loading:true,
}

export default function(state = initialState, action){

    switch(action.type){

        case SELECT_PROVINCE:
            return {
                ...state,
                provinceSelected:action.provinceSelected
            }
        case PROVINCE_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state
    }

}