import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../Dialog/DialogCustom";
import SearchBox from "./SearchBox";
import { Radio } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import {
  selectCity
  } from "../../store/actions/cityAction";
import {
  fetchStores
  } from "../../store/actions/storeAction"; 
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
  productNameCell: {
    textTransform: "capitalize",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
}));

export default function SelectCityDialog({    
  open,
  handleClose,
}) {
  const classes = useStyles();
  const city = useSelector(state => state.city);
  const dispatch = useDispatch()
  
  const [dataSource, setDataSource] = useState(city?city.cities:undefined);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    setDataSource(city?city.cities:undefined)    
  }, [city]);
  const handleSelected = (selected) => {
    dispatch(selectCity(selected));
    dispatch(fetchStores(selected.name))
    handleClose();
  };

  const doSearch = (q) => {
    //doSearch
    setSearchTerm(q);
    setDataSource(
      city.cities.filter((item) => item.name.toLowerCase().includes(q.toLowerCase()))
    );
  };
  

  return (
   
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="city-select-title"
        open={open}
      >
        <DialogTitle id="city-select-title" onClose={handleClose}>
          <SearchBox id="city-select-search-form" value={searchTerm} doSearch={doSearch} />
        </DialogTitle>
        <DialogContent dividers>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  Province
                </TableCell>
                <TableCell align="center">City</TableCell> 
                </TableRow>
            </TableHead> 
            <TableBody>
              {dataSource.length === 0 && (
                <TableRow>
                  <TableCell>Not finding what you're looking for?</TableCell>
                </TableRow>
              )}
              {dataSource.length > 0 &&
                dataSource.map((row, i) => {
                  return (
                    <TableRow
                      key={i}
                      onClick={() => handleSelected(row)}
                      className={classes.tableRow}
                    >
                      <TableCell className={classes.productNameCell}>
                        {row.province}
                      </TableCell>
                      <TableCell className={classes.productNameCell}>
                        {row.name}
                      </TableCell>                      
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
        
      </Dialog>    
  );
}
