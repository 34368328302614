import {GET_MY_PRODUCTS, GET_MY_PRODUCTS_COMPARISON,MY_PRODUCT_ERROR, SET_SHOW_REPORT_DATA,SET_SHOW_STORE_COUNT_COLUMN} from '../types'
import axios from 'axios'
import envConfig from "../../bin/env.config";


export const fetchMyProducts = (postData) => {
    return (dispatch,getState) => {
      return new Promise((resolve, reject) => {
        const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/my-product/list`;
        axios.post(url, postData, {
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          } 
        }).then((result) => {       
          resolve(result.data.data); 
        }).catch(error => {        
          dispatch( {
              type: MY_PRODUCT_ERROR,
              payload: error,
          })
          reject(error);
        })
      });
    };
};


export const addMyProduct = (groupId)=>{
  return (dispatch,getState) => {
    return new Promise((resolve, reject) => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/my-product/add`;
      const postData = {
        groupId:groupId,
        storeIDs: getState().store.storesSelected.map((store) => store.id),
      };     
      axios.post(url, postData, {
        headers: {
          'Authorization': JSON.stringify(getState().session.token)
        } 
      }).then((result) => {
        resolve(result.data.data); 
      }).catch(error => {        
        dispatch( {
            type: MY_PRODUCT_ERROR,
            payload: error,
        })
        reject(error);
      })
    });
  };
}
export const addMyProductComparison = (groupId,groupComparisonId)=>{
  return (dispatch,getState) => {
    return new Promise((resolve, reject) => {
      const myProductsComparison = getState().myProduct.myProductComparisons;
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/my-product/comparison/add`;
      const postData = {
        groupId:groupId,
        groupComparisonId:groupComparisonId,
        storeIDs: getState().store.storesSelected.map((store) => store.id),
      };     
      axios.post(url, postData, {
        headers: {
          'Authorization': JSON.stringify(getState().session.token)
        } 
      }).then((result) => {      
        // console.log(result.data.data)
        resolve(result.data.data); 
      }).catch(error => {        
        dispatch( {
            type: MY_PRODUCT_ERROR,
            payload: error,
        })
        reject(error);
      })
    });
  };
}
export const removeMyProduct = (groupId)=>{
  return (dispatch,getState) => {
    return new Promise((resolve, reject) => {
      const myProducts = getState().myProduct.myProducts;
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/my-product/remove`;
      const postData = {
        groupId:groupId,
      };   
      // console.log(postData)  
      axios.post(url, postData, {
        headers: {
          'Authorization': JSON.stringify(getState().session.token)
        } 
      }).then((result) => {   
        // console.log(result);
        // console.log('succsess');
        // dispatch( {
        //   type: GET_MY_PRODUCTS,
        //   payload: myProducts.filter(item => item.product.id != groupId)
        // })   
        resolve(true); 
      }).catch(error => {        
        dispatch( {
            type: MY_PRODUCT_ERROR,
            payload: error,
        })
        reject(error);
      })
    });
  };
}
export const removeMyProductComparison = (groupId,groupComparisonId)=>{
  return (dispatch,getState) => {
    return new Promise((resolve, reject) => {
      const myProductComparisons = getState().myProduct.myProductComparisons;
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/my-product/comparison/remove`;
      const postData = {
        groupId:groupId,
        groupComparisonId: groupComparisonId
      };     
      axios.post(url, postData, {
        headers: {
          'Authorization': JSON.stringify(getState().session.token)
        } 
      }).then((result) => {   
        resolve(true); 
      }).catch(error => {        
        dispatch( {
            type: MY_PRODUCT_ERROR,
            payload: error,
        })
        reject(error);
      })
    });
  };
}
export const setShowReportData = (showReportData) => ({
  type: SET_SHOW_REPORT_DATA,
  payload: showReportData
});
export const setShowStoreCountColumn = (showStoreCountColumn) => ({
  type: SET_SHOW_STORE_COUNT_COLUMN,
  payload: showStoreCountColumn
});

