import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ReportList from "./ReportList";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ConfigDialog = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {columns, reportType } = props;
  const [product, setProduct] = useState({
    pro_: "",
  });
  const onClose = () => {
    setOpen(false);
  };
  useImperativeHandle(ref, () => ({
    doOpen: doOpen,
  }));
  const doOpen = (row) => {
    setOpen(true);
    setProduct(row);
  };
  return (
    <Dialog    
      fullScreen  
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="title" color="inherit">
            Config
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container >        
        <Grid item xs={12} sm={12} md={12} lg={12}>              
              <ReportList />
        </Grid>
      </Grid>
    </Dialog>
  );
});
export default ConfigDialog;
