import React, { useEffect } from "react";

import { format } from "date-fns";
import  CLine from "./CLine";

export default function LineGraph({ storeProductPrices, columnField='current_price', trendLine = false}) {
  // var presets = window.chartColors;
  
  const chartOptions = {
    plugins: {
      legend: false,
    },
    responsive: true,
    aspectRatio:6,
    title: {
      display: false,
    },
    // interaction: {
    //   intersect: false,
    //   axis: 'x'
    // },   
    scales: {
      x:{
          display: false
        },
      y:{
          display: false,        
          ticks: { beginAtZero: false },
        },
    },    
  };
  const labels = [];
  const data = [];

  storeProductPrices.forEach((spp) => {
    if (spp[columnField] > 0) {
      labels.unshift(format(Date.parse(spp.created_at), "PP"));
      data.unshift(spp[columnField] );
    }
  });
  
  const dataSource = {
    labels: labels,
    datasets: [
      {
        fill: false,
        // steppedLine: true,
        data: data,
        backgroundColor: "rgba(0, 176, 79,0.4)",
        borderColor: "rgba(0,176,79,1)",  
        // borderWidth: 1,
        pointRadius: 0, 
        trendlineLinear: {
            style: "#2C4F69",
            lineStyle: "dotted",
            width: 3
        }            
      },
    ],
  };
  // console.log(dataSource);
  return (
    <CLine
      data={dataSource}
      options={chartOptions}
      trendLine={trendLine}
      // width={100}
    />    
  );
}
