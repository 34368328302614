import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PriceComponent, price100g } from "../../utility/utility";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";

import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";

import HistoricalPricingAllDialog from "./HistoricalPricingAllDialog";

import LineGraph from "../Charts/LineGraph";
// import TimeAgo from "react-timeago";
// import classes from "*.module.css";
import {  useSelector } from "react-redux";

import ExportButtons  from "../Common/ExportButtons";
import RequestPriceUp2DateButton from "../Common/RequestPriceUp2DateButton"

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "calc(100vh - 231px)",
    boxShadow: "none",
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
  lineGraphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  trHeader: {
    height: "64px",
  },
  thHeader: {
    backgroundColor: "#DDD",
    fontSize: "1rem",
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
  currentSize: {
    whiteSpace: "nowrap",
  },
  currentUpdatedAt: {
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    whiteSpace: "nowrap",
  },
  promoDistantInWeek: {
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    whiteSpace: "nowrap",
  },
  promoType: {
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    whiteSpace: "nowrap",
  },
  promoCycle: {
    display: "flex",
    alignItems:"center",
    justifyContent:"center",
    whiteSpace: "nowrap",
  },
  
}));
{/* <PriceComponentWithPromo
                          className={classes.hoverClickPopUp}
                          currentPrice={row.currentPrice}
                          isOnSale = {row.isOnSale}
                          style={{ color: row.isOnSale?"#00B04F":"#204F6C" }}
                          onClick={() => handleCurrentPriceOpen(row.spps, row.liveProductInput)}
                        /> */}
export function PriceComponentWithPromo(props) 
{
  const { currentPrice, isOnSale, className, onClick } = props;
  return (
    <div style={{display: "inline-flex"}}>
    <div className={className}  onClick={onClick} style={{ color: isOnSale?"#00B04F":"#204F6C" }}>
      {currentPrice > 0 ? `$${currentPrice.toFixed(2)}` : `---`}      
    </div>
      {isOnSale>0&&(
        <div style={{marginRight:"-28px",marginLeft:"8px",width: "20px",height: "20px", background: "#00B04F",color:"white",borderRadius: "50%",textAlign: "center",lineHeight: "20px", fontSize:"1em",}}> P</div>
      )}
    </div>
  );
};
export default function MainBoard({
  dataSource,
  productSelected
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [liveProductInputData, setLiveProductInputData] = useState({});
  const [dialogTitle, setDialogTitle] = useState("Historical Pricing");

  const state = useSelector((state) => state);
  const citySelected = state.city.citySelected; 

  const settingColumnsArray = state.setting.columns.promo_vs_regular;
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner
  
  // const storesSelected = state.store.storesSelected;
    // console.log(settingColumnsArray);
  const settingColumnsObject = settingColumnsArray.reduce(
    (acc, curr) => ((acc[curr.code] = curr), acc),
    {}
  );
  const countSelectedColumn = settingColumnsArray.filter(
    (obj) => obj.selected
  ).length;
  const printSize = (size) => {
    return size.trim().length > 0 ? size : "---";
  };
  const handleCurrentPriceOpen = ( liveProductInput) => {
    setLiveProductInputData(liveProductInput);
    setDialogTitle(`Price History - ${liveProductInput.companyName}`);
     
    setOpen(true);    
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  
  const headersExport=[
    { label: "Company", key: "company" },
    { label: "Store", key: "store" },
    { label: "Address", key: "store_address" },
    { label: "Product", key: "productName" },
    { label: "Size", key: "currentSize" },
    { label: "Standard Size", key: "currentStandardSize" },
    { label: "Collected Date", key: "currentUpdatedAt" },
    { label: "Price", key: "currentPrice" },
    { label: "Regular", key: "current_max" },
    { label: "Promo", key: "promo" },
    { label: "Promo type", key: "promoType" },
    { label: "Avg Promo Cycle", key: "promoCycle" }
  ];
  const productName = productSelected.name||'';
  const filename = "promo-vs-regular-" + productName.replace(/[^a-z0-9]/gi, '_').toLowerCase()+".csv"
  const configExport={
    headers:headersExport,
    data:dataSource,
    filename:filename,
    target:"_blank",
  }
  
  return (
    <>
      <HistoricalPricingAllDialog
        productSelected={productSelected}
        liveProductInputData={liveProductInputData}
        title={dialogTitle}
        open={open}
        handleClose={handleClose}
      />
      <TableContainer component={Paper} className={classes.container} square>
        <Table stickyHeader aria-label="datatable report">
          <TableHead>
            <TableRow className={classes.trHeader}>              
              {settingColumnsArray
                .filter((r) => r.selected)
                .map((row, index) => {
                  if(!(byBanner&&row.code=='spr.store')){
                    return (
                      <TableCell
                        align="center"
                        key={`column_${index}`}
                        className={classes.thHeader}
                        width={row.width}
                      >
                        <Typography variant="inherit">{row.name}</Typography>
                      </TableCell>
                    );
                  }else{
                    return null
                  }                  
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource &&
              dataSource.map((row, index) => {
                return (
                  <TableRow hover key={index}>                   
                    {settingColumnsObject["spr.company"].selected && (
                      <TableCell align="center">
                        {" "}
                        <img
                          className={classes.storeLogo}
                          src={row.company_logo_link}
                        />
                        {}
                      </TableCell>
                    )}
                    {(!byBanner)&&settingColumnsObject["spr.store"].selected && (
                      <TableCell align="left">{row.store}</TableCell>
                    )}
                    {settingColumnsObject["spr.pricing_trend"].selected && (
                      <TableCell align="center">
                        <div className={classes.lineGraphContainer}>
                          <LineGraph
                            key={index}
                            storeProductPrices={row.spps}
                          />
                        </div>
                      </TableCell>
                    )}
                   
                    {settingColumnsObject["spr.current"].selected && (
                      <TableCell align="center" style={{verticalAlign:"top"}}>
                        <PriceComponentWithPromo
                          className={classes.hoverClickPopUp}
                          currentPrice={row.currentPrice}
                          isOnSale = {row.isOnSale}
                          style={{ color: row.isOnSale?"#00B04F":"#204F6C" }}
                          onClick={() => handleCurrentPriceOpen(row.liveProductInput)}
                        />
                        <div className={classes.currentSize}>
                          <b>{printSize(row.currentSize)}</b>
                        </div>
                        <div className={classes.currentUpdatedAt}>
                          {row.currentUpdatedAt}
                        </div>
                      </TableCell>
                    )}
                    
                    {settingColumnsObject["spr.regular"].selected && (
                      <TableCell align="center"  style={{verticalAlign:"top"}}>
                        <PriceComponent
                          // value={row.estimate_regular_price}
                          value={row.current_max}
                          style={{ color: "#000", }}
                        />
                      </TableCell>
                    )}
                   
                    {settingColumnsObject["spr.promo"].selected && (
                      <TableCell align="center" style={{verticalAlign:"top"}}>
                        {row.promo&&
                                    <PriceComponent
                                      // value={row.estimate_regular_price}
                                      value={row.promo}
                                      style={{ color: row.isOnSale?"#000":"#c2c0c0"}}
                                    />
                        }
                        <div className={classes.promoDistantInWeek} style={{ color: row.isOnSale?"#000":"#c2c0c0"}}>
                          {row.promoDistantInWeek}
                        </div>                        
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.promo_type"].selected && (
                      <TableCell align="center" style={{verticalAlign:"top"}}>
                        <div className={classes.promoType} style={{ color: row.isOnSale?"#000":"#c2c0c0"}}>
                          {row.promoType}
                        </div>
                      </TableCell>
                    )}
                    {settingColumnsObject["spr.promo_cycle"].selected && (
                      <TableCell align="center" style={{verticalAlign:"top"}}>
                        <div className={classes.promoCycle} style={{ color: row.isOnSale?"#000":"#c2c0c0"}}>
                          {row.promoCycle}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {dataSource.length==0 && (
            <>
                <TableRow>
                  <TableCell align="center" colSpan={countSelectedColumn+2} style={{color:'#a39d9d'}} >
                    <br/>
                    <br/>
                    <br/>
                    <b>No data is currently available for this date range and region.</b>
                    <br/>
                    <br/>                    
                    Please try expanding the date range or changing the region.
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                  </TableCell>
                </TableRow>
              </>
            )}    
          </TableBody>
          <TableFooter>
            {/* <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell
                align="left"
                className={classes.tableFooter}
                colSpan={countSelectedColumn}
              >
                
                  {!byBanner && dataSource && `${storesSelected.length - dataSource.length} stores do not carry this product.`} 
                  {byBanner && dataSource && `${bannersSelected.length - dataSource.length} banners do not carry this product.`}
                
              </TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell colSpan={countSelectedColumn+1} className={classes.tableFooter}>{dataSource.length>0 &&<ExportButtons {...configExport}/>} </TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell colSpan={countSelectedColumn + 1}>&nbsp;</TableCell>
            </TableRow> */}
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
