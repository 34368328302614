import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import envConfig from "../bin/env.config";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// https://betterprogramming.pub/heres-why-i-m-replacing-html2canvas-with-html-to-image-in-our-react-app-d8da0b85eadf
// https://www.npmjs.com/package/html-to-image
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  Paper,
} from "@material-ui/core";
import { Menu } from "../components/Menu";
import Loading from "../components/Loading/Loading";
import { useSelector } from "react-redux";
import Alert from "../components/Alert/Alert";
import LineGraph from "../components/Charts/LineGraph";
import { PriceComponent } from "../utility/utility";
import { Helpers } from "../utility/helpers";
import ToolbarPanel from "../components/BrandTrend/ToolbarPanel";
import TrendDetailDialog from "../components/BrandTrend/TrendDetailDialog";
import TrendCompareDialog from "../components/BrandTrend/TrendCompareDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableContainer: {
    // padding: "16px",
    maxHeight: "calc(100vh - 128px)",
    boxShadow: "none",
    // max-height: 100% !important'; and display: inline-block !important
  },
  tableRow:{
    // cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
    textTransform: 'capitalize',
    cursor: "pointer",
    height:"130px"    
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },  
  productImg: {
    width: "auto",
    maxHeight: "50px",
    // maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingRow:{
    backgroundColor: '#ddd',
    animationDuration: '2s',
    highlightColor: '#a9b7c1',
    height:'70px',
    width:'100%',
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingPicture:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    marginLeft:'auto',
    marginRight:'auto'
  },
  lineGraphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width:'350px',
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
}));

export function BrandImages({brand, onClick}){
  const classes = useStyles();
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  // console.log(brand)
  return (
    <img className={classes.productImg} src={brand.image_path} style={{maxWidth:'200px',maxHeight:'100px'}} onError={addDefaultSrc} onClick={onClick}/>      
    )
}


export default function BrandTrend() {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state);
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  const storesSelected = state.store.storesSelected;

  const [open, setOpen] = useState(false);
  const [openCompareDialog, setOpenCompareDialog] = useState(false);
  const [trendSelected, setTrendSelected] = useState({});
  
  const [dataSource, setDataSource] = useState([]);

  const handleOpenDetailClick =(labelId)=>{
    history.push(`/brand-trend-detail/${labelId}`)    
  }
  const handleCurrentRowOpen = (rowObject) => {  ;   
    setTrendSelected(rowObject);
    setOpen(true);
  };
  const handleCompareDialogOpen = () => {  ;   
    setOpenCompareDialog(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCompareDialogClose = () => {
    setOpenCompareDialog(false);
  };

  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);

      const postData = {
        fromDate: fromDate,
        toDate: toDate,
        // storeIDs: storesSelected ? storesSelected.map((store) => store.id) : [],
        // limit: 50,
        // province: state.city.citySelected.province,
      };
      // console.log(postData)
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/trend-report/brand`;
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: state.session.token,
        },
      });
      // console.log(result.data.data)
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    
    fetchDataSource();
  }, [storesSelected, fromDate,toDate]);  

    
  return (
    <div className={classes.root}>
      <Menu title={"Brand Trends for"} 
        formDateToDateSelectedButtonEnable 
        // addBrandButtonEnable 
        selectedProvinceEnable
        />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <ToolbarPanel isLoading={isLoading} openCompareDialog={handleCompareDialogOpen}/>
          <Grid container spacing={3}>            
            <Grid item xs={12} md={12} lg={12}>
              {isLoading ? (
                <Loading />
              ) : (
                <>
                 <TrendCompareDialog
                    dataSource={dataSource}
                    open={openCompareDialog}
                    handleClose={handleCompareDialogClose}
                  />
                  <TrendDetailDialog
                    trendSelected={trendSelected}
                    open={open}
                    handleClose={handleClose}
                  />
        
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                  square
                  style={{background:"white"}}
                >
                 
                  <Table stickyHeader aria-label="brand trends">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"  className={classes.thHeader} width='150px'>Brand</TableCell>
                        <TableCell align="left"  className={classes.thHeader} width='100px'></TableCell>
                      
                        <TableCell align="center"  className={classes.thHeader} width='650px'>Price Trend</TableCell>  
                        <TableCell align="left"  className={classes.thHeader} width='120px'>Start Avg $</TableCell>                 
                        <TableCell align="left"  className={classes.thHeader} width='120px'>End Avg $</TableCell>                 
                                      
                        <TableCell align="left"  className={classes.thHeader} width='200px'>Price % Change</TableCell>                 
                        <TableCell align="left"  className={classes.thHeader}></TableCell>                 
                      </TableRow>
                      </TableHead>
                    <TableBody>
                    {dataSource.labels &&
                      dataSource.labels.map((row, index) => {
                        return (
                          <TableRow hover key={index} className={classes.tableRow} >
                            <TableCell align="left"  title={row.label}>
                              <BrandImages brand={row} onClick={() => handleCurrentRowOpen(row)}/>
                            </TableCell>
                            <TableCell align="left"  title={row.label}>
                              <div
                                className={classes.hoverClickPopUp}
                                value={row.currentPrice}
                                style={{ color: "#204F6C" }}
                                onClick={() => handleOpenDetailClick(row.id)}
                                >
                                {row.label}
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <div className={classes.lineGraphContainer} onClick={() => handleCurrentRowOpen(row)}>
                                <LineGraph
                                  key={index}
                                  storeProductPrices={row.prices}
                                  columnField={'avg_price'}
                                />
                              </div>
                            </TableCell>
                            <TableCell align="left"> 
                              <PriceComponent value={row.start_avg} />
                            </TableCell>                            
                            <TableCell align="left"> 
                              <PriceComponent value={row.end_avg} />
                            </TableCell>  
                            <TableCell align="left"> 
                              {/* <span>{row.percent_change>0?'+ '+row.percent_change:'- '+Math.abs(row.percent_change)}%&nbsp;</span> */}
                              <span>{Math.round((row.end_avg- row.start_avg)*1000/row.start_avg)/10}%&nbsp;</span>
                            </TableCell>                            
                            <TableCell align="left">     
                            </TableCell>                            
                          </TableRow>
                        )})}
                    </TableBody>
                    <TableFooter>
                    
                    </TableFooter>
                  </Table>
                </TableContainer>
                </>
              )}
            </Grid>
          </Grid>
        </Container>
        
      </main>
      
    </div>
  );
}
