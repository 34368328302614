import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "./Title";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import axios from "axios";
import envConfig from "../../bin/env.config";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  TrendingIcon: {
    fontSize: "120px",
  },
});

export function BiggestPriceIncrease({ storesSelected }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    setIsLoading(true);
    const fetchDataSource = async () => {
      // console.log('here'+searchText);

      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/analytics/biggest/increase?limit=1`;
      const result = await axios.post(url, {
        storeIDs: storesSelected.map((store) => store.id),
      });
      const { changed_percent } = result.data.data[0];
      setPercentage(changed_percent);
    };
    fetchDataSource();
    setIsLoading(false);
  }, [storesSelected]);
  return (
    <>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <>
          <Title>Increases</Title>
          <Typography component="p" variant="h3" color="secondary">
            {Math.abs(percentage.toFixed(2))}%
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            Biggest price increases
          </Typography>
          <TrendingUpIcon className={classes.TrendingIcon} color="secondary" />
        </>
      )}
    </>
  );
}
export const BiggestPriceIncreaseThisWeek = React.memo(BiggestPriceIncrease);
