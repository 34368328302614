import {SET_LOG_IN_USER, SET_AUTH_TOKEN, LOG_OUT} from '../types'
import axios from 'axios'
import envConfig from "../../bin/env.config";

import {
  fetchCategories
  } from "./categoryAction";
import {
  fetchMenus
    } from "./menuAction";
import {
  fetchCities
  } from "./cityAction";
import {
  fetchStores
} from "./storeAction";
import {
  fetchProducts
} from "./productAction";
import {
  fetchCustomerProducts
} from "./customerProductAction";
import {
  fetchWeeks
} from "./weekAction";
import {
  fetchColumns
} from "./columnAction";
import {
  fetchProductLists
} from "./reportAction";


export const setLogInLoader = (showLoginLoader = true) => ({
  type: 'SET_LOG_IN_LOADER',
  showLoginLoader
});

export const setLogInError = (loginError) => ({
  type: 'SET_LOG_IN_ERROR',
  loginError
});

export const setLogInUser = (user = {}) => {  
  return {
    type: SET_LOG_IN_USER,
    user
 }   
};
export const setAuthToken = (data = { user: {}, token: '' }) => {
  return (dispatch) => {
    dispatch(setLogInUser(data.user))
    dispatch({
      type: SET_AUTH_TOKEN,
      token: data.token
    });
  };
};

export const logout = () => {
  localStorage.removeItem("bettercartAnalytics");
  localStorage.removeItem("userDataT");
  localStorage.removeItem("liveProducts");
  return {
     type: LOG_OUT
  } 
 }

export const login = (username, password) => {
  return (dispatch, getState) => {

    return new Promise((resolve, reject) => {
      dispatch(setLogInLoader(true));
      dispatch(setLogInError(null));

      // let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/auth`;
      let url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/auth/analytics`;
     
      axios.post(url, {
          username: username,
          password: password,
        })  
        .then((response) => {
          const data = response.data.data;
            dispatch(setLogInLoader(false));
            dispatch(setAuthToken(data));
            resolve(data);
        })
        .catch((err) => {
          reject(err);
          dispatch(setLogInLoader(false));
          dispatch(setLogInError(err));
        });
    })
  };
}
export const loadData = (user)=>{
  return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchCities('SK')).then((city)=>{
          dispatch(fetchStores(city.name)).then((storesSelected)=>{
            dispatch(fetchProducts(storesSelected,user.default_pmg_id)).then(()=>{                         
                  dispatch(fetchMenus(user.menu)) 
                  dispatch(fetchCategories())                  
                  dispatch(fetchProductLists())
                  dispatch(fetchWeeks())
                  dispatch(fetchColumns()) 
                  dispatch(fetchCustomerProducts(user.default_customer_product_id)) 
                  resolve(true)      
                                     
            }).catch((err)=>{
              reject(err)
            })
          }).catch((err)=>{
            reject(err)
          })
        }).catch((err)=>{
          reject(err)
        })  
      })     
  }   
}