import React,{useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import envConfig from "../../bin/env.config";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from "react-redux";
import {
  TableCell,

} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  
  
}));
export function LoadingSpan(){ 
  const classes = useStyles();
  return (
      <CircularProgress size={16} />
    )
}

export function ChangeDirectionTag({planPrice}){
  const direction = planPrice.change_direction
  const dayChange = planPrice.days_change
return (
      <TableCell style={{width:'15px',backgroundColor:`${dayChange===0?"#fb9797":"#d8d8d8"}`}}>
        <img src={`/resources/images/${direction?"up":"down"}.png`} style={{height:'18px',align:'center',verticalAlign:'middle',border:'none',textDecoration:'none'}}/>
        <span style={{fontSize:`8px`, fontWeight:'bold'}}>
        {dayChange}D
        </span>
      </TableCell>
    )
}
export function PlanPriceTag({plan, planPrice}){
  return(
        <TableCell style={{textAlign:'center',backgroundColor:`${planPrice.price_today===planPrice.price_today_min?"#c7f8c4":""}`}}>
          <div style={{fontSize:`12px`}}>
            {plan.name}
          </div>
          {planPrice.price_today>0&&
            <div style={{fontSize:`12px`, fontWeight:'bold'}}>
              ${planPrice.price_today.toFixed(2)}
            </div>         
          }
        </TableCell>
  )
}
export function CellPrint({plan,planPrice,displayColumns}){
  const classes = useStyles();
  const {changeDirection, priceToday} = displayColumns
  return (
    <>
    {plan?
      ( <>        
          {changeDirection&&<ChangeDirectionTag planPrice={planPrice} type={0}/>}         
          {priceToday&&<PlanPriceTag plan={plan} planPrice={planPrice}/>}          
        </>
      ):      
      <TableCell colSpan={Object.keys(displayColumns).length}></TableCell>
    }
    </>
    )
}

export default function PlanProviderCell({plan, provider, displayColumns}) {  
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [planPrice, setPlanPrice] = useState(undefined);
  const state = useSelector(state => state);
  const toDate = state.week.toDateSelected.date;
  Object.keys(displayColumns).forEach(key => {
    if (!displayColumns[key]) {
      delete displayColumns[key];
    }
  });
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/sasktel/plan-price`;
      const postData = {
        toDate: toDate,
        plan_id: plan.id,
        provider: provider.id
      };
      
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });    
      setPlanPrice(result.data.data);
      setIsLoading(false);
    };
    plan&&fetchData();
  }, [toDate,plan, provider]); 
  
  return (
    <>
      {plan&&planPrice?
        <CellPrint plan={plan} planPrice={planPrice} displayColumns={displayColumns}/>
        :<TableCell colSpan={Object.keys(displayColumns).length}></TableCell>
      }     
    </>
  );
}
