import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableRow,
  TableCell,
 } from "@material-ui/core";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import ReportListDetail from "./ReportListDetail";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
const useStyles = makeStyles((theme) => ({
 
}));
export default function ReportListRow({ productList }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleRemove = (id) => {
    // dispatch(removeFromCart(product))
  };
  return (
    <>
      <TableRow hover className={classes.tableRow}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" className={classes.showText}>
          {productList.id}
        </TableCell>
        <TableCell align="left" className={classes.showText}>
          {productList.name}
        </TableCell>
        <TableCell align="center">
          {productList.id>2&&
          <IconButton aria-label="delete" onClick={() => handleRemove(productList.id)}>
            <RemoveCircleOutlineIcon />
          </IconButton>
          }
        </TableCell>
      </TableRow>
      <TableRow className={open && classes.rowCollapse}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ReportListDetail productList={productList} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
