import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
  root: {
      marginLeft:"10px", 
      padding:"0px 10px",
      borderWidth:"2px",
      '&:hover':{
        borderWidth:"2px",
      }    
  },
  label:{
      textTransform: "capitalize",
      fontSize: '1.25rem',       
      fontWeight: "500",
      '& >.MuiButton-iconSizeMedium > *:first-child ':{
        fontSize: '2rem',
      }
    }
  }));
// import StoreIcon from "@material-ui/icons/Store";

export default function SelectMonth({months, selected,onSelected, disabled}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const handleChange = (month) => {    
    onSelected&&onSelected(month)
    setAnchorEl(null);
  };    

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick  = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (  
    <>
      <Button
        variant="outlined"
        classes={{root:classes.root, label:classes.label}}
        color="primary"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon/>}
        disabled = {disabled}
      >
       {months[selected].name}
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {months.map((m,index) => (
          <MenuItem
            key={index}           
            selected={selected === index}
            onClick={() => handleChange(index)}
          >
            {m.name}
          </MenuItem>
        ))}
      </Menu>      
    </>
   
  );
}

