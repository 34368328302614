import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Toolbar from "@material-ui/core/Toolbar";
import CompareButton from "./CompareButton"
import SaveImageButton from "./SaveImageButton"
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  toolbarContainer:{
    padding: theme.spacing(1),
  }, 
  productImage: {
    width: "150px",
    height: "150px",
    objectFit: "contain", //
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column  ",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  productName: {
    textTransform: "capitalize",
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
}));

export default function ToolbarPanel({isLoading,trendReportLabel, openCompareDialog,exportFunction}) {
  const classes = useStyles();
  return (  
    

<Grid container className={classes.toolbarContainer}>
      <Grid item lg={9} sm={9} xl={9} xs={9} m={2}>
      {!isLoading&&trendReportLabel&&(
        <div className={classes.productSelectedButton}>
          <div className={classes.container}>
            <img
              className={classes.productImage}
              src={trendReportLabel.image_path}
              style={{maxHeight:'100px',maxWidth:'200px'}}
            />
            <div className={classes.titleContainer}>
              <div className={classes.productName}>                
                {trendReportLabel.label}
              </div>             
            </div>
          </div>
        </div>)}
      </Grid>
      <Grid
        container
        item
        lg={3}
        sm={3}
        xl={3}
        xs={3}
        m={2}
        style={{justifyContent:'center', alignItems:'center'}}
      >
        
        {!isLoading&&openCompareDialog&&<CompareButton onClick={openCompareDialog}/>}
        {!isLoading&&exportFunction&&<SaveImageButton onClick={exportFunction}/>}        
      </Grid>
    </Grid>
  );
}
