import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../../bin/env.config";
import Loading from "../../../components/Loading/Loading";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ReportListDetailRow from "./ReportListDetailRow";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  productImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
}));
export default function ReportListDetail({ productList }) {
  const classes = useStyles();
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/reports/report-list-detail/${productList.id}`;
      const result = await axios.get(url, {
        headers: {
          Authorization: state.session.token,
        },
      });
      //prepare data
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    fetchDataSource();
  }, [productList]);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="center">Id</TableCell>
                <TableCell>Product name</TableCell>
                <TableCell align="center">Number matching group</TableCell>
                <TableCell>Command</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource &&
                dataSource.map((row, index) => {
                  return (
                    <ReportListDetailRow item={row}/>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
