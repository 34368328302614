import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { useDispatch, useSelector } from "react-redux";
import {
  selectToDate
} from "../../store/actions/weekAction";
import { format } from 'date-fns'
import { convertToViewDate } from "../../utility/utility.js";
const useStyles = makeStyles((theme) => ({
  root: {
      marginLeft:"10px", 
      padding:"0px 10px",
      borderWidth:"2px",
      '&:hover':{
        borderWidth:"2px",
      }    
  },
  datePickerInput: {
      marginLeft: "10px",
      marginRight: "10px",
      width: "150px",
      //  display:"none"
      "&:hover": {
        cursor: "pointer",
      },
      "& > input": {
        textAlign: "center",
        padding: "10px 0px",
        fontSize: "1.25rem",
        fontWeight: "500",
        textTransform: "capitalize",
        color: `#204F6C`,
      },
      "& > input:hover": {
        cursor: "pointer",
      },
      "& > fieldset": {
        top: "0px",
        marginTop: "-2px",
        color: `#204F6C`,
        border: "2px solid rgba(32, 79, 108, 0.5)",
      },
      "& >.MuiOutlinedInput-notchedOutline:hover": {
        border: `1px solid #204F6C`,
        backgroundColor: `rgba(32, 79, 108, 0.04)`,
        cursor: "pointer",
      },
    },
}));
// import StoreIcon from "@material-ui/icons/Store";

export function SelectToDate() {
  const classes = useStyles();
  const toDateSelected = useSelector(state => state.week.toDateSelected);
  const [selectedToDate, setSelectedToDate] = useState(toDateSelected.date);
  const dispatch = useDispatch()
  const handleToDateChange = (toDate) => {    
    setSelectedToDate(toDate)      
    dispatch(selectToDate({
      date: format(toDate, 'yyyy-MM-dd'),
      viewDate: convertToViewDate(toDate) 
    }))
  };
  return (  
      <DatePicker
          InputProps={{ className: classes.datePickerInput }}
          style={{verticalAlign:'middle'}}
          variant="inline"
          inputVariant="outlined"
          openTo="date"
          views={["date"]}
          format="MMM dd yyyy"
          // label="Year and Month"
          // helperText="Start from year selection"
          value={selectedToDate}
          onChange={handleToDateChange}
          maxDate={new Date()}  //maxDate
          // disabled={isLoading}         
        />
  );
}
export const MemoizedSelectToDate = React.memo(SelectToDate);
