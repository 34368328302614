import React,{useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import envConfig from "../../bin/env.config";
import Skeleton from "react-loading-skeleton";
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from "react-redux";
import {
  TableCell,
  TableRow,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

import {
  DialogTitle,
  DialogContent,
  DialogActions
} from "../Dialog/DialogCustom";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  
  
}));
export function LoadingSpan(){ 
  const classes = useStyles();
  return (
      <CircularProgress size={16} />
    )
}

export function ChangeDirectionTag({devicePrice, type=0}){
  const direction = type===0?
                      (devicePrice.keep_day_change<devicePrice.return_day_change?
                        devicePrice.keep_change_direction:devicePrice.return_change_direction)
                      :(type===1?devicePrice.keep_change_direction:devicePrice.return_change_direction)
  const dayChange = type===0?
                      (devicePrice.keep_day_change<devicePrice.return_day_change?
                        devicePrice.keep_day_change:devicePrice.return_day_change)
                      :(type===1?devicePrice.keep_day_change:devicePrice.return_day_change)
return (
      <TableCell style={{width:'15px',backgroundColor:`${dayChange===0?"#fb9797":"#d8d8d8"}`}}>
        <img src={`/resources/images/${direction?"up":"down"}.png`} style={{height:'18px',align:'center',verticalAlign:'middle',border:'none',textDecoration:'none'}}/>
        <span style={{fontSize:`8px`, fontWeight:'bold'}}>
        {dayChange}D
        </span>
      </TableCell>
    )
}
export function DevicePriceTag({price, priceMin}){
  const priceYear = price*24
  return(
        <TableCell style={{textAlign:'center',backgroundColor:`${price===priceMin?"#c7f8c4":""}`}}>
          <div style={{fontSize:`12px`, fontWeight:'bold'}}>
            ${price.toFixed(2)}
          </div>
          <div style={{fontSize:`12px`, color:'#b4b4b4'}}>
            ${priceYear.toFixed(2)}
          </div>
        </TableCell>
  )
}
export function CellPrint({devicePrice,displayColumns}){
  const classes = useStyles();
  const {changeDirectionMin, keepChangeDirection, keepToday, returnChangeDirection, returnToday} = displayColumns
  return (
    <>
    {devicePrice.keep_today>0?
      ( <>        
          {changeDirectionMin&&<ChangeDirectionTag devicePrice={devicePrice} type={0}/>} 
          {returnChangeDirection&&<ChangeDirectionTag devicePrice={devicePrice} type={2}/>}         
          {returnToday&&<DevicePriceTag price={devicePrice.return_today} priceMin={devicePrice.return_today_min}/>}
          {keepChangeDirection&&<ChangeDirectionTag devicePrice={devicePrice} type={1}/>}         
          {keepToday&&<DevicePriceTag price={devicePrice.keep_today} priceMin={devicePrice.keep_today_min}/>}
        </>
      ):      
      <TableCell colSpan={Object.keys(displayColumns).length}></TableCell>
    }
    </>
    )
}

export default function DeviceProviderCell({device, provider, displayColumns}) {  
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [devicePrice, setDevicePrice] = useState(undefined);
  const state = useSelector(state => state);
  const toDate = state.week.toDateSelected.date;
  Object.keys(displayColumns).forEach(key => {
    if (!displayColumns[key]) {
      delete displayColumns[key];
    }
  });
  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/sasktel/device-price`;
      const postData = {
        toDate: toDate,
        device_id: device.id,
        provider: provider.id
      };
      
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });    
      setDevicePrice(result.data.data);
      setIsLoading(false);
    };
    fetchData();
  }, [toDate,device, provider]); 
  
  return (
    <>
      {!isLoading&&devicePrice?
        <CellPrint devicePrice={devicePrice} displayColumns={displayColumns}/>
        :<TableCell colSpan={Object.keys(displayColumns).length}><LoadingSpan/></TableCell>
      }     
    </>
  );
}
