import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  TrendingIcon:{
    fontSize:"120px"
  }
});

export default function ProductsAddedThisWeek() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>Added</Title>
      <Typography component="p" variant="h3"  color="secondary">
        518
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
      Products added
      </Typography>      
      <AddCircleOutlineOutlinedIcon className={classes.TrendingIcon} color="secondary"/>  
    </React.Fragment>
  );
}