import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MLink from "@material-ui/core/Link";
const useStyles = makeStyles((theme) => ({    
  contentRight:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width:"50%"    
  },
  logoRight:{
    width: "50%",
    maxWidth:"160px",
    minWidth: "120px",
  },
  textRight:{
    fontSize:"3em",
    color:"white",
    textAlign:"center",
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },   
}));
export function Copyright() {
  return (
    <Typography variant="body1"  align="center">
      {"Copyright © "}
      <MLink color="inherit" href="https://analytics.bettercart.ca/">
        BetterCart Analytics
      </MLink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
export function RightPanel() { 
  const classes = useStyles();
  return(
    <div className={classes.contentRight}>
    <img
    src="/resources/images/BetterCart-Icon-white.png"
    alt="BetterCart"
    className={classes.logoRight}
  />
  <div className={classes.textRight} >
    Powering your competitive advantage
  </div>
  </div>
  )
}