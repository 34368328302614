import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSort } from "@fortawesome/free-solid-svg-icons";
import RowDetail from "./RowDetail";
import axios from "axios";
import envConfig from "../../bin/env.config";
import Loading from "../../components/Loading/Loading";
import { PriceComponent, randomNumber } from "../../utility/utility";
import { format, addDays, parseISO } from "date-fns";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "calc(100vh - 128px)",
    boxShadow: "none",
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },

  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  sortLabel: {
    fontSize: "1rem",
  },
  tableRow: {},
  trHeader: {
    height: "64px",
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding: "16px 8px",
  },
  thHeaderFirst: {
    backgroundColor: "#DDD",
    paddingLeft: "24px",
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

export function SortLabel(props) {
  const classes = useStyles();
  return (
    <TableSortLabel
      className={classes.sortLabel}
      IconComponent={(props) =>
        props.hideSortIcon ? "" : <FontAwesomeIcon icon={faSort} />
      }
    >
      {props.nowrapExtra ? (
        <span style={{ paddingRight: "5px", whiteSpace: "nowrap" }}>
          {props.children}
        </span>
      ) : (
        <span style={{ paddingRight: "5px" }}>{props.children}</span>
      )}
    </TableSortLabel>
  );
}

export function StoreLabel({ stores, store_id }) {
  const classes = useStyles();
  const currStore = stores.find((p) => p.id == Number(store_id));
  return (
    <div>
      <img className={classes.storeLogo} src={currStore.company_logo_link} />
      <span>{currStore.name}</span>
    </div>
  );
}
export default function Overview() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  const state = useSelector((state) => state);

  const fromDate = state.week.weekSelected.first;
  const toDate = format(
    addDays(parseISO(state.week.weekSelected.first), 6),
    "yyyy-MM-dd"
  );
  const stores = state.store.stores;
  const storesSelected = state.store.storesSelected;
  const cart = state.cart.cart;

  const postData = {
    from_date: fromDate,
    to_date: toDate,
    storeIDs: storesSelected ? storesSelected.map((store) => store.id) : [],
    productMatchingGroupIds: cart.map((product) => product.id),
    province: state.city.citySelected.province,
    limit: -1,
  };
  useEffect(() => {
    const fetchDataSource = async () => {
      // console.log('here'+searchText);
      setIsLoading(true);

      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/cart-comparison`;
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: state.session.token,
        },
      });

      const dataSourceInit = [];
      result.data.data.forEach((record) => {
        let store = stores.find((p) => p.id === record.store_id);
        record.matched === cart.length &&
          dataSourceInit.push({
            store_id: record.store_id,
            store_name: store.name,
            company: store.company_name,
            company_logo_link: store.company_logo_link,
            matched: record.matched,
            total_amount: record.total_amount,
            percent_expensive: 0,
          });
      });
      const tempDataSource1 = dataSourceInit.sort(
        (a, b) => a.total_amount - b.total_amount
      );
      const cheapestCartPriceInList = tempDataSource1[0]
        ? tempDataSource1[0].total_amount
        : 0;

      const tempDataSource = tempDataSource1.map((store) => {
        let percentExpensive =
          store.total_amount === 0
            ? 0
            : Math.round(
                ((store.total_amount - cheapestCartPriceInList) /
                  store.total_amount) *
                  100
              );
        return {
          ...store,
          percent_expensive: percentExpensive,
        };
      });
      state.city.citySelected.province && setDataSource(tempDataSource);
      setIsLoading(false);
    };
    // console.log(postData);
    (postData.storeIDs.length > 0 &&
      postData.productMatchingGroupIds.length > 0)?fetchDataSource():setDataSource([])
  }, [fromDate, storesSelected, cart]);

  const [orderDirection, setOrderDirection] = useState(1);

  const handleSort = (column) => {
    setOrderDirection(-orderDirection);
    let tempDataSource = [...dataSource];
    column === "percent_expensive"
      ? tempDataSource.sort((a, b) => (a[column] - b[column]) * orderDirection)
      : tempDataSource.sort((a, b) =>
          a[column] > b[column]
            ? 1
            : (b[column] > a[column] ? -1 : 0) * orderDirection
        );
    setDataSource(tempDataSource);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer component={Paper} className={classes.container} square>
          <Table stickyHeader aria-label="cart comparison overview">
            <TableHead>
              <TableRow className={classes.trHeader}>
                <TableCell className={classes.thHeader} width="30px" />
                <TableCell
                  align="left"
                  className={classes.thHeader}
                  width="200px"
                >
                  <SortLabel onClick={() => handleSort("company")}>
                    Company
                  </SortLabel>
                </TableCell>
                <TableCell align="left" className={classes.thHeader}>
                  <SortLabel onClick={() => handleSort("store_name")}>
                    Store
                  </SortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.thHeader}
                  width="50px"
                >
                  <SortLabel hideSortIcon>Matched</SortLabel>
                </TableCell>
                <TableCell className={classes.thHeader} width="350px" />

                <TableCell
                  align="center"
                  className={classes.thHeader}
                  width="30px"
                >
                  <SortLabel hideSortIcon nowrapExtra>
                    Basket $
                  </SortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  className={classes.thHeader}
                  width="150px"
                >
                  <SortLabel hideSortIcon nowrapExtra>
                    % More Expensive
                  </SortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource &&
                dataSource.map((row, index) => (
                  <RowDetail key={index} row={row} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
