import { useEffect } from "react";
import L from "leaflet";
import "./Legend.css";

function Legend({ map , priceRangeColors}) {
    
    useEffect(() => {
      if (map) {
        var legend = L.control({position: 'topright'});
        legend.onAdd = function () {
            var div = L.DomUtil.create('div', 'info legend'),
            labels = []
           

            for (var i = 0; i < priceRangeColors.length; i++) {
                    div.innerHTML += 
                    labels.push(
                        '<i class="circle" style="background:' + priceRangeColors[i].color + '"></i> ' +
                    '$' + priceRangeColors[i].min.toFixed(2)+ ' - $' + priceRangeColors[i].max.toFixed(2));

                }
                div.innerHTML = labels.join('<br>');
            return div;
        };
        legend.addTo(map);
      }
    }, [map]); //here add map
    return null;
}

export default Legend;