import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Checkbox, TableHead } from "@material-ui/core";
import {DialogTitle,DialogContent,DialogActions } from '../Dialog/DialogCustom';
import {
  Table,
  TableBody,
  TableCell,  
  TableRow,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import {
  selectStores
  } from "../../store/actions/storeAction";
import SearchBox from "./SearchBox";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(0 176 79 / 10%)",
      color: "#fff",
    },
  }, 
}));
export default function SelectStoreDialog({
  open,
  handleClose,
}) {
  const classes = useStyles();
  const store = useSelector(state => state.store);
  const dispatch = useDispatch()

  const [selected, setSelected] = useState(store.storesSelected);
  const [stores, setStores] = useState(store.stores);
  const [searchTermBanner, setSearchTermBanner] = useState("");
  const [searchTermStore, setSearchTermStore] = useState("");
  const doSearchBanner = (q) => {
    //doSearch
    setSearchTermBanner(q);
    setStores(
      store.stores.filter((item) => {
        let ret = item.company_name.toLowerCase().includes(q.toLowerCase())
        if(searchTermStore) ret = ret&& item.name.toLowerCase().includes(searchTermStore.toLowerCase())
        return ret 
      })
    );
  };
  const doSearchStore = (q) => {
    //doSearch
    setSearchTermStore(q);
    setStores(
      store.stores.filter((item) => {
        let ret = item.name.toLowerCase().includes(q.toLowerCase())
        if(searchTermBanner) ret = ret&& item.company_name.toLowerCase().includes(searchTermBanner.toLowerCase())
        return ret
      })
    );
  };
  const handleChange = (isChecked, select) => {
    if (!isChecked) {
      setSelected([...selected,select]);      
    } else {
      setSelected(selected.filter((item) => item.id !== select.id));
    }
  };
  const handleChangeAll = (isChecked) => { 
    if (isChecked) {
      setSelected([])
    }else{
      setSelected([...stores])
    }    
  };
  const handleSave = () => {    
    dispatch(selectStores(selected));
    handleClose();
  };
  const handleCancel = () =>{
    setSelected(store.storesSelected);
    handleClose();
  }
  useEffect(() => {   
    setStores(store.stores)
    setSelected(store.storesSelected)    
  }, [store]);
  return (    
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Select stores
        </DialogTitle>
        <DialogContent dividers>
          <Table size="small"> 
            <TableHead>
              <TableRow>
                <TableCell align="right">#</TableCell>
                <TableCell align="center">
                  <Checkbox
                          id={`chk_store_all`}
                          checked={selected.length ===store.stores.length}
                          onChange={() => handleChangeAll(selected.length ===store.stores.length)}
                  />
                </TableCell>
                {/* <TableCell align="left"><SearchBox placeholder="Banner" id="banner-search-form"  value={searchTermBanner} doSearch={doSearchBanner} iconButton={false} /></TableCell>  */}
                <TableCell align="left">Banner</TableCell> 
                <TableCell align="left">Store Location</TableCell> 
                {/* <TableCell align="left">
                <SearchBox placeholder="Store Location" id="store-search-form" value={searchTermStore} doSearch={doSearchStore} iconButton={false}/>
                </TableCell>  */}
              </TableRow>
            </TableHead>          
            <TableBody>
              {stores.map((row, i) => {
                let isChecked =  selected.some(el => el.id === row.id);
                return (
                  <TableRow key={i}
                  className={classes.tableRow} onClick={() => handleChange(isChecked, row)}>
                    <TableCell align="right">{i + 1}</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        id={`chk_store_${row.id}`}
                        checked={isChecked}
                        // onChange={() => handleChange(isChecked, row)}
                      />
                    </TableCell>                    
                    <TableCell>{row.company_name}</TableCell>    
                    <TableCell>{row.name}</TableCell>                                        
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button id="btn_stores_cancel" onClick={handleCancel} color="default">
            Cancel
          </Button>
          <Button id="btn_stores_save" autoFocus onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
   
  );
}
