import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import { MemoizedMenu } from "../components/Menu";
import Overview from "../components/NewDiscontinuedProducts/Overview";
import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { format,addDays, parseISO } from 'date-fns'

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    padding:'0px',
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function NewProductsOverview() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const state = useSelector(state => state);
  
  const selectedCategory = state.category.categories&&state.category.categories.find(category=>category.selected);
  const fromDate = state.week.weekSelected.first;
  const toDate = format(addDays(parseISO(state.week.weekSelected.first),6),'yyyy-MM-dd');
  const storesSelected = state.store.storesSelected
  const postData = {
    from_date: fromDate,
    to_date: toDate,
    storeIDs: storesSelected
      ? storesSelected.map((store) => store.id)
      : [],
    limit: 5,
    province: state.city.citySelected.province,  
    categoryId:selectedCategory?selectedCategory.id:0
  };
  

  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    const fetchDataSource = async () => {      
      // console.log('here'+searchText);
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/new-products`;
      const result = await axios.post(url, postData,
                                      {
                                        headers: {
                                          Authorization: state.session.token,
                                        },
                                      });
      // console.log(result.data.data)
      const dataSourceInit = storesSelected.map((store)=>{
        let temp = result.data.data.find((p) => p.store_id == store.id)
        return {
          store_id:store.id,
          store:store.name,
          company:store.company_name,
          company_logo_link:store.company_logo_link,
          number_of_products:temp?temp.number_of_products:0,
          products: temp?temp.products:[]
        }
      })
      setDataSource(dataSourceInit.sort((a,b)=>b.number_of_products-a.number_of_products));
      setIsLoading(false);
    };
    // console.log(postData);
    fetchDataSource();
  }, [selectedCategory,storesSelected,fromDate]);
  return (
    <div className={classes.root}>
      <MemoizedMenu title={"New Products for the Week of"} weekSelectedButtonEnable  
          selectedCategoryEnableEnd 
          selectedCityEnable
          selectedStoreEnable
          />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Overview dataSource={dataSource} />
            </>
          )}
        </Container>
      </main>
    </div>
  );
}
