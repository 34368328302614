import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import envConfig from "../../bin/env.config";
import  SummaryBox  from "./SummaryBox";
import  SimilarProductImage  from "./SimilarProductImage";
import TagsDisplay from '../../utility/TagsDisplay';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    backgroundColor: "white",
    width: "calc(100vw - 240px)",
    paddingLeft: "24px",
    paddingRight: "24px",
  },
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  productSummaryInformation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",

  },
  summaryBox: {
    border: "2px solid #DDD",
    boxShadow: 'None',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: `0px 8px`,
    height: "50px",
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
  },
  productImage: {
    width: "150px",
    height: "150px",
    objectFit: "contain", //
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column  ",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  productName: {
    textTransform: "capitalize",
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  productSize: {
    textTransform: "capitalize",
    fontFamily: "Roboto",
  },
  btnSelected: {
    textTransform: "none",
    marginLeft: theme.spacing(1),
  },
  menuButtonGroup: {
    float: "right",
    display: "block",
    marginTop: "25px"
  }
}));

const Toolbar = ({ mapButton = true, columnButton = true }) => {
  const classes = useStyles();
  const productSelected = useSelector((state) => state.customerProduct.productSelected);
  // const summary = useSelector((state) => state.report.singleProductPricing);
  const urlSummaryMinMax = `${envConfig.API_ROOT}${envConfig.API_VERSION}/similar-pricing-tool/summary-min-max`;

  return (
    <div className={classes.root} >
      {/* <div className={classes.menuButtonGroup}>
        {mapButton && <PriceMapButton />}
        {columnButton && <SelectColumns />}
      </div> */}
      <div className={classes.productSelectedButton}>
        <div className={classes.container}>
          <SimilarProductImage/>
          <div className={classes.titleContainer}>
            <div className={classes.productName}>
              {/* #{productSelected.upc} - {productSelected.brandName&&`[${productSelected.brandName}]`} {productSelected.name} */}
              {productSelected.name}
            </div>
            <TagsDisplay tagsString={productSelected.key_words} />
            <div className={classes.productSummaryInformation}>
              <SummaryBox title="Min" apiUrl={urlSummaryMinMax} whichType='selected-min'/>  
              <SummaryBox title="Max" apiUrl={urlSummaryMinMax} whichType='selected-max'/>                
              {/* <Hidden smDown>
                <VolatilityBox/>                
              </Hidden> */}
              <Hidden smDown>
                <SummaryBox title="Historical Min" apiUrl={urlSummaryMinMax} whichType='historical-min'/>
                <SummaryBox title="Historical Max" apiUrl={urlSummaryMinMax} whichType='historical-max'/>
              </Hidden>
            </div>
          </div>
        </div>
      </div>
      <Divider style={{marginLeft:"-24px",marginRight:"-24px"}} />
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
