
import {GET_CUSTOMER_PRODUCTS, SELECT_CUSTOMER_PRODUCT, CUSTOMER_PRODUCT_ERROR} from '../types'
const initialState = {
    products:[],
    productSelected:{
    },
    loading:true,
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_CUSTOMER_PRODUCTS:
            return {
                ...state,
                products:action.payload,
                loading:false
            } 
        case SELECT_CUSTOMER_PRODUCT:
            return {
                ...state,
                productSelected:action.payload
            }
                  
        // case PRODUCT_ERROR:
        //     console.log("x"*100);
        //     console.log(action);
        //     return{
        //         loading: false, 
        //         error: action.payload 
        //     }
        default: return state
    }

}