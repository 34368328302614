import React,{useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router-dom";
import {
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";

import DeviceProviderCell from "./DeviceProviderCell";
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "90vh",
    boxShadow: "none",
  },
  providerLogo:{
    maxHeight: "50px",
    maxWidth: "170px",
  }  
})); 
export default function ProviderRow({device, provider}) { 
  // const history = useHistory(); 
  const classes = useStyles();
  // const handleRowClick =()=>{
  //   history.push(`/sasktel-device-detail/${device.id}`)    
  // }
  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell
          align="center"
          // style={{backgroundColor:provider.color}}
        >
          <img src={provider.logo_url} className={classes.providerLogo}/>
      </TableCell>    
      <TableCell></TableCell>  
      {provider.id==='sasktel'?(
        <>
          <TableCell colSpan={2}></TableCell>  
          <TableCell colSpan={2}></TableCell>  
          <DeviceProviderCell device={device} provider={provider} displayColumns={{keepChangeDirection:true, keepToday:true}}/>
        </>  
      ):(
        <>
          <DeviceProviderCell device={device} provider={provider} displayColumns={{keepChangeDirection:true, keepToday:true}}/>
          <DeviceProviderCell device={device} provider={provider} displayColumns={{returnChangeDirection:true, returnToday:true}}/>
          <TableCell colSpan={2}></TableCell>  
        </>  
      )}
      <TableCell></TableCell>  
                          
    </TableRow>
    
  );
}
