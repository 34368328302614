import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";


import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";


import { MemoizedMenu } from "../components/Menu";
import RegularPrice from "../components/SRPPricingTool/RegularPrice";
// import RegularPriceTest from "../components/SRPPricingTool/RegularPriceTest";
// import ModeRegularPrice from "../components/SRPPricingTool/ModeRegularPrice";
import Toolbar from "../components/SRPPricingTool/Toolbar.js";
import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { useSelector } from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  toolBarSpacer: {
    display: "block",
    minHeight: '168px'    
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },  

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function SingleProductPricing({goBackButtonEnable}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  
  const state = useSelector(state => state);
  // console.log(state)
  const productSelected = state.product.productSelected;
  
  const [companyColours, setCompanyColours] = useState(undefined)
  

  useEffect(() => {
    const fetchCompanyColours = async () => {
      setIsLoading(true);      
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company_colours`;
      const result = await axios.get(url);
      let dictColours = {}
      result.data.data.map(({id,colour})=> 
        dictColours[id]= colour
      );
      setCompanyColours(dictColours);
      setIsLoading(false);
    };
    fetchCompanyColours();
  }, []);

  
  
  // if (!productSelected) {
  //   return <Redirect to="/login" />;
  // }
  return (
    <div className={classes.root}>
      <MemoizedMenu goBackButtonEnable={goBackButtonEnable} title={"Exact Product Matches  "} 
          selectedProductEnable 
          selectedCityEnable
          selectedStoreEnable
          formDateToDateSelectedButtonEnable
          />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {productSelected && (
          <Container maxWidth={false} className={classes.container}>
            <Toolbar/>
            <div className={classes.toolBarSpacer} />
            {isLoading ? (
              <Loading />
            ) : (
              <RegularPrice                
                companyColours={companyColours}
              />
            )}           
          </Container>
        )}
      </main>
    </div>
  );
}
