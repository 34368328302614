import React from "react"
import { makeStyles } from "@material-ui/styles";
import { CSVLink } from "react-csv";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
    // productSelectedButton: {
    //   marginLeft: theme.spacing(1),
    //   paddingBottom: theme.spacing(1),
    // },
    btnSelected: {
      textTransform: "none",
      textDecoration: "none",
      color:'#204F6C',
      '&:visited': {
        color: '#204F6C'
      },
      '&:hover': {
        color: `#296689`,
      },
      '&$selected': {
        color: '#204F6C'
      },
      '&:focus': {
        color: `#296689`,
      },
    //   marginLeft: theme.spacing(1),
    },
  }));

export default function ExportButtons(props){
    const classes = useStyles();
    const title = props.title||"Export to CSV"
    return (
        <CSVLink {...props} className={classes.btnSelected}>
            {title}
        </CSVLink>
    )
}