import {SET_COLUMNS} from '../types'

export const setColumns = (columns = {}) => ({
  type: SET_COLUMNS,
  payload: columns
});


export const fetchColumns = () => {
  const  columns={   
      single_product_report:[
        {code:'spr.company',name:'Company',title:'Company/Banner',selected:true},
        {code:'spr.store',name:'Store',title:'Store',selected:true},
        {code:'spr.pricing_trend',name:'Pricing Trend',title:'Pricing Trend Graph',selected:true,width:'350px'},
        {code:'spr.pricing_trend_100g',name:'Pricing Trend 100g',title:'Pricing Trend Graph - price/100g ',selected:false,width:'350px'},
        // {code:'spr.on_live_site',name:'On Live site',selected:false},
        {code:'spr.current_ea',name:'Price $/ea',title:'Price per each',selected:true},
        {code:'spr.current_100g',name:'Price $/100g',title:'Price per 100g',selected:false},
        {code:'spr.min_ea',name:'Min $/ea',title:'Min price per each',selected:true},
        {code:'spr.min_100g',name:'Min $/100g',title:'Min price per 100g',selected:false},
        {code:'spr.max_ea',name:'Max $/ea',title:'Max price per each',selected:true},
        {code:'spr.max_100g',name:'Max $/100g',title:'Max price per 100g',selected:false},
        {code:'spr.percent_changed',name:'% Change for Price/ea',title:'Min $/ea compared to Max $/ea for the time period specified',selected:true},
        {code:'spr.percent_changed_100g',name:'% Change for Price/100g',title:'Min $/100g compared to Max $/100g for the time period specified',selected:false},
        
        {code:'spr.mean_ea',name:'Mean $/ea',title:'Mean of Current $/each',selected:true},
        {code:'spr.mean_100g',name:'Mean $/100g',title:'Mean of Current $/100g',selected:false},
        {code:'spr.std',name:'Standard Deviation/ea',title:'Standard Deviation of Current $/ea',selected:false},
        {code:'spr.std_100g',name:'Standard Deviation/100g',title:'Standard Deviation of Current $/100g',selected:false},        
        {code:'spr.most_common_ea',name:'Most common $/ea',title:'Most common of Current $/ea',selected:true},
        {code:'spr.most_common_100g',name:'Most common $/100g',title:'Most common of Current $/100g',selected:false},
        {code:'spr.calculated_reg_ea',name:'Calculated Reg $/ea',title:'Calculated Regular price per each',selected:false},
        {code:'spr.calculated_reg_100g',name:'Calculated Reg $/100g',title:'Calculated Regular price per 100g',selected:false},
        {code:'spr.reported_reg_ea',name:'Reported Reg $/ea',title:'Reported price per each',selected:false},
        {code:'spr.reported_reg_100g',name:'Reported Reg $/100g',title:'Reported price per each',selected:false},
      ]
      ,
      promo_vs_regular:[
        {code:'spr.company',name:'Company',selected:true},
        {code:'spr.store',name:'Store',selected:true},
        {code:'spr.pricing_trend',name:'Pricing Trend',selected:true,width:'350px'},
        {code:'spr.current',name:'Price',selected:true},
        {code:'spr.regular',name:'Regular',selected:true},
        {code:'spr.promo',name:'Promo',selected:true},
        {code:'spr.promo_type',name:'Promo type',selected:true},
        {code:'spr.promo_cycle',name:'Avg Promo Cycle',selected:true},
      ]
      ,
      month_reports:[
        {code:'cat_',name:'Category',selected:false, color:'rgba(0, 0, 0, 0.04)'},
        {code:'pro_',name:'Product name',selected:true, color:'rgba(0, 0, 0, 0.04)'},
        {code:'pre_',name:'Province',selected:true, color:'rgba(0, 0, 0, 0.04)'},
        {code:'cit_',name:'City',selected:true, color:'rgba(0, 0, 0, 0.04)'},
        {code:'avg_',name:'Average',selected:true, color:'rgba(247, 9, 5, 0.04)'},
        {code:'std_',name:'Standard Deviation',selected:false, color:'rgba(0, 0, 0, 0.04)'},
        {code:'min_',name:'Min',selected:false, color:'rgba(0, 0, 0, 0.04)'},
        {code:'max_',name:'Max',selected:true,width:'350px', color:'rgba(0, 0, 0, 0.04)'},
        {code:'aoa_',name:'Avg over all Months',selected:true, color:'rgba(0, 0, 0, 0.04)'},
        {code:'soa_',name:'Standard deviation over all months',selected:true, color:'rgba(0, 0, 0, 0.04)'},
        {code:'ran_',name:'Range over all months',selected:true, color:'rgba(0, 0, 0, 0.04)'},
        {code:'per_',name:'% Change',selected:true, color:'rgba(0, 0, 0, 0.04)'}
      ]  
  };
  return (dispatch) => {

      dispatch({
        type: SET_COLUMNS,
        payload: columns
      });
    
  };
};

