import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";

import PlanRow from "./PlanRow";
import TableRowLoadMore from "../Common/TableRowLoadMore";
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "90vh",
    boxShadow: "none",
  },
  providerLogo:{
    maxHeight: "50px",
    maxWidth: "170px",
  }  
}));

export default function PlansTable({
  plans,
  providers
}) {
  const classes = useStyles();
  const [loadMore, setLoadMore] = useState(false);
  const [limit, setLimit] = useState(50);
  const [plansLoaded, setPlansLoaded] = useState(plans&&plans.slice(0,limit));
  const [categories,setCategories] = useState([])//[...new Set(plans.map(item => item.cagtegory))];
  
  useEffect(() => {    
    setLoadMore(plans&&plans.length>=limit);  
    let cats = plans?[...new Set(plans.map(item => item.category ))]:[]
    setCategories(cats);    
  }, [plans]);

  const doLoadMore = (isVisible) => {
    if(isVisible){
      let tempLimit = limit+10
      if(plans.length < tempLimit){
        tempLimit=plans.length
        setLoadMore(false)
      }
      setLimit(tempLimit)
      setPlansLoaded(plans.slice(0,tempLimit))
    } 
  };
  // const [skip, setSkip] = useState(0);
  
  return (
    
      <TableContainer component={Paper} className={classes.container}  square>
        <Table stickyHeader aria-label="devices">
          <TableHead>
            <TableRow >
              <TableCell align="center" style={{backgroundColor:'#dddddd'}}>Category</TableCell>
              {providers&&providers
                .map((row, index) => {
                  return (
                    <TableCell
                      align="center"
                      key={`column_${index}`}
                      colSpan={2}
                      style={{backgroundColor:row.color, width:'170px'}}
                    >
                      <img src={row.logo_url} className={classes.providerLogo}/>
                    </TableCell>
                  );
              })}
            </TableRow>
            
          </TableHead>
          <TableBody>
            {plansLoaded && categories &&
              categories.map((category, index) => {
                let plansOfCategory = plans.filter(e => e.category === category)
                return (
                  <PlanRow key={index} category={category} plans={plansOfCategory} providers={providers}/>
                );
              })}
            {loadMore && <TableRowLoadMore doLoadMore={doLoadMore} colSpan={providers.length + 1} />}  
          </TableBody>
        </Table>
      </TableContainer>
  );
}
