import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
      marginLeft:"10px", 
      padding:"0px 10px",
      borderWidth:"2px",
      '&:hover':{
        borderWidth:"2px",
      }    
  },
  label:{
      textTransform: "capitalize",
      fontSize: '1.25rem',       
      fontWeight: "500",
      '& >.MuiButton-iconSizeMedium > *:first-child ':{
        fontSize: '2rem',
      }
    }
  }));
// import StoreIcon from "@material-ui/icons/Store";

export function SelectSaveCarts() {
  const classes = useStyles();
  const handleClick  = (event) => {
    // setAnchorEl(event.currentTarget);
  };
  return (  
    <>
      <Button
        variant="outlined"
        classes={{root:classes.root, label:classes.label}}
        color="primary"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon/>}
      >
       Saved Carts
      </Button>      
    </>
   
  );
}
export const MemoizedSelectSaveCarts = React.memo(SelectSaveCarts);
