import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "10px",
    padding: "0px 10px",
    borderWidth: "2px",
    "&:hover": {
      borderWidth: "2px",
    },
  },
  label: {
    textTransform: "capitalize",
    fontSize: "1.25rem",
    fontWeight: "500",
    "& >.MuiButton-iconSizeMedium > *:first-child ": {
      fontSize: "2rem",
    },
  },
}));
// import StoreIcon from "@material-ui/icons/Store";

export default function PriceMapButton() {
  const history = useHistory();
  const classes = useStyles();
  const handleClick = () => {
    history.push('/single-report-map')
  };
  return (
    <>
      <Button
        variant="outlined"
        classes={{ root: classes.root, label: classes.label }}
        color="default"
        onClick={handleClick}
        startIcon={<i className={"material-icons"}>map</i>}
      >
        <Hidden mdDown><div  style={{whiteSpace: 'nowrap'}}>$ Map</div></Hidden>
        {/* <Hidden > Map</Hidden> */}
      </Button>
    </>
  );
}
