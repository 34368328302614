import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {DialogTitle,DialogContent,DialogActions } from '../Dialog/DialogCustom';
import TextField from "@material-ui/core/TextField";


import axios from "axios";
import envConfig from "../../bin/env.config";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  warning:{
    color: 'rgb(255, 213, 153)',
    display: "flex",
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.43",
    letterSpacing: "0.01071em"
    // backgroundColor: 'rgb(25, 15, 0)'
  },
  info:{
    color: 'rgb(166, 213, 250)',
    display: "flex",
    padding: "4px 8px 8px 8px",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.43",
    letterSpacing: "0.01071em"
    
  },
  success:{
    color: 'rgb(183, 223, 185)',
    display: "flex",
    padding: "6px 16px",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.43",
    letterSpacing: "0.01071em"
    
  }
}));

export function AlertMessage( {severity,message}){
  const classes = useStyles();
  return(
    <div className={classes[severity]}>{message}</div>
  ) 
}
export default function MessageDialog(promps) {
  const {page,product,city,store,price, collected_date, icon=true,text="",channel="price-update-needed", open, handleClose} = promps
    
  const classes = useStyles();
  const [requested, setRequested] = useState(false);
  const [notification, setNotification] = useState('Please give us some information, thank you!');
  const [notificationStatus, setNotificationStatus] = useState('info');
  const [noteMessage, setNoteMessage] = useState('');
  const state = useSelector(state => state);
    

  useEffect(() => {
    const fetchDataSource = async () => {
      const postData = {
        channel:channel,
        page:page,
        product:product,
        city: city,
        store: store,
        price: price,
        collected_date: collected_date,
        note: noteMessage
      };
      const apiUrl = `${envConfig.API_ROOT}${envConfig.API_VERSION}/analytics/request2update`;
      try {
        const result = await axios.post(apiUrl, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });
        setNotificationStatus('success')
        setNotification("Your message has been sent to our team. We will check it out. Thank you!");
      } catch (e) {
        setNotificationStatus('error')
        setNotification(`Sorry, something went wrong.`); 
      }
    };
    requested && fetchDataSource();
  }, [requested]);

  const handleSend = () => { 
    setRequested(true);
  };
  const handleCancel = () =>{
    handleClose();
  }  
  const handleChange = (event) => {
    setNoteMessage(event.target.value);
  };
  return (    
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Notification message
        </DialogTitle>
        <DialogContent dividers>
          
        <AlertMessage severity={notificationStatus} message={notification}/>
          
        <TextField
              className={classes.textBox}
              required
              label="Enter your request message here"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              value={noteMessage}  
              id="message"                     
              name="message"
              autoFocus
              color="primary"
              disabled={notificationStatus==="success"}
            />   
        </DialogContent>
        <DialogActions>
          <Button id="btn_banner_cancel" onClick={handleCancel} color="default">
            Cancel
          </Button>
          <Button id="btn_banner_cancel" autoFocus onClick={handleSend} color="primary" disabled={notificationStatus==="success"}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
   
  );
}
