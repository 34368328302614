import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";


import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";


import { MemoizedMenu } from "../components/Menu";
import MainBoard from "../components/PromoVSRegular/MainBoard";
// import RegularPriceTest from "../components/SRPPricingTool/RegularPriceTest";
// import ModeRegularPrice from "../components/SRPPricingTool/ModeRegularPrice";
import Toolbar from "../components/SRPPricingTool/Toolbar.js";
import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  setSingleProductPricing
  } from "../store/actions/reportAction";
import { getStandardDeviation,getMean,NumberComponent, price100g } from "../utility/utility";
import { format,differenceInWeeks,differenceInDays  } from "date-fns";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  toolBarSpacer: {
    display: "block",
    minHeight: '168px'    
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },  

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function SingleProductPricingPromotion({goBackButtonEnable}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  
  // const [includeSalePrices, setIncludeSalePrices] = useState(0);
  const includeSalePrices = 0;
  const state = useSelector(state => state);
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  // console.log(state)
  const productSelected = state.product.productSelected;
  const storesSelected = state.store.storesSelected
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner
  const stores = state.store.stores
 
  
  // const [showExtendedDetail, setShowExtendedDetail] = useState(false);
  const showExtendedDetail = false
  const [dataSource, setDataSource] = useState([]);
  
  useEffect(() => {
    const fetchDataSource = async () => {      
      setIsLoading(true);
      const postData = {
        productMatchingGroupID: productSelected
          ? productSelected.id
          : 0,
        includeSalePrices: +includeSalePrices, //setAllway true
        storeIDs: storesSelected.map((store) => store.id),
        bannerIDs: bannersSelected,  
        byBanner: byBanner,  
        fromDate: fromDate,
        toDate: toDate,  
      };
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/promo-vs-regular`;
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });
        const externalLink = (link, companyId, storeCode) => {
          // console.log(`${link} - ${companyId} - ${storeCode}`);
      
          let extLink = "";
          if (link) {
            if (companyId === 13 && storeCode.length <= 4) {
              let paths = link.split('/');
              let storeCodeInPath=paths[6];
              extLink = link.replace(storeCodeInPath, storeCode);
              // extLink = link.replace("953", storeCode);
            } else {
              extLink = link;
            }
          }
          return extLink;
        };
        // useEffect(() => {
        //   console.log('reder - +++')
        // },[])
        const  singleProductPricing = {
            currentMin:{
                value:0,
                chain: '' //company Name
            },
            currentMax:{
                value:0,
                chain: '' //company Name
            },
            historicalMin:{
                value:0,
                chain: '' //company Name
            },
            historicalMax:{
                value:0,
                chain: '' //company Name
            },
            stdList: [], 
            currentPriceList: [], 
        }
      
        let prepareData = result.data.data.map((row, index) => {
                                // console.log(row)
                                let retRow = {
                                  ...row,
                                  spps:[],
                                  currentPrice:0,
                                  isOnSale:0,
                                  currentSize:'',
                                  currentStandardSize:'',
                                  currentPrice100g:0,
                                  currentUpdatedAt:'',
                                  indexPrevReg:-1,
                                  extLink:'',
                                  liveProductInput:{},
                                  min_100g:'',
                                  max_100g:'',
                                  most_common_100g:'',
                                  calculated_reg_100g:'',
                                  mean:0,
                                  mean_100g:0,
                                  std:0,
                                  std_100g:0,
                                  // storeAddress:'',
                                  productName:productSelected.name,
                                  indexPromo:-1,
                                  promo:0,
                                  promoDistantInWeek:'',
                                  countPromo:0,
                                  promoType:'',
                                  promoCycle:''
                                }
                                let flags = [],
                                  sppsPrices = [],
                                  sppsPrices100 = [],
                                  l = row.spp_record.length,
                                  i;
                                // console.log(row);
                                let countSaleRange = 0
                                let countDaysNotSale = 0
                                let saleFlag = false
                                let saleFlagDate = undefined
                                let notSaleFlagDate = undefined
                                for (i = 0; i < l; i++) {
                                  const items = row.spp_record[i].split(";");
                                  if (flags[items[0]]) continue;
                                  flags[items[0]] = true;
                                  let created_at= items[0];
                                  let is_onSale = +items[1]; 
                                  let standardSize = +items[6];
                                  let dbCurrentPrice100g = +items[9];//check if exist in the database
                                  let currentPrice100g = dbCurrentPrice100g?dbCurrentPrice100g:price100g(+items[2], standardSize);
                                  let regularPrice100g = price100g(+items[3], standardSize);
                                  let currentPriceHistorical = Math.round(+items[2] * 100) / 100
                                  let regularPriceHistorical = Math.round(+items[3] * 100) / 100
                                  let currentPriceHistorical100 = Math.round(+currentPrice100g * 100) / 100
                                  let regularPriceHistorical100 = Math.round(+regularPrice100g * 100) / 100
                                  retRow.spps.push({
                                    created_at: created_at,
                                    is_onsale: is_onSale,
                                    current_price: currentPriceHistorical,
                                    regular_price: regularPriceHistorical,
                                    size: items[5],
                                    standard_size: +items[6],
                                    current_price_100g: currentPriceHistorical100, 
                                    regular_price_100g: regularPriceHistorical100, 
                                    unit: items[8],
                                    product_id: +items[7],
                                    sale_description_cal:items[10],
                                    realized_percent_discount:items[11]
                                  });
                                  
                                  //init singleProductPricing summary report for history
                                  sppsPrices.push(currentPriceHistorical);
                                  sppsPrices100.push(currentPriceHistorical100);
                                  if(singleProductPricing.historicalMin.value==0||singleProductPricing.historicalMin.value>currentPriceHistorical){
                                    singleProductPricing.historicalMin.value = currentPriceHistorical
                                    singleProductPricing.historicalMin.chain = retRow.company                                                               
                                  } 
                                  if(singleProductPricing.historicalMax.value==0||singleProductPricing.historicalMax.value<currentPriceHistorical){
                                    singleProductPricing.historicalMax.value = currentPriceHistorical
                                    singleProductPricing.historicalMax.chain = retRow.company                                                               
                                  } 
                                  //
                                  if(is_onSale>0){
                                    saleFlag = true
                                    saleFlagDate = created_at
                                  }
                                  if(!saleFlag&&is_onSale===0&&saleFlagDate){
                                    //case still not sale
                                    if(notSaleFlagDate)
                                      countDaysNotSale += differenceInDays(Date.parse(notSaleFlagDate+"T12:00:00.000Z"),Date.parse(created_at+"T12:00:00.000Z"))
                                    notSaleFlagDate = created_at
                                  }
                                  if(saleFlag&&is_onSale===0){
                                    countDaysNotSale += differenceInDays(Date.parse(saleFlagDate+"T12:00:00.000Z"),Date.parse(created_at+"T12:00:00.000Z"))
                                    countSaleRange++
                                    saleFlag = false
                                  }                           
                                }
                                let tempDate = format(
                                  Date.parse(retRow.spps[0].created_at+"T12:00:00.000Z"),
                                  "PP" 
                                ).split(",");
                                const currentUpdatedAt =
                                  tempDate[0] + " '" + tempDate[1].slice(-2);                          
                                // console.log(retRow.spps[0].created_at)    
                                // console.log(tempDate)    
                                // console.log(currentUpdatedAt)    
                                retRow.currentPrice = retRow.spps[0].current_price;
                                retRow.isOnSale = retRow.spps[0].is_onsale;
                                retRow.currentSize = retRow.spps[0].size;
                                retRow.currentStandardSize = retRow.spps[0].standard_size;
                                retRow.currentPrice100g = retRow.spps[0].current_price_100g;
                                retRow.currentUpdatedAt = currentUpdatedAt;                        
      
                                const isPrevReg = (element) => element.regular_price > 0;
                                retRow.indexPrevReg = retRow.spps ? retRow.spps.findIndex(isPrevReg) : -1;
                                
                                const isPromo = (element) => element.is_onsale > 0;
                                retRow.indexPromo = retRow.spps ? retRow.spps.findIndex(isPromo) : -1;
      
      
                                retRow.extLink = externalLink(
                                  row.links[0],
                                  +row.company_id,
                                  row.store_code
                                );
                                retRow.liveProductInput = {
                                  companyName:row.company,
                                  storeId:row.store_id,
                                  productId:retRow.spps[0].product_id,
                                  productUrl:retRow.extLink,
                                }
                                retRow.min_100g = price100g(retRow.current_min,retRow.currentStandardSize)
                                retRow.max_100g = price100g(retRow.current_max,retRow.currentStandardSize)
                                retRow.most_common_100g = price100g(retRow.current_mode,retRow.currentStandardSize)
                                retRow.calculated_reg_100g = price100g(retRow.current_max,retRow.currentStandardSize)
                                
                                retRow.reported_reg_ea = retRow.indexPrevReg >= 0? retRow.spps[retRow.indexPrevReg].regular_price:''
                                retRow.promo= retRow.indexPromo >= 0? retRow.spps[retRow.indexPromo].current_price:''
                                // let diffInWeeks =   retRow.indexPromo >= 0? differenceInWeeks(Date.parse(retRow.spps[0].created_at),Date.parse(retRow.spps[retRow.indexPromo].created_at)):-1 
                                let diffInWeeks =   retRow.indexPromo >= 0? differenceInWeeks(new Date(),Date.parse(retRow.spps[retRow.indexPromo].created_at+"T12:00:00.000Z")):-1 
                                retRow.promoDistantInWeek = diffInWeeks===-1?'': diffInWeeks>0? '('+diffInWeeks+' wks ago)':'(last collected)'
                                retRow.promoType= retRow.indexPromo >= 0? retRow.spps[retRow.indexPromo].sale_description_cal:''
                                let percentDiscount  = retRow.indexPromo >= 0? +retRow.spps[retRow.indexPromo].realized_percent_discount:+0
                                percentDiscount = percentDiscount?percentDiscount.toFixed(0)+'%':''
                                retRow.promoType= retRow.promoType||percentDiscount
                                
                                retRow.reported_reg_100g = retRow.indexPrevReg >= 0? price100g(
                                  retRow.spps[retRow.indexPrevReg].regular_price,
                                  retRow.spps[retRow.indexPrevReg].standard_size
                                ):''
                                //init singleProductPricing summary report
                                retRow.mean = sppsPrices.length>1?(Math.round(getMean(sppsPrices) * 100) / 100).toFixed(2):'---';
                                retRow.mean_100g = sppsPrices100.length>1?(Math.round(getMean(sppsPrices100) * 100) / 100).toFixed(2):'---';
                                
                                retRow.std = sppsPrices.length>1?(Math.round(getStandardDeviation(sppsPrices) * 100) / 100).toFixed(2):'---';
                                retRow.std_100g = sppsPrices100.length>1?(Math.round(getStandardDeviation(sppsPrices100) * 100) / 100).toFixed(2):'---';
                                  
                                if(singleProductPricing.currentMin.value==0||singleProductPricing.currentMin.value>retRow.currentPrice){
                                  singleProductPricing.currentMin.value = retRow.currentPrice
                                  singleProductPricing.currentMin.chain = retRow.company                                                               
                                } 
                                if(singleProductPricing.currentMax.value==0||singleProductPricing.currentMax.value<retRow.currentPrice){
                                  singleProductPricing.currentMax.value = retRow.currentPrice
                                  singleProductPricing.currentMax.chain = retRow.company                                                               
                                }
                                singleProductPricing.stdList.push(retRow.std)
                                singleProductPricing.currentPriceList.push(retRow.currentPrice)
                                //storeAddress: 
                                // retRow.promoCycle
                                if(countSaleRange&&countDaysNotSale){
                                  let wks = (countDaysNotSale/7/countSaleRange).toFixed(1)
                                  retRow.promoCycle= wks+' wks'
                                }else if(saleFlag){
                                  retRow.promoCycle='Always' 
                                }else{
                                  retRow.promoCycle='---'
                                }  
                                //fix the issue not show promo when regular price > current price
                                if(retRow.isOnSale===0 && retRow.current_max>retRow.currentPrice){
                                  retRow.isOnSale = 1 
                                  retRow.promo = retRow.currentPrice
                                  retRow.promoDistantInWeek = '(Last time)'
                                }
                                // console.log(retRow)
                                return retRow; 
                              });  
      setDataSource(prepareData);
      dispatch(setSingleProductPricing(singleProductPricing));
      setIsLoading(false);
    };
    productSelected.id && fetchDataSource();
  }, [productSelected,storesSelected,bannersSelected,byBanner, fromDate, toDate]);
  // if (!productSelected) {
  //   return <Redirect to="/login" />;
  // }
  return (
    <div className={classes.root}>
      <MemoizedMenu goBackButtonEnable={goBackButtonEnable} title={"Promotion vs Regular Pricing"} 
          selectedProductEnable 
          selectedCityEnable
          selectedStoreEnable
          formDateToDateSelectedButtonEnable
          />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {productSelected && (
          <Container maxWidth={false} className={classes.container}>
            <Toolbar columnButton={false}/>
            <div className={classes.toolBarSpacer} />
            <Grid container spacing={3}>
              {/* Chart */}

              <Grid item xs={12} md={12} lg={12}>
                {/* <ModeRegularPrice
                      storesSelected={props.storesSelected}
                      productSelected={productSelected}
                    /> */}
                {isLoading ? (
                  <Loading />
                ) : (
                  <MainBoard
                    dataSource={dataSource}
                    productSelected={productSelected}                    
                  />
                )}
              </Grid>
            </Grid>
          </Container>
        )}
      </main>
    </div>
  );
}
