import {SET_CATEGORIES, CATEGORIES_ERROR} from '../types'
const categoriesDefaultState = {
  categories: [],
  loading:true
};

export default (state=categoriesDefaultState, action) => {
  switch (action.type) {
    case SET_CATEGORIES :
      return {
        ...state,
        categories: action.payload,
        loading:false
      };
    // case 'CLEAR_CATEGORIES' :
    case CATEGORIES_ERROR:
      return{
          loading: false, 
          error: action.payload 
      }
    default:
      return state;
  }
};
