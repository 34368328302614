import React, { useState,useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {  
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel ,
  Typography
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { useDispatch, useSelector } from "react-redux";
import {
  removeFromCart
  } from "../../store/actions/cartAction";
const useStyles = makeStyles((theme) => ({
  container:{
    borderBottom:"3px solid green"
  },
  tableRow: {    
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
   
  },
  rowExpand:{
    '& > *': {
      borderTop:"3px solid green"
    },   
  },
  rowCollapse:{
    '& > *': {
      borderBottom:"3px solid green"
    },
    backgroundColor: "rgba(0, 0, 0, 0.04)",      
  },
  showText:{
    textTransform:'capitalize '
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  lineGraphContainer:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px',
    fontSize: "1rem", 
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
}));
export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}>
                {props.nowrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}
export function ProductImages({product}){
  const classes = useStyles();
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  return (
    <img className={classes.productImg} src={product.imageUrl} onError={addDefaultSrc}/>      
    )
}
export default function CartExpand() {
  const classes = useStyles();  
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart);
  const [dataSource, setDataSource] = useState(cart?cart.cart:undefined);
  useEffect(() => {
    setDataSource(cart?cart.cart:undefined)    
  }, [cart]);
  const handleRemoveFromCart = (product)=>{
    dispatch(removeFromCart(product))
  }
  return (   
      <TableContainer className={classes.container} square>
      <Table>
        <TableHead>
            <TableRow  className={classes.trHeader}>
              <TableCell align="left"  className={classes.thHeader} width="150px">
              </TableCell>
              <TableCell align="left"  className={classes.thHeader} width="100px">
                <SortLabel hideSortIcon>Brand</SortLabel>
              </TableCell>
              <TableCell align="left"  className={classes.thHeader}>
                <SortLabel hideSortIcon>Name</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader} width="120px">
                <SortLabel hideSortIcon>Size</SortLabel>
              </TableCell>                           
              <TableCell align="center"  className={classes.thHeader} width="120px"/>                 
            </TableRow>
          </TableHead>
        <TableBody>
          {
            dataSource &&
              dataSource.map((row, index) => {
                return (
                  <TableRow hover key={index} className={classes.tableRow}>
                    <TableCell align="left">
                      <ProductImages product={row}/>
                    </TableCell>
                    <TableCell align="left" className={classes.showText}>{row.brand}</TableCell>
                    <TableCell align="left" className={classes.showText}>{row.name}</TableCell>
                    <TableCell align="center">{row.size}</TableCell>
                    <TableCell align="center">     
                    <IconButton aria-label="delete" onClick={()=>handleRemoveFromCart(row)}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                              
                    </TableCell>
                    
                  </TableRow>
                );
              })}
        </TableBody>                
      </Table>
    </TableContainer>
  
  );
}

