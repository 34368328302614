import {GET_STORES, SELECT_STORES,GET_BANNERS,SELECT_BANNERS,SELECT_BY_BANNER,  STORE_ERROR} from '../types'
import axios from 'axios'
import envConfig from "../../bin/env.config";
import { dynamicColors } from "../../utility/utility.js";

export const fetchStores = (citySelectedName) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const userData  = localStorage.getItem('userDataT');
        const jsBetterCart = userData?JSON.parse(userData):{}
        const lsCity = jsBetterCart[citySelectedName];
        if(lsCity){
          const {stores, selectedStores}  = lsCity;
          var banners = []
          var bannerIDs = [] 
          stores.map(s=>{
            if(!(bannerIDs.includes(s.company_id))){
              bannerIDs.push(s.company_id)
              banners.push({'company_id':s.company_id,'company_name':s.company_name,'company_logo_link':s.company_logo_link})
            }
          })
          dispatch( {
              type: GET_STORES,
              payload: stores
          });
          dispatch( {
            type: SELECT_STORES,
            payload: selectedStores
          })

          dispatch( {
            type: GET_BANNERS,
            payload: banners
          });
          dispatch( {
            type: SELECT_BANNERS,
            payload: bannerIDs
          })
          dispatch( {
              type: SELECT_BY_BANNER,
              payload: getState().store.byBanner
          })
          resolve(selectedStores); 
        }else{
          const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/analytics/stores/${citySelectedName}`;
          //get history local stores saved
          axios.get(url,{
            headers: {
              'Authorization': JSON.stringify(getState().session.token)
            } 
          }).then((result) => {
            let stores = result.data.data.map((store) => {
                return { ...store, color: dynamicColors() };
            });
            var banners = []
            var bannerIDs = [] 
            stores.map(s=>{
              if(!(bannerIDs.includes(s.company_id))){
                bannerIDs.push(s.company_id)
                banners.push({'company_id':s.company_id,'company_name':s.company_name,'company_logo_link':s.company_logo_link})
              }
            })
            dispatch( {
                type: GET_STORES,
                payload: stores
            })
            //Selected store
            dispatch( {
                type: SELECT_STORES,
                payload: stores
            })
            dispatch( {
              type: GET_BANNERS,
              payload: banners
            });
            dispatch( {
              type: SELECT_BANNERS,
              payload: bannerIDs
            })
            dispatch( {
                type: SELECT_BY_BANNER,
                payload: getState().store.byBanner
            })
            let userData = {...jsBetterCart,
              [citySelectedName]:{
                                  stores:stores,
                                  selectedStores:stores
                                }
                              }
            localStorage.setItem('userDataT', JSON.stringify(userData)); 
            resolve(stores);        
          }).catch(error => {  
            reject(error);    
            dispatch( {
                type: STORE_ERROR,
                payload: error,
            })
          })
        }    
    });
    };
};

export const selectStores = (selectedStores) => {
   return (dispatch,getState)=>{
    const citySelectedName = getState().city.citySelected.name;
    const stores = getState().store.stores;
    // console.log(citySelectedName);
    const userData  = localStorage.getItem('userDataT');
    const jsBetterCart = userData?JSON.parse(userData):{}
    // const lsCity = jsBetterCart[citySelectedName]
    // if(lsCity)  
    // const {stores}  = lsCity;

    let tempUserData = {...jsBetterCart,
                        [citySelectedName]:{
                                stores:stores,
                                selectedStores:selectedStores
                               }
                            }
    localStorage.setItem('userDataT', JSON.stringify(tempUserData));
    dispatch( {
        type: SELECT_STORES,
        payload: selectedStores
    })
  }
}
export const selectBanners = (selectedBanners) => {
  return (dispatch,getState)=>{
   dispatch( {
       type: SELECT_BANNERS,
       payload: selectedBanners
   })
 }
}
export const selectByBanner = (byBanner) => {
  return (dispatch,getState)=>{
   dispatch( {
       type: SELECT_BY_BANNER,
       payload: byBanner
   })
 }
}
