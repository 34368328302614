import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";


import Container from "@material-ui/core/Container";

import { MemoizedMenu } from "../components/Menu.js";
import RegularPrice from "../components/SingleProductPricingElectrical/RegularPrice.js";
// import RegularPriceTest from "../components/SRPPricingTool/RegularPriceTest";
// import ModeRegularPrice from "../components/SRPPricingTool/ModeRegularPrice";
import Toolbar from "../components/SingleProductPricingElectrical/Toolbar.js";
import axios from "axios";
import envConfig from "../bin/env.config.js";
import Loading from "../components/Loading/Loading.js";
import { useDispatch, useSelector } from "react-redux";
import {
  setSingleProductPricing
  } from "../store/actions/reportAction.js";
import { getStandardDeviation,getMean,NumberComponent, price100g } from "../utility/utility.js";
import { format } from "date-fns";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  toolBarSpacer: {
    display: "block",
    minHeight: '168px'    
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },  

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function SingleProductPricingElectrical({goBackButtonEnable}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  
  // const [includeSalePrices, setIncludeSalePrices] = useState(0);
  const includeSalePrices = 0;
  const state = useSelector(state => state);
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  // console.log(state)
  const productSelected = state.product.productSelected;
  const storesSelected = state.store.storesSelected
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner
 
  const [dataSource, setDataSource] = useState([]);
  const [bannerDataLineGraph, setBannerDataLineGraph] = useState({
    labels:[],
    bannerDatas:[]
  });
  const [companyColours, setCompanyColours] = useState(undefined)
  const externalLink = (link, companyId, storeCode) => {
    // console.log(`${link} - ${companyId} - ${storeCode}`);

    let extLink = "";
    if (link) {
      if (companyId === 13 && storeCode.length <= 4) {
        let paths = link.split('/');
        let storeCodeInPath=paths[6];
        extLink = link.replace(storeCodeInPath, storeCode);
        // extLink = link.replace("953", storeCode);
      } else {
        extLink = link;
      }
    }
    return extLink;
  };

  useEffect(() => {
    const fetchCompanyColours = async () => {
      setIsLoading(true);      
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/company_colours`;
      const result = await axios.get(url);
      let dictColours = {}
      result.data.data.map(({id,colour})=> 
        dictColours[id]= colour
      );
      setCompanyColours(dictColours);
      setIsLoading(false);
    };
    fetchCompanyColours();
  }, []);

  useEffect(() => {
    const fetchDataSource = async () => {      
      setIsLoading(true);
      const postData = {
        productMatchingGroupID: productSelected
          ? productSelected.id
          : 0,
        includeSalePrices: +includeSalePrices, //setAllway true
        storeIDs: storesSelected.map((store) => store.id),
        bannerIDs: bannersSelected,  
        byBanner: byBanner,  
        fromDate: fromDate,
        toDate: toDate,  
      };
      // console.log(postData)
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/regular-price-in-store`;
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });
        let  singleProductPricing = {
          currentMin:{
              value:0,
              chain: '' //company Name
          },
          currentMax:{
              value:0,
              chain: '' //company Name
          },
          historicalMin:{
              value:0,
              chain: '' //company Name
          },
          historicalMax:{
              value:0,
              chain: '' //company Name
          },
          stdList: [], 
          currentPriceList: [], 
        }
        let bannerDataLineGraph ={
          labels:[],
          bannerDatas:[]
        }
        let prepareData = result.data.data.map((row) => {
                                // console.log(row)
                                let retRow = {
                                  ...row,
                                  spps:[],
                                  currentPrice:0,
                                  currentSize:'',
                                  currentStandardSize:'',
                                  currentUpdatedAt:'',
                                  indexPrevReg:-1,
                                  extLink:'',
                                  liveProductInput:{},
                                  mean_ea:0,
                                  std:0,
                                  // storeAddress:'',
                                  percent_changed:0,// current_price with current_min
                                  productName:productSelected.name
                                }
                                let flags = [],
                                  sppsPrices = [],
                                  l = row.spp_record.length,
                                  i,
                                  bannerData={
                                    companyId:0,
                                    companyName:"",
                                    avgPriceMonthData:{}
                                  };
                                // console.log(row);
                                for (i = 0; i < l; i++) {
                                  const items = row.spp_record[i].split(";");
                                  if (flags[items[0]]) continue;
                                  flags[items[0]] = true;
                                  let currentPriceHistorical = Math.round(+items[2] * 100) / 100
                                  let regularPriceHistorical = Math.round(+items[3] * 100) / 100
                                  // console.log(Date.parse(items[0]))
                                  retRow.spps.push({
                                    created_at: items[0],
                                    is_onsale: +items[1],
                                    current_price: currentPriceHistorical,
                                    regular_price: regularPriceHistorical,
                                    size: items[5],
                                    unit: items[8],
                                    product_id: +items[7],
                                  });
                                  
                                  //init singleProductPricing summary report for history
                                  sppsPrices.push(currentPriceHistorical);
                                  if(singleProductPricing.historicalMin.value==0||singleProductPricing.historicalMin.value>currentPriceHistorical){
                                    singleProductPricing.historicalMin.value = currentPriceHistorical
                                    singleProductPricing.historicalMin.chain = retRow.company                                                               
                                  } 
                                  if(singleProductPricing.historicalMax.value==0||singleProductPricing.historicalMax.value<currentPriceHistorical){
                                    singleProductPricing.historicalMax.value = currentPriceHistorical
                                    singleProductPricing.historicalMax.chain = retRow.company                                                               
                                  }
                                  // banner Line Graph
                                  let monthYearCollected = format(Date.parse(items[0]+"T12:00:00.000Z"),"yyyy-MM-15")
                                  bannerDataLineGraph.labels.push(monthYearCollected) 
                                  bannerData.avgPriceMonthData[monthYearCollected] = bannerData.avgPriceMonthData[monthYearCollected]?Math.round(((bannerData.avgPriceMonthData[monthYearCollected]+currentPriceHistorical)/2)*100)/100:currentPriceHistorical                           
                                }
                                bannerData.companyName = row.company
                                bannerData.companyId = +row.company_id
                                bannerDataLineGraph.bannerDatas.push(bannerData)  
                                let tempDate = format(
                                  Date.parse(retRow.spps[0].created_at+"T12:00:00.000Z"),
                                  "PP"
                                ).split(",");
                                const currentUpdatedAt =
                                  tempDate[0] + " '" + tempDate[1].slice(-2);                          
                                // console.log(retRow.spps[0].created_at)    
                                // console.log(tempDate)    
                                // console.log(currentUpdatedAt)    
                                retRow.currentPrice = retRow.spps[0].current_price;
                                retRow.currentSize = retRow.spps[0].size;
                                retRow.currentUpdatedAt = currentUpdatedAt;                        
      
                                const isPrevReg = (element) => element.regular_price > 0;
                                retRow.indexPrevReg = retRow.spps ? retRow.spps.findIndex(isPrevReg) : -1;
                                retRow.extLink = externalLink(
                                  row.links[0],
                                  +row.company_id,
                                  row.store_code
                                );
                                retRow.liveProductInput = {
                                  companyName:row.company,
                                  storeId:row.store_id,
                                  productId:retRow.spps[0].product_id,
                                  productUrl:retRow.extLink,
                                }
                                retRow.reported_reg_ea = retRow.indexPrevReg >= 0? retRow.spps[retRow.indexPrevReg].regular_price:''
                                retRow.reported_current_ea = retRow.indexPrevReg >= 0? +retRow.spps[retRow.indexPrevReg].current_price:0
                                
                                retRow.percent_changed =  retRow.current_min >= 0? ((retRow.currentPrice-retRow.current_min)/retRow.current_min):'---'


                                //init singleProductPricing summary report
                                retRow.mean_ea = sppsPrices.length>1?(Math.round(getMean(sppsPrices) * 100) / 100).toFixed(2):'---';
                                
                                retRow.std = sppsPrices.length>1?(Math.round(getStandardDeviation(sppsPrices) * 100) / 100).toFixed(2):'---';
                                
                                if(singleProductPricing.currentMin.value==0||singleProductPricing.currentMin.value>retRow.currentPrice){
                                  singleProductPricing.currentMin.value = retRow.currentPrice
                                  singleProductPricing.currentMin.chain = retRow.company                                                               
                                } 
                                if(singleProductPricing.currentMax.value==0||singleProductPricing.currentMax.value<retRow.currentPrice){
                                  singleProductPricing.currentMax.value = retRow.currentPrice
                                  singleProductPricing.currentMax.chain = retRow.company                                                               
                                }
                                singleProductPricing.stdList.push(retRow.std)
                                singleProductPricing.currentPriceList.push(retRow.currentPrice)
                                //storeAddress: 
                                // let tempLabels = [...labels]
                                bannerDataLineGraph.labels = [...new Set(bannerDataLineGraph.labels)].sort()
                                // console.log(bannerDataLineGraph)
                                return retRow; 
                              });  

      setDataSource(prepareData);
      dispatch(setSingleProductPricing(singleProductPricing));
      setBannerDataLineGraph(bannerDataLineGraph)
      setIsLoading(false);
    };
    productSelected.id && fetchDataSource();
  }, [productSelected,storesSelected,bannersSelected,byBanner, fromDate, toDate]);
  
  
  // if (!productSelected) {
  //   return <Redirect to="/login" />;
  // }
  return (
    <div className={classes.root}>
      <MemoizedMenu goBackButtonEnable={goBackButtonEnable} title={"Exact Product Matches  "} 
          selectedProductEnable 
          selectedCityEnable
          selectedStoreEnable
          formDateToDateSelectedButtonEnable
          />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {productSelected && (
          <Container maxWidth={false} className={classes.container}>
            <Toolbar/>
            <div className={classes.toolBarSpacer} />
            {isLoading ? (
              <Loading />
            ) : (
              <RegularPrice
                dataSource={dataSource}
                productSelected={productSelected}
                // showExtendedDetail={showExtendedDetail}
                // includeSalePrices={includeSalePrices}
                // storesSelected = {storesSelected}
                bannerDataLineGraph={bannerDataLineGraph}
                companyColours={companyColours}
              />
            )}           
          </Container>
        )}
      </main>
    </div>
  );
}
