
import {GET_WEEKS, SELECT_WEEK, SELECT_FROM_DATE,SELECT_TO_DATE} from '../types'
const initialState = {
    weeks:[],
    weekSelected:{first:undefined, firstday:undefined},
    fromDateSelected:{date:undefined, viewDate:undefined},
    toDateSelected:{date:undefined, viewDate:undefined},
    loading:true,
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_WEEKS:
            return {
                ...state,
                weeks:action.weeks,
                loading:false
            }
        case SELECT_WEEK:
            return {
                ...state,
                weekSelected:action.weekSelected
            }    
        case SELECT_FROM_DATE:
            return {
                ...state,
                fromDateSelected:action.fromDateSelected
            }   
        case SELECT_TO_DATE:
            return {
                ...state,
                toDateSelected:action.toDateSelected
            }                
        default: return state
    }

}