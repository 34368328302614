import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import Loading from "../Loading/Loading";
import SelectMonth from "./SelectMonth";
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  productImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
  tableContainer: {
    // padding: "16px",
    maxHeight: "calc(100vh - 128px)",
    boxShadow: "none",
  },
}));

export default function DetailPrices({ columns, product, reportType }) {
  const classes = useStyles();
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(0);
  
  useEffect(() => {
    const postData = {
      reportType: reportType,
      monthly_product_id: product.pid_,
      fromDate: columns[selectedMonth].from_date,
      toDate: columns[selectedMonth].to_date,
      city: product.cit_
    };
    const fetchDataSource = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/monthly-report-prices-detail`;
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: state.session.token,
        },
      });
      //prepare data
      setDataSource(result.data.data);
      // setGridRows(result.data.data);
      setIsLoading(false);
    };
    fetchDataSource();
  }, [product, reportType, selectedMonth]);
  return (
    <>
      <h3>
      Prices collected from stores
        <SelectMonth
          months={columns}
          selected={selectedMonth}
          onSelected={setSelectedMonth}
          disabled={isLoading}
        />
      </h3>
      {isLoading ? (
        <Loading />
      ) : (
        
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          square
        >
          <Table  stickyHeader className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell>Store</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Collected date</TableCell>
                <TableCell>Price($)</TableCell>
                <TableCell>Multiply</TableCell>
                <TableCell>Price(after multiply)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource &&
                dataSource.map((row, index) => {
                  return (
                    <TableRow>
                      <TableCell >
                        {row.company_name}
                      </TableCell>
                      <TableCell>{row.store_name}</TableCell>
                      <TableCell>{row.fullname}</TableCell>
                      <TableCell style={{whiteSpace:'nowrap'}}>
                        {format(Date.parse(row.get_price_date+"T12:00:00.000Z"), "PP")}
                      </TableCell>
                      <TableCell>{row.current_price_ea}</TableCell>
                      <TableCell>{+row.multiply}</TableCell>
                      <TableCell>{row.current_price}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
