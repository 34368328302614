import React, { useState, useEffect } from "react";
import clsx from "clsx";

import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// import { KeyboardDatePicker, DatePicker } from "@material-ui/pickers";
// import { MemoizedSelectProduct } from "./SelectOneProductDropDowButton";

import { MemoizedSelectToDate } from "../Menu/SelectToDate";
import { MemoizedSelectADevice } from "./SelectADevice";
import { MemoizedDrawerLeftMenu } from "../Menu/DrawerLeftMenu";


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  logoBetterCart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    backgroundColor: "#296689",
    height: "128px",
  },
  filterBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 16px",
    ...theme.mixins.toolbar,
    backgroundColor: "#255874",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0px 0px 0px 0px ",
    // boxShadow:
    //   "1px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 1px 0px 0px 0px rgba(0,0,0,0.12)",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth + 1}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',               
    textOverflow: 'ellipsis'   
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "#204F6C",
    // backgroundColor:"rgba(0,0,0,85)",
    color: "#fff",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  drawerFooter: {
    // borderTop: "2px solid green",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "18px",
    //paddingBottom: "px",
  },
  drawerFooterContainer: {
    // height: "300px",
    width: drawerWidth,
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,

  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  listSubheader: {
    color: "#fff",
  },
  listItemIcon: {
    minWidth: "36px",
    // fontSize:'0.875rem'
    color: "#fff",
  },
  listItemText: {
    // fontSize:'0.875rem'
  },
  listItemSelect: {
    // fontSize:'0.875rem'
  },
  divider:{
    // height:'2px',
    backgroundColor: 'rgba(255, 255, 255, 0.70)'
  }
}));

export function Menu(props) {
  const history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const { title, goBackButtonEnable } = props;
  
  
  return (
    <>
      <AppBar
        position="absolute"
        color="default"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {goBackButtonEnable && (
            <IconButton
              color="inherit"
              onClick={() => history.goBack()}
              aria-label="Close"
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            nowrap
            className={classes.title}
          >
            
            {title}
            
            {/* BetterCart Analytics - Powering Your Competitive Advantage! */}
            
            {props.selectedADeviceEnable && <MemoizedSelectADevice {...props} />}
            {props.toDateSelectedButtonEnable && (
              <MemoizedSelectToDate {...props} />
            )}
            
          </Typography>
          
        </Toolbar>
      </AppBar>      
      <MemoizedDrawerLeftMenu {...props}/>           
    </>
  );
}
export const MemoizedMenu = React.memo(Menu);
