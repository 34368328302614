import React,{useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import envConfig from "../../bin/env.config";
import Skeleton from "react-loading-skeleton";
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useSelector } from "react-redux";
import {
  TableCell,
  TableRow,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

import {
  DialogTitle,
  DialogContent,
  DialogActions
} from "../Dialog/DialogCustom";
import Button from '@material-ui/core/Button';
import  ProductAttributesInfo  from "../Product/ProductAttributesInfo";

const useStyles = makeStyles((theme) => ({
  tableRow:{
    // cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
    textTransform: 'capitalize',
    cursor: "pointer",    
  },
  
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingRow:{
    backgroundColor: '#ddd',
    animationDuration: '2s',
    highlightColor: '#a9b7c1',
    height:'70px',
    width:'100%',
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingPicture:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: '#ddd',
    height:'70px',
    width:'70px',
    marginLeft:'auto',
    marginRight:'auto'
  }
}));
export function LoadingSpan(){ 
  const classes = useStyles();
  return (
      <CircularProgress size={16} />
    )
}
function ConfirmationDialog(props) {
  const { onClose, open, productId, onRemoveCommand,...other } = props;
  const handleNo = () => {
    onClose();
  };

  const handleYes = () => {
    onRemoveCommand&&onRemoveCommand(productId)
    onClose();
  };
  const handleRemoveMyProduct = (productId)=>{
    // console.log(product.id)
    //Open confirmation Dialog    
       
  }
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      // TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      {/* <DialogTitle>Confirm</DialogTitle> */}
      <DialogTitle>Are you sure you want to delete this?</DialogTitle>
      {/* <DialogContent dividers>
        
      </DialogContent> */}
      <DialogActions>
        <Button autoFocus onClick={handleNo}>
          No
        </Button>
        <Button onClick={handleYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}
export function ProductImages({product}){
  const classes = useStyles();
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  return (
    <img className={classes.productImg} src={product.image_url} onError={addDefaultSrc}/>      
    )
}
export default function ProductRow({product, onClick, onRemoveCommand, alwaysShow=false}) {  
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [priceInfo, setPriceInfo] = useState(undefined);
  const state = useSelector(state => state);
  const user =  state.session.user;
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  const storesSelected = state.store.storesSelected;  
  const [open, setOpen] = React.useState(false);
  // const [show, setShow] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/my-product/price-info`;
      const postData = {
        fromDate: fromDate,
        toDate: toDate,
        storeIDs: storesSelected ? storesSelected.map((store) => store.id) : [],
        groupId:product.product.id
      };
      
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });    
      setPriceInfo(result.data.data);
      setIsLoading(false);
    };
    fetchData();
  }, [product, fromDate,toDate]); 
  const handleRowClick =(product)=>{
    // console.log(product)
    product.imageUrl = product.image_url
    onClick&&onClick(product)   
  }

  const handleClose = () => {
    setOpen(false);
  };

  
  return (
    <>
    {isLoading&&(
    <TableRow className={classes.tableRow} >
      <TableCell colSpan={9}>
        <div className={classes.waitingPicture}>
          <CircularProgress size={16} />
        </div>
      </TableCell>
    </TableRow>  
    )}
    {!isLoading&&(
      <>
      {(alwaysShow||(priceInfo&&priceInfo.min>0&&priceInfo.max))&&(
        <TableRow hover={onClick?true:false} className={classes.tableRow} >
          <TableCell align="left" onClick={()=>handleRowClick(product.product)} title={product.product.id}>
            <ProductImages product={product.product}/>
          </TableCell>
          {/* <TableCell align="left" className={classes.showText} onClick={()=>handleRowClick(row.product.id)}>{row.product.id}</TableCell> */}
          <TableCell align="left" className={classes.showText} onClick={()=>handleRowClick(product.product)}>{product.product.fullname}
          <ProductAttributesInfo productId={product.product.id} isPmg={true}/>
          </TableCell>
          <TableCell align="left" className={classes.showText} onClick={()=>handleRowClick(product.product)}>{product.product.size}</TableCell>
          <TableCell align="left" className={classes.showText} onClick={()=>handleRowClick(product.product)}>
            {!isLoading&&priceInfo?priceInfo.min.toFixed(2):<LoadingSpan/>}      
          </TableCell>
          <TableCell align="left" className={classes.showText} onClick={()=>handleRowClick(product.product)}>
            {!isLoading&&priceInfo?priceInfo.max.toFixed(2):<LoadingSpan/>}      
          </TableCell>
          <TableCell align="left" className={classes.showText} onClick={()=>handleRowClick(product.product)}>
            {!isLoading&&priceInfo?priceInfo.min_100g.toFixed(2):<LoadingSpan/>}
          </TableCell>
          <TableCell align="left" className={classes.showText} onClick={()=>handleRowClick(product.product)}>
            {!isLoading&&priceInfo?priceInfo.max_100g.toFixed(2):<LoadingSpan/>}
          </TableCell>
          <TableCell align="left" className={classes.showText} onClick={()=>handleRowClick(product.product)}>
            {!isLoading&&priceInfo?priceInfo.std.toFixed(2):<LoadingSpan/>}
          </TableCell>
          <TableCell align="center">    
            {user&&user.company_id==1&&onRemoveCommand&&
              <IconButton aria-label="delete" onClick={()=>setOpen(true)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            }
            <ConfirmationDialog
              id="confirm-delete-dialog"
              keepMounted
              open={open}
              onClose={handleClose}
              productId={product.product.id}
              onRemoveCommand={onRemoveCommand}
            />
          </TableCell>                            
        </TableRow>
        )}
      </>
    )}
    </>
    
    
  );
}
