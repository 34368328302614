import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectOneProductDialog from "./SelectOneDialog";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import {
  selectProduct
  } from "../../store/actions/productAction";
const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft:"10px", 
    padding:"0px 10px",
    borderWidth:"2px",
    '&:hover':{
      borderWidth:"2px",
    }    
},
label:{
    textTransform: "capitalize",
    fontSize: '1.25rem',       
    fontWeight: "500",
    '& >.MuiButton-iconSizeMedium > *:first-child ':{
      fontSize: '2rem',
    }
  }
}));
// import StoreIcon from "@material-ui/icons/Store";

export function SelectOneProductDropDowButton(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch()
  const state = useSelector(state => state);
  const products = state.product.products
  const storesSelected = state.store.storesSelected;

  const handleClickOpen = () => {
    // alert('test');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const setProductSelected = (product)=>{
    dispatch(selectProduct(product));
  }
  return (
    <>
    {!state.product.loading&&
      <>
        <Button
          variant="outlined"
          classes={{root:classes.root, label:classes.label}}
          color="primary"
          onClick={handleClickOpen}
        endIcon={<ExpandMoreIcon/>}

        >Select a product</Button>
        <SelectOneProductDialog
          items={products}
          setItemSelected={setProductSelected}      
          open={open}
          handleClose={handleClose}
          storesSelected={storesSelected}       
        />
      </>
    }
    </>
  );
}
export const MemoizedSelectProduct = React.memo(SelectOneProductDropDowButton);
