import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import { useDispatch, useSelector } from "react-redux";
import * as FullStory from '@fullstory/browser';
import ChangePasswordDialog from "../ChangePassword/ChangePasswordDialog";
import {
  logout
} from "../../store/actions/sessionAction";
const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "10px",
    padding: "0px 10px",
    borderWidth: "2px",
    "&:hover": {
      borderWidth: "2px",
    },
  },
  label: {
    textTransform: "capitalize",
    fontSize: "1.25rem",
    fontWeight: "500",
    "& >.MuiButton-iconSizeMedium > *:first-child ": {
      fontSize: "2rem",
    },
  },
  listItemIcon: {
    minWidth: "36px",
    // fontSize:'0.875rem'
    color: "#fff",
  },
}));
// import StoreIcon from "@material-ui/icons/Store";

export default function UserMenu() {
  const dispatch = useDispatch()
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.session.user);
  const history = useHistory();
  const referer = "/";
  const openUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const logOut = () => {
    dispatch(logout())
    setAnchorEl(null);
    history.push(referer);
  };

  const closeUserMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {  ;   
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    FullStory.setUserVars({
      "displayName" : user.firstName,
      "email" : user.email,
      "user_type_id" : user.user_type_id
     });
  },[]);
  return (
    <>      
      <ChangePasswordDialog open={open} handleClose={handleClose}/>
      <ListItem button onClick={openUserMenu}>
        <ListItemIcon className={classes.listItemIcon}>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText style={{ overflow: "hidden", textOverflow: "ellipsis" }} primary={user && `${user.email}`} />
      </ListItem>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeUserMenu}
      >
        <MenuItem>
          {/* <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon> */}
          <Typography variant="inherit">
            {user && `${user.email}`}
          </Typography>
        </MenuItem>
        <Divider />
        {/* <MenuItem onClick={()=>alert('Open confirmation to sending an email')}>Reset password</MenuItem>
        <Divider /> */}
        <MenuItem onClick={handleOpen}>Change your password</MenuItem>
        <Divider />
        
        <MenuItem onClick={logOut}>Sign Out</MenuItem>
      </Menu>
      {/* <ChangePasswordDialog
                    open={open}
                    handleClose={handleClose}
                  /> */}
    </>
  );
}
