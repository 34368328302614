import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";


import { MemoizedMenu } from "../components/Sasktel/Menu";
import PlansTable from "../components/Sasktel/PlansTable";
// import Toolbar from "../components/SRPPricingTool/Toolbar.js";
import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },  

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function SasktelPlans({goBackButtonEnable}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const state = useSelector(state => state);
  const toDate = state.week.toDateSelected.date;
  const [dataSource, setDataSource] = useState([]);
  
  useEffect(() => {
    const fetchDataSource = async () => {      
      setIsLoading(true);
      const postData = {
        toDate: toDate 
      };
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/sasktel/providers-plans`;
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });

      setDataSource(result.data.data);
      setIsLoading(false);
    };
    fetchDataSource();
  }, [toDate]);
  
  return (
    <div className={classes.root}>
      <MemoizedMenu goBackButtonEnable={goBackButtonEnable} title={"Rate Plan Pricing for "} 
          selectedProvinceEnable
          toDateSelectedButtonEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
          <Container maxWidth={false} className={classes.container}>
            {/* <Toolbar/> */}

            <Divider />
            <Grid container spacing={3}>
              {/* Chart */}

              <Grid item xs={12} md={12} lg={12}>
                {/* <ModeRegularPrice
                      storesSelected={props.storesSelected}
                      productSelected={productSelected}
                    /> */}
                {isLoading ? (
                  <Loading />
                ) : (
                  <PlansTable plans={dataSource.plans} providers={dataSource.providers}/>                  
                )}
              </Grid>
            </Grid>
          </Container>
      </main>
    </div>
  );
}
