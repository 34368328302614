import React, { useState } from "react";
import { useHistory, Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import MLink from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import envConfig from "../bin/env.config";

import { TextField } from "@material-ui/core";
import { Copyright,RightPanel } from "../components/Common/Common";

import { useDispatch, useSelector } from "react-redux";
import {
    login, loadData
  } from "../store/actions/sessionAction";

const useStyles = makeStyles((theme) => ({  
  container: {
    padding: "0",
    margin: "0",    
    display: "flex",
    width:"100%",
    // height:'100vh',
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    }
  },
 
  containerLeft:{    
    display: "flex",
    backgroundColor:'#204F6C',
    width:"50%",
    height:'100vh',
    alignItems: "center",
    alignContent:"center",
    justifyContent: "center",
    justifyItems:"center",
    color:"white",
    [theme.breakpoints.down("sm")]: {
      width:"100%",
    }
  },
  contentLeft:{
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",   
  },
  logoLeft:{
    width:"100%",
    // paddingBottom:theme.spacing(3),
  },
  containerRight:{
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent:"center",
    justifyContent: "center",
    justifyItems:"center",
    width:"50%", 
    height:'100vh',
    backgroundColor:theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      width:"100%",
    }
  },   
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),    
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress:{
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '65%',
    marginTop: -12,
    marginLeft: -12,    
  },
  mLink:{
    color:"white",
    whiteSpace: "nowrap"    
  },
  textBox:{
    marginTop: '16px',
    marginBottom: '8px',
    backgroundColor:"white",
    // padding: '18px 14px',
    borderRadius: '4px',
    '& div':{
      backgroundColor:"white",
      borderRadius: '4px',
    },
    '& input':{
      padding: '27px 12px 10px',
      borderBottom:'0'
      // marginTop: '10px',
      // marginBottom: '10px',
    }
  }, 
  mlinkContainer:{
    display: "flex",
    justifyContent:"space-between",
    [theme.breakpoints.down("sm")]: {
      flexWrap:"wrap",
    }
  },
  errorMessage:{
    width:"100%",
    display: "box",
    color:"red",
    fontSize: '1rem'
  }
}));

export default function SignIn(props) {

  const dispatch = useDispatch()
  const classes = useStyles();

   
  
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  // const referer = "/loading";
  
  const history = useHistory();
  const [errors,setErrors] = useState({});

  const validRequireFields = () =>{
    let requiredErrors = {}
    if (!userName) {
      requiredErrors[ 'userName' ] = `Please enter a username`
    }
    if (!password) {
      requiredErrors[ 'password' ] = `Please enter a password`
    }   
    setErrors(requiredErrors);
    // console.log(Object.keys(requiredErrors))
    return Object.keys(requiredErrors).length===0;
  }

  const handleOnChange=(e) => {
    e.preventDefault();
    let requiredErrors = {}
    setErrors(requiredErrors);
    e.target.name==='username'&&setUserName(e.target.value);
    e.target.name==='password'&&setPassword(e.target.value);
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validRequireFields()) {
      //set waiting
      setIsWaiting(true);
      dispatch(login(userName, password))
      .then((data) => {    
          dispatch(loadData(data.user)).then(()=>{
            setIsWaiting(false);
            envConfig.DEV_CONSOLE_LOG_PRINT&&console.log(data.user.menu);
            history.push(data.user.menu[0].path);        
          })
          .catch(e => {
            envConfig.DEV_CONSOLE_LOG_PRINT&&console.log(e);
            setIsWaiting(false);
          })    
      })
      .catch(e => {
        envConfig.DEV_CONSOLE_LOG_PRINT&&console.log(e);
        let requiredErrors = {}
        e.response.data.err_msg.includes("username") ? (
          requiredErrors['userName'] = e.response.data.err_msg
        ):(
          requiredErrors['password'] = e.response.data.err_msg
        )
        setErrors(requiredErrors);
        setIsWaiting(false);
      })  
    } 
  };
  // const state = useSelector((state) => state);
  // if (state.session.token&&state.city.citySelected&&(state.product.products.length>0)) {
  //   return <Redirect to={referer} />;
  // }
  const renderLeft=()=>{
    return (
      <Container maxWidth="xs">
        <CssBaseline />
        <div className={classes.contentLeft}>      
          {/* <LockOutlinedIcon /> */}
          <img
            src="/resources/images/BC-Analytics-Logo-white-400.png"
            alt="BetterCart Analytics Logo"
            className={classes.logoLeft}
          />  
          <form className={classes.form} onSubmit={handleSubmit} noValidate >
            
            {errors['userName']&&(<div className={classes.errorMessage} >{errors['userName']}</div>)}
            {errors['password']&&(<div className={classes.errorMessage} >{errors['password']}</div>)}
            <TextField
              error={errors['userName']}
              className={classes.textBox}
              required
              label="UserName"
              variant="filled"
              fullWidth
              id="username"                     
              name="username"
              autoFocus
              onChange={handleOnChange}
              color="secondary"
            />     
            <TextField
              error={errors['userName']}
              className={classes.textBox}
              required
              label="Password"
              variant="filled"
              fullWidth
              name="password"
              type="password"
              id="password"
              autoFocus
              onChange={handleOnChange}
              color="secondary"
            />        
            
            <FormControlLabel
              control={<Checkbox value="remember" style={{color:"white"}} />}
              label="Remember me"
              color="secondary"              
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Sign In
              {isWaiting && (
              <CircularProgress size={24} className={classes.buttonProgress} />
              )}
            </Button>
            <div className={classes.mlinkContainer}>
                <MLink className={classes.mLink} href="./resetpassword/request" variant="body1">
                  Forgot password?
                </MLink>
                {/* <MLink className={classes.mLink} href="#" variant="body1">
                  {"Don't have an account? Sign Up"}
                </MLink> */}
            </div>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        
      </Container>
    );
  }
  
  return(
    <div className={classes.container}>
      <div className={classes.containerLeft}>
          {renderLeft()}
      </div>  
      <div className={classes.containerRight}>
      <RightPanel/>
      </div>
    </div>  

  )
}

