import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent,DialogActions, } from "../Dialog/DialogCustom";
import SelectedProductTitle from "../Menu/SelectedProductTitle";
import {  price100g } from "../../utility/utility";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { format } from "date-fns";
import { PriceComponent } from "../../utility/utility";
import axios from "axios";
import envConfig from "../../bin/env.config";
import { useSelector } from "react-redux";

import ExportButtons  from "../Common/ExportButtons";
import RequestPriceUp2DateButton from "../Common/RequestPriceUp2DateButton"
import Loading  from "../../components/Loading/Loading";

const useStyles = makeStyles((theme) => ({
  subTitle:{
    width:'100%',
    fontSize:'0.8em',
    fontStyle:'Italic'
  },
  productNameCell: {
    textTransform: "capitalize",
    color: theme.palette.secondary.main,
  },
}));
export default function HistoricalPricingAllDialog({
  productSelected,
  title,
  liveProductInputData,
  open,
  handleClose,
  isCurrent = true
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [limit, setLimit] = useState(2000);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("edited_at");
  const [data, setData] = useState([]);
  const [subTitle, setSubTitle] = useState("Store Address");
  
  const state = useSelector(state => state);
  const citySelected = state.city.citySelected; 
  const user = state.session.user
  const byBanner = state.store.byBanner
  const storesSelected = state.store.storesSelected
  const fromDate = state.week.fromDateSelected.date;  
  const toDate = state.week.toDateSelected.date;
  // console.log(fromDate)
  // console.log(toDate)
  const headersExport=[    
    { label: "Banner", key: "companyName" },   
    { label: "Store", key: "storeName" },   
    { label: "Address", key: "storeAddress" },   
    { label: "Product", key: "productName" },   
    { label: "Size", key: "size" },
    { label: "Collected date", key: "date" },   
    { label: "$/ea", key: "price" },
    { label: "$/100g", key: "price_100g" },    
    { label: "Promotion", key: "is_onsale" },    
  ];
  const productName = productSelected.name||'';
  // const filename = "historical-pricing.csv"
  const filename = "promo-vs-regular-historical-pricing-" + productName.replace(/[^a-z0-9]/gi, '_').toLowerCase()+".csv"
  
  const configExport={
    headers:headersExport,
    data:data,
    filename:filename,
    target:"_blank",
  }
  useEffect(() => {
    const fetchDataSource = async () => {      
      if (skip === 0) {
        setIsLoading(true);
      }
      const postData = {
        productMatchingGroupID: productSelected
          ? productSelected.id
          : 0,
        storeID: liveProductInputData.storeId,//Incase byBanner, storeID is company_id
        storeIDs: storesSelected
                  ? storesSelected.map((store) => store.id)
                  : [],
        byBanner: byBanner,  
      };
      // console.log(postData)
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/regular-price-in-store-history?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });
      if(result.data.data.length>0){
        let storeName = ''
        let storeAddress = ''
        if(!byBanner){
          const currStore = storesSelected.find((p) => p.id == Number(liveProductInputData.storeId)) 
          storeAddress = currStore?currStore.address:''
          storeName = currStore?currStore.name:''
        }
        setSubTitle(storeAddress)
        // console.log(result.data.data)
        let dataTemp = result.data.data.map((obj, i) => {
            return {
              collected_at: format(Date.parse(obj.edited_at+"T12:00:00.000Z") , "yyyy-MM-dd"),
              date: format(Date.parse(obj.edited_at+"T12:00:00.000Z") , "PP"),
              is_onsale:obj.is_onsale,
              size: obj.size,
              price: isCurrent?obj.current_price_ea:obj.regular_price_ea,
              price_100g: isCurrent?price100g(obj.current_price_ea,obj.standard_size):price100g(obj.regular_price_ea,obj.standard_size),
              product_id: obj.product_id,
              standard_size: obj.standard_size,
              unit: obj.unit,
              productName: productSelected.name,
              companyName: liveProductInputData.companyName,
              storeName: storeName,
              storeAddress: storeAddress  
            };
          });
          // console.log(dataTemp)
        if (skip === 0) {
          setData(dataTemp);
        } else {
          //   console.log(result.data.data);
          //   console.log('herer');
          setData([...dataTemp, ...result.data.data]);
        }
        if (skip === 0) {
          setLoadMore(result.data.data.length === limit);
          setIsLoading(false);
        }
      }  
      
    };
    liveProductInputData.storeId&& productSelected.id && fetchDataSource();
  }, [productSelected,liveProductInputData,byBanner, skip, order, orderBy]);
  
  return (
      <Dialog
        fullWidth={true}
        maxWidth={user&&user.user_type_id===1?"md":"sm"}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={open}
      >
        <DialogTitle id="dialog-title" onClose={handleClose}>
          {title}
          {subTitle&&(
            <div className={classes.subTitle}>{subTitle}</div>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <Table size="small">
            <TableHead >
              <TableRow>
                <TableCell colSpan={user&&user.user_type_id===1?"8":"5"} className={classes.productNameCell}>                  
                  {productSelected&&<SelectedProductTitle productSelected={productSelected} />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={user&&user.user_type_id===1?"8":"5"} style={{textAlign:"right"}}>
                {data.length>0 &&
                    <ExportButtons {...configExport}/>
                }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Collected date</TableCell>
                <TableCell>Size</TableCell>
                <TableCell  align="right">$/ea</TableCell>
                <TableCell  align="center">Promo</TableCell>
                <TableCell  align="right">$/100g</TableCell>
                {user&&user.user_type_id===1&&(
                  <>
                    <TableCell  align="right">Unit</TableCell>
                    <TableCell  align="right">Standard Size</TableCell>
                    <TableCell  align="right">Product Id</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading&&(
                <TableRow>
                  <TableCell colSpan={user&&user.user_type_id?7:4} style={{align:"center"}}>
                    <Loading />
                  </TableCell>
                </TableRow>
              )}
              {!isLoading&& (data.length > 0) &&
                data.map((row, i) => {
                  let inTimeRange = (row.collected_at>=fromDate)&&(row.collected_at<=toDate)
                  return (
                        <TableRow hover key={i} style={{backgroundColor:inTimeRange?"rgba(41,102,137,0.2)":""}}>
                          <TableCell>
                           {row.date}
                          </TableCell>
                          <TableCell>
                            {row.size}
                          </TableCell>
                          <TableCell align="right">
                            <PriceComponent value={row.price} style={{ color: row.is_onsale?"green":"#000" }}/>
                          </TableCell>
                          <TableCell align="center">
                            {row.is_onsale>0&&<span style={{color:"green"}}>P</span>} 
                          </TableCell>
                          <TableCell align="right">
                            <PriceComponent value={row.price_100g} />
                          </TableCell>
                          {user&&user.user_type_id===1&&(
                            <>
                          <TableCell align="right">{row.unit}</TableCell>
                          <TableCell align="right">{row.standard_size}</TableCell>
                          <TableCell align="right">{row.product_id}</TableCell>
                          </>
                          )}
                        </TableRow>
                    
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
       
      </Dialog>
  );
}
