import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";

// import Loading from "../Loading/Loading";
import ProviderRow from "./ProviderRow";
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "90vh",
    boxShadow: "none",
  },
  providerLogo:{
    maxHeight: "50px",
    maxWidth: "170px",
  }  
}));

export default function DeviceDetailTable({
  device,
  providers
}) {
  const classes = useStyles(); 
  return (
      <TableContainer component={Paper} className={classes.container}  square>
        <Table stickyHeader aria-label="devices">
          <TableHead>
            <TableRow >
              <TableCell align="left" ></TableCell>
              <TableCell align="center" >Company</TableCell>
              <TableCell align="center" >Price Trend</TableCell>
              <TableCell align="center" colSpan={2}>Device Return</TableCell>
              <TableCell align="center" colSpan={2}>Financing Return</TableCell>
              <TableCell align="center" colSpan={2}>Save on your device</TableCell>
            </TableRow>
            </TableHead>
          <TableBody>
            {providers &&
              providers.map((row, index) => {
                return (
                  <ProviderRow key={index} device={device} provider={row}/>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
  );
}
