import React from "react";
import { Link } from "react-router-dom";
import MLink from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import {Copyright} from '../components/Common/Common';


const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0",
    margin: "0",
    display: "flex",
    width: "100%",
    // height:'100vh',
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  containerRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    justifyItems: "center",
    width: "100%",
    height: "90vh",
    
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  contentRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
  },
  logoRight: {
    width: "50%",
    maxWidth: "160px",
    minWidth: "120px",
  },
  textRight: {
    fontSize: "3em",
    
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "bold",
  },  
  goBack:{
    color: '#fff',
		backgroundColor: '#00b04f',		
		fontSize: '20px',
		fontWeight: '600',
		lineHeight: '1.75',
	
		letterSpacing: '0.02857em',
		textTransform: 'uppercase',  
		maxWidth: '500px',
		marginLeft: 'auto',
		marginRight: 'auto',	
		marginTop:'40px',
		padding:'10px 20px 12px 20px',	
		display: 'block',
    textDecoration: 'none',
    '&:hover':{
      backgroundColor: 'rgb(0, 123, 55)'
    }
  }
}));

export default function NotFoundPage() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.containerRight}>
        <div className={classes.contentRight}>
          <img
            src="/resources/images/bc-cart-green-500.png"
            alt="BetterCart"
            className={classes.logoRight}
          />
          <div className={classes.textRight}>
            404 Error - Page Not Found
          </div>
          <Link className={classes.goBack} to="/">
            Go back to Homepage
          </Link>
          <Box mt={8}>
            <Copyright />
          </Box>
        </div>
      </div>
    </div>
  );
}
