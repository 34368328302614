import {SET_MENUS, MENU_ERROR} from '../types'
const menusDefaultState = {
  menus: [],
  loading:true
};

export default (state=menusDefaultState, action) => {
  switch (action.type) {
    case SET_MENUS :
      return {
        ...state,
        menus: action.payload,
        loading:false
      };
    // case 'CLEAR_CATEGORIES' :
    case MENU_ERROR:
      return{
          loading: false, 
          error: action.payload 
      }
    default:
      return state;
  }
};
