import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import envConfig from "../../bin/env.config";
import LinearProgress from '@material-ui/core//LinearProgress';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  summaryBox: {
    border: "2px solid #DDD",
    boxShadow: 'None',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: `0px 8px`,
    height: "50px",
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
  },
}));
export default function VolatilityBox({}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector(state => state);
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  const productSelected = state.product.productSelected;
  const storesSelected = state.store.storesSelected
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner

  const [dataSource, setDataSource] = useState("---");

  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const postData = {
        productMatchingGroupID: productSelected
          ? productSelected.id
          : 0,
        storeIDs: storesSelected.map((store) => store.id),
        bannerIDs: bannersSelected,  
        byBanner: byBanner,  
        fromDate: fromDate,
        toDate: toDate,
      };
      const apiUrl = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/summary-volatility`;
  
      try {
        const result = await axios.post(apiUrl, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });        
        setDataSource(result.data.data);
      } catch (e) {}
      setIsLoading(false);
    };
    productSelected.id && fetchDataSource();
  }, [productSelected,storesSelected,bannersSelected,byBanner, fromDate, toDate]);
  const title = "Volatility(SD)" 
  return (
    <Paper className={classes.summaryBox} title={title}>
      {isLoading ? (
        <>
          <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{title} </div>
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </>
      ) :(
        <>
          <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{title}</div>
          <span style={{ whiteSpace: 'nowrap' }}>{dataSource}</span>
        </>
      )}
    </Paper>  
  );
}
