import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
  root: {
      marginLeft:"10px", 
      padding:"0px 10px",
      borderWidth:"2px",
      '&:hover':{
        borderWidth:"2px",
      }    
  },
  label:{
      textTransform: "capitalize",
      fontSize: '1.25rem',       
      fontWeight: "500",
      '& >.MuiButton-iconSizeMedium > *:first-child ':{
        fontSize: '2rem',
      }
    }
  }));
// import StoreIcon from "@material-ui/icons/Store";

export default function SelectReportType({value,onSelected, disabled}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const reportTypes = [
    `All Products`,`Organic Products Removed`,`Multiplier Products Removed`,`Minus Organic and Multipliers`
  ]
  
  const handleChange = (reportType) => {    
    onSelected&&onSelected(reportType)
    setAnchorEl(null);
  };    

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick  = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (  
    <>
      <Button
        variant="outlined"
        classes={{root:classes.root, label:classes.label}}
        color="primary"
        onClick={handleClick}
        endIcon={<ExpandMoreIcon/>}
        disabled = {disabled}
      >
       {reportTypes[value-1]}
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {reportTypes.map((name,index) => (
          <MenuItem
            key={index+1}           
            selected={value-1 === index}
            onClick={() => handleChange(index+1)}
          >
            {name}
          </MenuItem>
        ))}
      </Menu>      
    </>
   
  );
}

