import React,{useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectOneProductDialog from "./SelectOneDialog";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { addMyProduct } from "../../store/actions/myProductAction";
const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "10px",
    padding: "0px 10px",
    borderWidth: "2px",
    "&:hover": {
      borderWidth: "2px",
    },
  },
  label: {
    textTransform: "capitalize",
    fontSize: "1.25rem",
    fontWeight: "500",
    "& >.MuiButton-iconSizeMedium > *:first-child ": {
      fontSize: "2rem",
    },
  },
}));
// import StoreIcon from "@material-ui/icons/Store";

export function AddMyProductButton({myProductIds,setAddWaiting,addCompleted}) {
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  // console.log(myProductIds)
  const filterProducts =   state.product.products.filter((x)=>{
                                          //filter all products in my_product
                                          let index  = myProductIds.indexOf(x.id)
                                          // x.id===9296&&console.log(index)&&console.log((index<0))
                                          return (index<0)
                                        });
  // console.log(filterProducts.length)
  const [products, setProducts] = React.useState(filterProducts);
  // console.log(products.length)
  
  const storesSelected = state.store.storesSelected;
  
  const handleClickOpen = () => {
    // alert('test');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAddClick = (product) => {
    setDisabled(true)
    setAddWaiting&&setAddWaiting(true)
    dispatch(addMyProduct(product.id)).then((result)=>{
      //success 
      // setAddWaiting(false)
      addCompleted(result)          
    })
    .catch(e => {
      //error
    }).finally(() => {
      let temp = filterProducts.filter(x=>x.id != product.id)
      setProducts(temp)
      setAddWaiting(false)    
      setDisabled(false)
    });    
  };
  return (
    <>
      {!state.product.loading && (
        <>
          <Button
            variant="outlined"
            classes={{ root: classes.root, label: classes.label }}
            color="primary"
            onClick={handleClickOpen}
            endIcon={<AddIcon />}
            disabled={disabled}
          >
            Add Product
          </Button>
          <SelectOneProductDialog
            items={products}
            setItemSelected={handleAddClick}
            open={open}
            handleClose={handleClose}
            storesSelected={storesSelected}
          />
        </>
      )}
    </>
  );
}
