import React , { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute({ component: Component, ...rest }) {
  const token = useSelector((state) => state.session.token);
  const citySelected = useSelector(state => state.city.citySelected); 
  const products = useSelector(state => state.product.products); 
  
  return (
    <Route
      {...rest}
      render={props =>
        token&&citySelected&&(products.length>0)? (
          <Component {...props} />
        ) : ( 
          <Redirect
            to={{ pathname: "/", state: { referer: props.location } }}
          />         
        )
      }
    />
  );
}

export default PrivateRoute;

