import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {  
  TableCell, 
  TableRow 
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';


import { PriceComponent,randomNumber } from "../../utility/utility";
import {YourPercent,Discrepancy} from "../Charts/Percent";
import RowDetailExpand from "./RowDetailExpand";
import ProductImage from "../Common/ProductImage.js";

const useStyles = makeStyles((theme) => ({
  root: {    
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
   
  },
  rowExpand:{
    '& > *': {
      borderTop:"3px solid green"
    },   
  },
  rowCollapse:{
    '& > *': {
      borderBottom:"3px solid green",
      padding:"0"  
    },
    backgroundColor: "rgba(0, 0, 0, 0.04)",  
      
  },
  showText:{
    textTransform:'capitalize '
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
}));
// function ProductImages({product}){
//   const classes = useStyles();
//   const addDefaultSrc = (event)=>{
//     event.target.src = '/resources/images/no-picture-available.png'
//   }
//   return (
//     <img className={classes.productImg} src={product.image_url} onError={addDefaultSrc}/>      
//     )
// } 
export default function RowDetail(props) {
  const classes = useStyles();
  const { row } = props;
  const [open, setOpen] = React.useState(false); 
  const handleClick = ()=>{
    setOpen(!open)
  }
  
  return (
    <>
          <TableRow hover className={clsx(classes.root, open && classes.rowExpand)}>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={handleClick}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="center">
              {/* <ProductImages product={row}/> */}
              <ProductImage  product_matching_group_id={row.product_matching_group_id} title={row.name} hide_multiple_icon width={'70px'} height={'70px'}/>
            </TableCell>
            <TableCell align="left" className={classes.showText}>{row.brand}</TableCell>
            <TableCell align="left" className={classes.showText}>{row.name}</TableCell>
            <TableCell align="center">{row.size}</TableCell>
            <TableCell align="center"><PriceComponent value={row.low_price} /></TableCell>
            <TableCell align="center"><PriceComponent value={row.high_price} /></TableCell>
            <TableCell align="center"><Discrepancy percent={row.difference}/></TableCell>
            <TableCell align="center"><YourPercent percent={row.your_percent}/></TableCell>                                    
          </TableRow>
          <TableRow className={ open && classes.rowCollapse}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <RowDetailExpand product_matching_group_id={row.product_matching_group_id}/>              
              </Collapse>                      
            </TableCell>
          </TableRow>
        </>
  );
}

