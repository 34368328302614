import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
// https://github.com/tsayen/dom-to-image/issues/210
const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: "10px",
    padding: "0px 10px",
    borderWidth: "2px",
    "&:hover": {
      borderWidth: "2px",
    },
  },
  label: {
    textTransform: "capitalize",
    fontSize: "1.25rem",
    fontWeight: "500",
    "& >.MuiButton-iconSizeMedium > *:first-child ": {
      fontSize: "2rem",
    },
  },
}));
// import StoreIcon from "@material-ui/icons/Store";

export default function CompareButton({onClick}) {
  const classes = useStyles();
  const handleOnClick = () => {
    onClick&&onClick()
  };
  return (
    <>
      <Button
        variant="outlined"
        classes={{ root: classes.root, label: classes.label }}
        color="default"
        onClick={handleOnClick}
      >
        Compare Trend
      </Button>
    </>
  );
}
