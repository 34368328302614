
import {GET_STORES, SELECT_STORES,GET_BANNERS, SELECT_BANNERS,SELECT_BY_BANNER, STORE_ERROR} from '../types'
const initialState = {
    stores:[],
    storesSelected:[],
    banners:[],
    bannersSelected:[],
    byBanner: false,
    loading:true,
}

export default function(state = initialState, action){

    switch(action.type){
        case GET_STORES:
            return {
                ...state,
                stores:action.payload,
                loading:false
            }       
        case SELECT_STORES:
                return {
                    ...state,
                    storesSelected:action.payload,
                    // byBanner:false,
                    loading:false
                }
        case GET_BANNERS:
            return {
                ...state,
                banners:action.payload,
                loading:false
            } 
        case SELECT_BANNERS:
            return {
                ...state,
                bannersSelected:action.payload,
                // byBanner:true,
                loading:false
            }    
        case SELECT_BY_BANNER:
            return {
                ...state,
                byBanner: action.payload,
                loading:false
            }                  
        case STORE_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state
    }

}