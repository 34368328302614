import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DetailChart from "./DetailChart";
import DetailProduct from "./DetailProduct";
import DetailPrices from "./DetailPrices";

const useStyles = makeStyles((theme) => ({
  root: {},
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ViewDetailDialog = forwardRef((props, ref) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { columns, reportType } = props;
  const [product, setProduct] = useState({
    pro_: "",
  });
  const onClose = () => {
    setOpen(false);
  };
  useImperativeHandle(ref, () => ({
    doOpen: doOpen,
  }));
  const doOpen = (row) => {
    setOpen(true);
    setProduct(row);
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <IconButton color="inherit" onClick={onClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit">
            {product.pro_}
          </Typography>
          <Typography variant="h6" color="inherit">
           &nbsp; &nbsp; [{product.pre_} - {product.cit_}]
          </Typography>
        </Toolbar>
      </AppBar>

      <Grid container >
        <Grid xs={6} container>
          <Grid  container direction="column" spacing={4} style={{marginLeft: '0px',marginRight: '0px'}}>
            <Grid item xs>
              <h3>Monthly report</h3>
              <DetailChart columns={columns} product={product} />
            
              <h3>Included products </h3>
              <DetailProduct
                columns={columns}
                product={product}
                reportType={reportType}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>              
              <DetailPrices
                columns={columns}
                product={product}
                reportType={reportType}
              />
        </Grid>
      </Grid>
    </Dialog>
  );
});
export default ViewDetailDialog;
