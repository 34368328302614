import React,{useState, useEffect} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Paper from '@material-ui/core/Paper';
import ProductImage from "../Common/ProductImage.js";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  productSummaryInformation:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",

  },
  summaryBox: {
    border: "2px solid #DDD",
    boxShadow: 'None',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: `0px 8px`,
    height: "50px",
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
  },
  
  titleContainer: {
    display: "flex",
    flexDirection: "column  ",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  productName: {
    textTransform: "capitalize",
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  productSize: {
    textTransform: "capitalize",
    fontFamily: "Roboto",
  },
  btnSelected: {
    textTransform: "none",
    marginLeft: theme.spacing(1),
  },
}));

const Toolbar = ({productSelected,summary}) => {
  const classes = useStyles();
  const [data, setData] = useState(summary);
  useEffect(() => {
    setData(summary)
  }, [summary]);
  return (
    <Grid container spacing={4}>
      <Grid item lg={9} sm={9} xl={9} xs={9} m={2}>
        <div className={classes.productSelectedButton}>
          <div className={classes.container}>
          <ProductImage   product_matching_group_id={productSelected.id} title={productSelected.name} width={'150px'} height={'150px'}/>
         
            
            <div className={classes.titleContainer}>
              <div className={classes.productName}>
                {/* #{productSelected.upc} - {productSelected.brandName&&`[${productSelected.brandName}]`} {productSelected.name} */}
                {productSelected.name}
              </div>
              <div className={classes.productSize}>{productSelected.size}</div>
              <div className={classes.productSummaryInformation}>
                <Paper className={classes.summaryBox}>
                  <b>Min {data&&data.currentMin&&`$${data.currentMin.price}`}</b>
                  <span>{data&&data.currentMin&&data.currentMin.company}</span>
                </Paper>
                <Paper className={classes.summaryBox}>
                  <b>Max {data&&data.currentMax&&`$${data.currentMax.price}`}</b>
                  <span>{data&&data.currentMax&&data.currentMax.company}</span>
                </Paper>                
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        container
        item
        lg={3}
        sm={3}
        xl={3}
        xs={3}
        m={2}
        alignItems="center"
        justifyContent="center"
      >
        <FormGroup row>
         
        </FormGroup>
      </Grid>
    </Grid>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
};

export default Toolbar;
