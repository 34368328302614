const local = {
  // API_ROOT: 'http://localhost:8081/api', 
  API_ROOT: 'https://gcp-api01.bettercart.ca/api', 
  // API_ROOT: 'https://api-dev.bettercart.ca/api', 
  // API_ROOT: 'https://api2.bettercart.ca/api',
};
const dev = {
  API_ROOT: 'https://gcp-api01.bettercart.ca/api',  
};

const prod = {
  API_ROOT: 'https://gcp-api01.bettercart.ca/api',  
};

const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod: (process.env.REACT_APP_STAGE === 'dev'?dev:local);


export default {
  ...config, 
  API_VERSION:'/v1',
  // DEV_CONSOLE_LOG_PRINT:process.env.REACT_APP_STAGE ==='local' 
  DEV_CONSOLE_LOG_PRINT:false 
}
