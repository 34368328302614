
import {SET_PRODUCTLISTS, SELECT_PRODUCTLIST, PRODUCTLIST_ERROR,SET_SINGLE_PRODUCT_PRICING,RESET_SINGLE_PRODUCT_PRICING} from '../types'
const initialState = {
    productLists:[],
    productListSelected:{},
    singleProductPricing:{
        currentMin:{
            value:0,
            chain: '' //company Name
        },
        currentMax:{
            value:0,
            chain: '' //company Name
        },
        historicalMin:{
            value:0,
            chain: '' //company Name
        },
        historicalMax:{
            value:0,
            chain: '' //company Name
        },
        stdList: [], 
        currentPriceList: [],  
    },
    loading:true,
}

export default function(state = initialState, action){

    switch(action.type){
        case SET_PRODUCTLISTS :
            return {
              ...state,
              productLists: action.payload,
              loading:false
            };    
        case SELECT_PRODUCTLIST:
            return {
                ...state,
                productListSelected:action.payload
            }
        case PRODUCTLIST_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }  
        case SET_SINGLE_PRODUCT_PRICING:
            return{
                loading: false, 
                ...state,
                singleProductPricing:action.payload
            } 
        case RESET_SINGLE_PRODUCT_PRICING:
            return{
                loading: false, 
                ...state,
                singleProductPricing:initialState.singleProductPricing
            }            
        default: return state
    }

}