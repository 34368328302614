import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  TableCell,
  TableRow,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import ReportListDetailProductGroup from "./ReportListDetailProductGroup";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },  
}));
export default function ReportListDetailRow({ item }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleClick}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{item.id}</TableCell>
        <TableCell>{item.name}</TableCell>
        <TableCell align="center">{item.cnt_groups}</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow className={open && classes.rowCollapse}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ReportListDetailProductGroup product={item} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
