import React, { useEffect, useState } from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "./Title";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import Loading from "../Loading/Loading"
import axios from "axios";
import envConfig from "../../bin/env.config";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  TrendingIcon: {
    fontSize: "120px",
  },
});

export function BiggestPriceDrop({ storesSelected }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    // console.log('here'+searchText);
    const fetchDataSource = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/analytics/biggest/drop?limit=1`;
      const result = await axios.post(url, {
        storeIDs: storesSelected.map((store) => store.id),
      });
      const {changed_percent}= result.data.data[0]
      setPercentage(changed_percent);
      setIsLoading(false);
    };
    fetchDataSource();
  }, [storesSelected]);
  return (
    <>
      {isLoading ? (
         <Loading/>
      ) : (
        <>
          <Title>Drops</Title>
          <Typography component="p" variant="h3" color="primary">
          {percentage.toFixed(2)}%
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            Biggest price drops
          </Typography>
          <TrendingDownIcon className={classes.TrendingIcon} color="primary" />
        </>
      )}
    </>
  );
}
export const BiggestPriceDropThisWeek = React.memo(BiggestPriceDrop);