import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import { MemoizedMenu } from "../components/Menu";
import Overview from "../components/Battleground/Overview";
import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { format,addDays, parseISO } from 'date-fns'
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    padding:'0px',
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function BattlegroundOverview(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const state = useSelector(state => state);
  const categories = state.category.categories
  const selectedCategory = categories&&categories.find(category=>category.selected);
  
  const fromDate = state.week.weekSelected.first;
  const toDate = format(addDays(parseISO(state.week.weekSelected.first),6),'yyyy-MM-dd');
  const storesSelected = state.store.storesSelected

  const postData = {
    from_date: fromDate,
    to_date: toDate,
    storeIDs: storesSelected
      ? storesSelected.map((store) => store.id)
      : [],
    // categoryId: props.match.params.id,
    limit: -1,
    province: state.city.citySelected.province,
    categoryId:selectedCategory?selectedCategory.id:0    
  };
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    const fetchDataSource = async () => {      
      // console.log('here'+searchText);
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/battleground`;
      const result = await axios.post(url, postData,
                                      {
                                        headers: {
                                          Authorization: state.session.token,
                                        },
                                      });
                                      
      let dataSourceInit = [];  
      result.data.data.forEach((record)=>{
        let divided = record.high_price - record.low_price;
        let divisor = record.voila_price - record.low_price;
        dataSourceInit.push({...record,
          difference: record.low_price===0?0:Math.trunc(100*(divided/record.low_price)),
          your_percent: divided===0?0:Math.trunc(100 * ( divisor / divided ))
        })
      })      
          
      setDataSource(dataSourceInit.sort((a,b)=>b.difference-a.difference));     
      setIsLoading(false);
    };
    // console.log(postData);
    fetchDataSource();
  }, [selectedCategory,storesSelected,fromDate]);
  return (
    <div className={classes.root}>
      <MemoizedMenu selectedCategoryEnable title={`with the Highest Price Variances for the Week of`} 
        weekSelectedButtonEnable
        selectedCityEnable
        selectedStoreEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Overview dataSource={dataSource} {...props}/>
            </>
          )}
        </Container>
      </main>
    </div>
  );
}
