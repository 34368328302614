import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { DialogTitle, DialogContent } from "../Dialog/DialogCustom";
import ChangePasswordForm from "./ChangePasswordForm";

export default function ChangePasswordDialog({open,handleClose}) {
  return (   
      <Dialog
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        open={open}
      >
        <DialogTitle id="dialog-title" onClose={handleClose}>
         Change your password      
        </DialogTitle>
        <DialogContent dividers>
            <ChangePasswordForm onClose={handleClose}/>
        </DialogContent>               
      </Dialog>
   
  );
}
