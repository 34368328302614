import React,{useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import {
  TableCell,
  TableRow,
  Typography,
  Link
} from "@material-ui/core";

import DeviceProviderCell from "./DeviceProviderCell";

export default function DeviceRow({device, providers}) { 
  const history = useHistory(); 
  const handleRowClick =()=>{
    history.push(`/sasktel-device-detail/${device.id}`)    
  }
  return (
    <TableRow>
      <TableCell align="left" style={{backgroundColor:'#f1f1f1'}}>
        <Link underline="hover"component="button"  nowrap onClick={handleRowClick} >
          {device.brand} {device.model} {device.size}
        </Link>
      </TableCell>
      {providers&&providers
        .map((row, index) => {
          return (
            <>
              {row.id==='sasktel'?
              (<DeviceProviderCell key={index} device={device} provider={row} displayColumns={{keepChangeDirection:true, keepToday:true}}/>
              ):(<DeviceProviderCell key={index} device={device} provider={row} displayColumns={{changeDirectionMin:true, keepToday:true, returnToday:true}}/>
              )}
            </>
          );
      })}                       
    </TableRow>
    
  );
}
