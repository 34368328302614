import React, {  useEffect }  from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectProvinceDialog from "./SelectProvinceDialog";
import FlagIcon from "@material-ui/icons/Flag";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from "react-redux";
import { truncate } from "../../utility/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    width:"100%",
    // padding:"0px 10px",
    margin:"8px 0px",
    border:"1px solid white",
    '&:hover':{
      border:"1px solid white",
    }    
},
label:{
    textTransform: "capitalize",
    color:"#fff",
    justifyContent:"space-between",
    // fontSize: '1.25rem',       
    // fontWeight: "500",
    '& >.MuiButton-iconSizeMedium > *:first-child ':{
      fontSize: '2rem',
    }
  }
}));

// import StoreIcon from "@material-ui/icons/Store";

export function SelectProvince() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const provinceSelected = useSelector(state => state.province.provinceSelected); 
  // const provinceSelected = 0; // useSelector(state => state.province.provinceSelected); 
   
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        classes={{root:classes.root, label:classes.label}}
        color="primary"
        disabled
        // onClick={handleClickOpen}
        endIcon={<ExpandMoreIcon/>}
        startIcon={<FlagIcon />}
        style={{border: '1px solid rgba(255, 255, 255, 0.5)'}}
      >        
        {truncate(provinceSelected.name,11,false)}
      </Button>
      <SelectProvinceDialog  open={open} handleClose={handleClose} />
    </>
  );
}
export const MemoizedSelectProvince = React.memo(SelectProvince);
