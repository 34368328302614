export const SELECT_PROVINCE = 'SELECT_PROVINCE';
export const PROVINCE_ERROR = 'PROVINCE_ERROR';

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';

export const GET_CITIES = 'GET_CITIES';
export const SELECT_CITY = 'SELECT_CITY';
export const CITY_ERROR = 'CITY_ERROR';

export const GET_STORES = 'GET_STORES';
export const GET_BANNERS = 'GET_BANNERS';
export const SELECT_STORES = 'SELECT_STORES';
export const SELECT_BANNERS = 'SELECT_BANNERS';
export const SELECT_BY_BANNER = 'SELECT_BY_BANNER';
export const STORE_ERROR = 'STORE_ERROR';

export const SET_COLUMNS = 'SET_COLUMNS';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

export const GET_WEEKS = 'GET_WEEKS';
export const SELECT_WEEK = 'SELECT_WEEK';
export const SELECT_FROM_DATE = 'SELECT_FROM_DATE';
export const SELECT_TO_DATE = 'SELECT_TO_DATE';

export const INIT_CART = 'INIT_CART';

export const SET_MENUS = 'GET_MENUS';
export const MENU_ERROR = 'MENU_ERROR';

export const SET_LOG_IN_USER = 'SET_LOG_IN_USER';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const LOG_OUT = 'LOG_OUT';
export const INIT_APP = 'INIT_APP';

export const SW_INIT = 'SW_INIT';
export const SW_UPDATE = 'SW_UPDATE';


export const INIT_REPORT = 'INIT_REPORT';

export const SET_PRODUCTLISTS = 'SET_PRODUCTLISTS';
export const SELECT_PRODUCTLIST = 'SELECT_PRODUCTLIST';
export const PRODUCTLIST_ERROR = 'PRODUCTLIST_ERROR';

export const SET_SINGLE_PRODUCT_PRICING = 'SET_SINGLE_PRODUCT_PRICING';
export const RESET_SINGLE_PRODUCT_PRICING = 'RESET_SINGLE_PRODUCT_PRICING';

export const ADD_MY_PRODUCT = 'ADD_MY_PRODUCT';
export const GET_MY_PRODUCTS = 'GET_MY_PRODUCTS';
export const MY_PRODUCT_ERROR = 'MY_PRODUCT_ERROR';

export const ADD_MY_PRODUCT_COMPARISON = 'ADD_MY_PRODUCT_COMPARISON';
export const GET_MY_PRODUCTS_COMPARISON = 'GET_MY_PRODUCTS_COMPARISON';
export const MY_PRODUCT_COMPARISON_ERROR = 'MY_PRODUCT_COMPARISON_ERROR';
export const SET_SHOW_REPORT_DATA = 'SET_SHOW_REPORT_DATA';
export const SET_SHOW_STORE_COUNT_COLUMN = 'SET_SHOW_STORE_COUNT_COLUMN';

export const GET_CUSTOMER_PRODUCTS = 'GET_CUSTOMER_PRODUCTS';
export const SELECT_CUSTOMER_PRODUCT = 'SELECT_CUSTOMER_PRODUCT';
export const CUSTOMER_PRODUCT_ERROR = 'CUSTOMER_PRODUCT_ERROR';


