import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux'
import store from './store/store'

import "./index.css";
import App from "./App";
import * as FullStory from '@fullstory/browser';
// import { SW_INIT, SW_UPDATE } from './store/types';
import * as serviceWorker from "./serviceWorker";

import { MuiThemeProvider, createTheme  } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {CssBaseline} from "@material-ui/core"
// src: https://material-ui.com/customization/themes/
const theme = createTheme({
  palette: {
    primary: {
      main: "#204F6C",
    },
    secondary: {
      main: "#00b04f",
      contrastText: "#fff",
    },
    primaryLight: {
      main: "#577fcc",
    },
    // ,
    // secondary: { main: '#11cb5f' }, // This is just green.A700 as hex.
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      lxl: 1500,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      label: {
        fontSize: '1rem',       
        fontWeight: "400",
      },
    },
  },
});
FullStory.init({ orgId: 'QQEDP' });
ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <CssBaseline/>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: SW_INIT }),
//   onUpdate: registration =>
//     store.dispatch({ type: SW_UPDATE, payload: registration }),
// });
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
