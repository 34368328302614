import React,{useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useDispatch, useSelector } from "react-redux";
import {
  setCategories
  } from "../../store/actions/categoryAction";
 
const useStyles = makeStyles((theme) => ({
  root: {
      marginRight:"10px", 
      padding:"0px 10px",
      borderWidth:"2px",
      '&:hover':{
        borderWidth:"2px",
      }    
  },
  label:{
      textTransform: "capitalize",
      fontSize: '1.25rem',       
      fontWeight: "500",
      '& >.MuiButton-iconSizeMedium > *:first-child ':{
        fontSize: '2rem',
      }
    }
  }));
// import StoreIcon from "@material-ui/icons/Store";

export default function SelectCategory({allCategories='All Categories'}) {
  const classes = useStyles(); 
  
  const categories = useSelector(state => state.category.categories);
  const selectedCategory = categories&&categories.find(category=>category.selected);
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);
  
  const handleOpenCategoryMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCategoryMenu = (id) => {
    setAnchorEl(null);
    // console.log('hello')
    // console.log(id)
    const categoriesTemp = categories.map(category=>{
        if(category.id===id){
            category.selected = true;
        }else{
          category.selected = false;
        }
        return category;
    });
    dispatch(setCategories(categoriesTemp));
  };
  return (  
    <>
      <Button
        variant="outlined"
        classes={{root:classes.root, label:classes.label}}
        color="primary"
        onClick={handleOpenCategoryMenu}
        endIcon={<ExpandMoreIcon/>}
      >
      {selectedCategory?selectedCategory.description:allCategories} 
      </Button>   
      <Menu
        id="category-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseCategoryMenu}
      >
        <MenuItem onClick={handleCloseCategoryMenu}>{allCategories}</MenuItem>
        {categories&&categories.map((category)=>
          <MenuItem key={category.id} selected={category.selected} onClick={()=>handleCloseCategoryMenu(category.id)}>{category.description}</MenuItem>
        )}
    </Menu>

    </>
   
  );
}

