import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";
import envConfig from "../bin/env.config";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { MemoizedMenu } from "../components/Menu";
import Loading from "../components/Loading/Loading";
import { format, addDays, parseISO } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { AddMyProductComparisonButton } from "../components/Menu/AddMyProductComparisonButton";
import Alert from "../components/Alert/Alert";
import ProductRow from "../components/MyProductsElectrical/ProductRow";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helpers } from "../utility/helpers";
import {
  removeMyProductComparison
} from "../store/actions/myProductAction";
import {
  selectProduct
} from "../store/actions/productAction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },
  sortLabel: {
    fontSize: "1rem",
  },
  tableContainer: {
    // padding: "16px",
    maxHeight: "calc(100vh - 64px)",
    boxShadow: "none",
  },
  tableRow:{
    // cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
    textTransform: 'capitalize',
    cursor: "pointer",    
  },
  trHeader: {
    height: "64px",
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding: "16px 8px",
  },
  thHeaderFirst: {
    backgroundColor: "#DDD",
    paddingLeft: "24px",
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  waitingRow:{
    backgroundColor: '#ddd',
    animationDuration: '2s',
    highlightColor: '#a9b7c1',
    height:'70px',
    width:'100%',
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingPicture:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    height:'70px',
    width:'70px',
    marginLeft:'auto',
    marginRight:'auto'
  }
}));
export function ProductImages({ product }) {
  const classes = useStyles();
  const addDefaultSrc = (event) => {
    event.target.src = "/resources/images/no-picture-available.png";
  };
  return (
    <img
      className={classes.productImg}
      src={product.image_url}
      onError={addDefaultSrc}
    />
  );
}
export function SortLabel(props) {
  const classes = useStyles();
  return (
    <TableSortLabel
      {...props}
      className={classes.sortLabel}
      IconComponent={(props) =>
        props.hideSortIcon ? "" : <FontAwesomeIcon icon={faSort} />
      }
    >
      {props.nowrap ? (
        <span style={{ paddingRight: "5px", whiteSpace: "nowrap" }}>
          {props.children}
        </span>
      ) : (
        <span style={{ paddingRight: "5px" }}>{props.children}</span>
      )}
    </TableSortLabel>
  );
}
export default function MyProductComparisonElectrical(props) {
  const classes = useStyles();
  const history = useHistory();  
  const alertPopup = useRef();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);  
  const [addWaiting, setAddWaiting] = useState(false);
  const state = useSelector((state) => state);
  const user =  state.session.user;
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  // const fromDate = state.week.weekSelected.first;
  // const toDate = format(
  //   addDays(parseISO(state.week.weekSelected.first), 6),
  //   "yyyy-MM-dd"
  // );
  const storesSelected = state.store.storesSelected;  
  const loadDetail = state.myProduct.showReportData;

  const groupId = props.match.params.id
  
  const [dataSource, setDataSource] = useState({});
  const [orderDirection, setOrderDirection] = useState(1);
  useEffect(() => {
    const fetchDataSource = async () => {
      const postData = {       
        storeIDs: storesSelected ? storesSelected.map((store) => store.id) : [],       
        groupId: groupId,
      };
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/my-product/comparison/list`;
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: state.session.token,
        },
      });
      //sort by size at the first time
      let dataSourceFirst =result.data.data
     
      let orderDirection = 1
      let column = 'size'
      let tempDataSource = [...dataSourceFirst.comparisonProducts]
      tempDataSource.sort((a,b) => ((a.product[column] > b.product[column]) ? 1 : ((b.product[column] > a.product[column]) ? -1 : 0))*orderDirection)
      let tempSource = {...dataSourceFirst,comparisonProducts:tempDataSource}
      setDataSource(tempSource);
      // console.log(tempSource)
      // console.log(tempSource.myProduct.product)
      setIsLoading(false);
    };
    groupId&&fetchDataSource();
    
  }, [storesSelected, groupId]);
  const handleSort = (column) => {
    setOrderDirection(-orderDirection);
    let tempDataSource = [...dataSource.comparisonProducts]
    column =='max'||column =='min'||column =='max_100g'||column =='min_100g'||column =='std'?
    tempDataSource.sort((a,b) => (a[column] - b[column])*orderDirection)
    :tempDataSource.sort((a,b) => ((a.product[column] > b.product[column]) ? 1 : ((b.product[column] > a.product[column]) ? -1 : 0))*orderDirection);
    setDataSource({...dataSource,comparisonProducts:tempDataSource});
  };
  
  const handleRemoveComparison = (groupId, groupComparisonId) => {
    setIsLoading(true);
    let tempDataSource = dataSource.comparisonProducts.filter(item => item.product.id != groupComparisonId)
    setDataSource({...dataSource,comparisonProducts:tempDataSource})
    dispatch(removeMyProductComparison(groupId,groupComparisonId)).then(()=>{      //success
      alertPopup.current&&alertPopup.current.doAlert("success", "Success! removed from the comparison list."); 
    })
    .catch(e => {
      alertPopup.current&&alertPopup.current.doAlert("error", `Sorry, something went wrong.`);       
      // //error
      // console.log(e)
    }).finally(() => {
      setIsLoading(false);
    });
  };
  const handleRowMyProductClick =(product)=>{
    // let selectedProduct= state.product.products.find(x =>x.id==product.id)
    // console.log(myProduct)
    dispatch(selectProduct(product))
    history.push(`/single-report-electrical`)
  }
  const handleRowClick =(product)=>{
    let selectedProduct= state.product.products.find(x =>x.id==product.id)
    // console.log(selectedProduct)
    dispatch(selectProduct(selectedProduct))
    history.push(`/single-report-electrical`)
  }
  const addCompleted=(product)=>{
    let tempDataSource = [...dataSource.comparisonProducts,product]
    setDataSource({...dataSource,comparisonProducts:tempDataSource})
    alertPopup.current&&alertPopup.current.doAlert("success", "Success! added to the comparison list.");
  }
  return (
    <div className={classes.root}>
      <MemoizedMenu
        goBackButtonEnable
        title={`${dataSource.myProduct&&`"${Helpers.titleCase(dataSource.myProduct.product.name) }" from `||""}`}
        formDateToDateSelectedButtonEnable
        selectedCityEnable
        selectedStoreEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>              
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                  square
                >
                  <Table stickyHeader aria-label="my products">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          className={classes.thHeader}
                        ></TableCell>
                        {/* <TableCell
                          align="left"
                          className={classes.thHeader}
                        ></TableCell> */}
                        <TableCell align="left" className={classes.thHeader}>
                          <SortLabel onClick={() => handleSort("fullname")}>
                            Name
                          </SortLabel>
                        </TableCell>
                        <TableCell align="left" className={classes.thHeader}>
                          <SortLabel onClick={() => handleSort("size")}>
                            Size
                          </SortLabel>
                        </TableCell>                  
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel hideSortIcon>Min $</SortLabel>                          
                        </TableCell>
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel hideSortIcon>Max $</SortLabel>                          
                        </TableCell>
                        {/* <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel hideSortIcon>Min $/100g</SortLabel>                          
                        </TableCell>
                        <TableCell align="left"  className={classes.thHeader}>                         
                          <SortLabel hideSortIcon>Max $/100g</SortLabel>
                        </TableCell> */}
                        <TableCell align="left"  className={classes.thHeader}>
                          <SortLabel hideSortIcon>Volatility</SortLabel>                          
                        </TableCell>                                           
                        <TableCell
                          align="left"
                          className={classes.thHeader}
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataSource.myProduct && (
                        <>
                          <ProductRow product={dataSource.myProduct} onClick={handleRowMyProductClick}  />
                             
                          <TableRow className={classes.thHeader}>
                            <TableCell
                              align="left"
                              colSpan={2}
                              className={classes.thHeader}
                            >
                              <span style={{fontSize: "1rem",fontWeight: '500'}}>Comparison products</span>
                            </TableCell>
                            <TableCell align="right" colSpan={7}>
                              {user&&user.company_id==1&&
                                <AddMyProductComparisonButton
                                  addCompleted ={addCompleted}
                                  setAddWaiting={setAddWaiting}
                                  groupId={dataSource.myProduct.product.id}
                                  comparisonProductIds={dataSource.comparisonProducts.map(x=>+x.product.id)}
                                />
                              }
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {isLoading ? (
                          <TableRow className={classes.tableRow}>
                              <TableCell colSpan={loadDetail?9:4} >
                                <Loading />
                              </TableCell>                                           
                          </TableRow>
                          ) : ( 
                          <>
                            {dataSource.comparisonProducts &&
                                dataSource.comparisonProducts.map((row, index) => {
                                  return (
                                    <ProductRow key={index} product={row} onClick={handleRowClick} onRemoveCommand={(groupComparisonId)=>handleRemoveComparison(dataSource.myProduct.product.id,groupComparisonId)}/>
                                  )}
                            )} 
                            {addWaiting&&(
                              <TableRow className={classes.tableRow}>
                                <TableCell >
                                  <span  className={classes.waitingPicture}>
                                  <CircularProgress color="success" />
                                  </span>
                                </TableCell>  
                                <TableCell colSpan={loadDetail?8:3} >
                                  <span  className={classes.waitingRow}>
                                    <Skeleton  duration={2} />
                                  </span>
                                </TableCell>                                           
                              </TableRow>
                            )}  
                          </>
                        )
                      }  
                    </TableBody>
                    <TableFooter></TableFooter>
                  </Table>
                </TableContainer>             
            </Grid>
          </Grid>
        </Container>
      </main>
      <Alert ref={alertPopup} />
    </div>
  );
}
