import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

 
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: '2px 4px',
    display: "flex",
    alignItems: "center",
    // marginBottom:'8px',
    backgroundColor: "#f6f6f6",
    border: "2px solid " + theme.palette.primary.main,
    borderRadius: "4px",    
  },
  searchIcon: {
    display:"flex",
    height: "100%",    
    alignItems: "center",
    minWidth: "64px",
    justifyContent: "center",
    zIndex: "1000",
    top: "0px",
    right: "0px",
    backgroundColor: theme.palette.primary.main,   
    color: "#fff",
  },
  searchButtonText: {
    fontSize: "1.8rem",
    fontWeight: "bold",    
    color: "#fff !important",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    fontSize:"1.3rem",
    lineHeight: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0rem"           
    },
  },  
  categoryContainer:{
    display:'flex',
  },
  iconButton: {
    padding: 8   
  },
  iconButtonCategoryMenu: {
    padding: 8,
    color: theme.palette.primary.main,
    display: "none", 
    [theme.breakpoints.down("sm")]: {
      display: "block"           
    },
  },
  buttonCategoryMenu:{
    whiteSpace: 'nowrap',
    fontSize:"1.3rem",
    lineHeight: "1.5rem",
    textTransform: 'capitalize',
    [theme.breakpoints.down("sm")]: {
      display: "none"           
    },
  },
  divider: {
    height: 28,
    margin: 4,
    marginRight: '10px'
  },
}));


export default function DeviceSearchBox({value, doSearch, placeholder, searchOnChange=true}) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(value);
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleClear = () => {
    setSearchValue("");
    doSearch("",0);
    // dispatch(clearSearchHistory());
  };
 
  
  const keyPressed = ({ key }) => {
    if (key === "Enter") {
      doSearch(searchValue); 
    }
  };
  
  
  return (
    <div className={classes.container}>
      <InputBase
        className={classes.inputRoot}
        placeholder={placeholder}
        onChange={handleChange}
        value={searchValue}
        onKeyPress={keyPressed}
        inputProps={{ "aria-label": "search" }}
        autoFocus={true}
      />
      <div className={classes.searchIcon}>
        {searchValue.length > 0 ? (
          <IconButton
            onClick={handleClear}
            className={classes.iconButton}
            aria-label="clear"
          >
            <ClearIcon className={classes.searchButtonText}/>
          </IconButton>
        ) : (
          <IconButton className={classes.iconButton} aria-label="search" onClick={()=>doSearch(searchValue,selectedCategoryId)}>
            <SearchIcon className={classes.searchButtonText}/>
          </IconButton>
        )}
      </div>
    </div>
  )   
}

