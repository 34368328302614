import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";
import Loading from "../../components/Loading/Loading";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  productImage: {
    width: 50,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 25,
    },
    cursor: "pointer",
  },
 
}));
export default function DetailProduct({ product, reportType }) {
  const classes = useStyles();
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    const postData = {
      reportType: reportType,
      monthly_product_id: product.pid_,
    };
    const fetchDataSource = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/monthly-report-pmg-detail`;
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: state.session.token,
        },
      });
      //prepare data
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    fetchDataSource();
  }, [product, reportType]);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer          
        >
          <Table
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Product name</TableCell>
                <TableCell>Brand</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Multiply</TableCell>
                <TableCell>Organic</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource &&
                dataSource.map((row, index) => {
                  return (
                    <TableRow>
                      <TableCell align="center">
                        <img
                          className={classes.productImage}
                          src={row.image_url}
                          // onClick={() => handGroupView(productMatching.id)}
                        />
                      </TableCell>
                      <TableCell>{row.fullname}</TableCell>
                      <TableCell>{row.brand_name}</TableCell>
                      <TableCell>{row.size}</TableCell>
                      <TableCell>{row.multiply}</TableCell>
                      <TableCell>{row.organic?'True':'False'}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
