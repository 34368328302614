import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "92vh",
    boxShadow: "none",
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
 
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableRow:{
    cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    }
  },
  trHeader:{
    height:'64px'
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
  productImgContainer: {
    display: "flex",
    justifyContent:"flex-start",
    width: "400px"
  },
  productImgColumn: {
    // flex: "20%",
    padding: "0px 5px",
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
  },
  noOfProducts:{
    fontSize: "1rem",
    fontWeight: "500",
    color: 'rgba(0, 0, 0, 1)'
  }
}));
export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={()=>(<FontAwesomeIcon icon={faSort} />)}
              >
                <span style={{paddingRight:'5px'}}>{props.children}</span>                
                </TableSortLabel>
  )
}
export function ProductImages({products}){
  const classes = useStyles();
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  return (
    <div className={classes.productImgContainer}>
    {products &&
     products.map((product) => {
      return (
        <div className={classes.productImgColumn}>
          <img className={classes.productImg} src={ product.image_url} onError={addDefaultSrc}/>
        </div>
      )}
      )}
    </div>
  )
} 
export default function Overview(props) {
  const classes = useStyles();
  const history = useHistory();
  const [dataSource, setDataSource] = useState(props.dataSource);
  const [orderDirection, setOrderDirection] = useState(1);
  const handleSort=(column)=>{

    setOrderDirection(-orderDirection);
    let tempDataSource = [...props.dataSource]
    column =='number_of_products'?
    tempDataSource.sort((a,b) => (a[column] - b[column])*orderDirection)
    :tempDataSource.sort((a,b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)*orderDirection);
    setDataSource(tempDataSource);
  }
  const handleRowClick =(storeId)=>{
    !props.isDiscontinued&&history.push(`/new-products-detail/${storeId}`);
  }
  var totalProduct = 0;
  return (
    <>
      <TableContainer component={Paper} className={classes.container} square>
        <Table stickyHeader aria-label="new products overview">
          <TableHead >
            <TableRow className={classes.trHeader}>
              <TableCell align="left" className={classes.thHeaderFirst}>
              <SortLabel onClick={()=>handleSort('company')}>Company</SortLabel>
              </TableCell>
              <TableCell align="left" className={classes.thHeader}>
              <SortLabel onClick={()=>handleSort('store')}>Store</SortLabel>
              </TableCell>
              <TableCell align="center" className={classes.thHeader} >
              <SortLabel onClick={()=>handleSort('number_of_products')}>{props.isDiscontinued?'# of Discontinued products':'# of New products'}</SortLabel>                
              </TableCell>
              <TableCell align="left" className={classes.thHeader}></TableCell>
              <TableCell align="right" className={classes.thHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            dataSource &&
              dataSource.map((row, index) => {
                totalProduct = totalProduct + row.number_of_products ;
                return (
                  <TableRow hover key={index} onClick={()=>handleRowClick(row.store_id)} className={classes.tableRow}>
                    <TableCell align="center">
                      <img className={classes.storeLogo} src={row.company_logo_link}/>
                    </TableCell>
                    <TableCell align="left">{row.store}</TableCell>
                    <TableCell align="center"><span className={classes.noOfProducts}>{row.number_of_products}</span></TableCell>
                    <TableCell align="left">
                      <ProductImages products={row.products}/>
                    </TableCell>
                    <TableCell align="right">
                      <FontAwesomeIcon icon={faChevronRight} size="3x" style={{color:'rgba(0, 0, 0, 0.1)'}} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
          <TableFooter className={classes.tableFooter}>
            <TableRow >
              <TableCell align="right" colSpan={2}><span className={classes.noOfProducts}>Total of {props.isDiscontinued?'Discontinued':'New'} products:</span></TableCell>
              <TableCell align="center" ><span className={classes.noOfProducts}>{totalProduct}</span></TableCell>
              <TableCell align="left" colSpan={2}></TableCell>
              {/* <TableCell align="left"  className={classes.tableFooter} colspan={showExtendedDetail?9:6}>Not displaying {dataSource &&(storesSelected.length-dataSource.length)} stores that don't carry this product.</TableCell> */}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
