import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";

import DeviceRow from "./DeviceRow";
import TableRowLoadMore from "../Common/TableRowLoadMore";
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "90vh",
    boxShadow: "none",
  },
  providerLogo:{
    maxHeight: "50px",
    maxWidth: "170px",
  }  
}));

export default function DevicesTable({
  devices,
  providers
}) {
  const classes = useStyles();
  const [loadMore, setLoadMore] = useState(false);
  const [limit, setLimit] = useState(10);
  const [devicesLoaded, setDevicesLoaded] = useState(devices&&devices.slice(0,limit));
  useEffect(() => {
    setLoadMore(devices&&devices.length>=limit);  
  }, [devices]);
  const doLoadMore = (isVisible) => {
    if(isVisible){
      let tempLimit = limit+10
      if(devices.length < tempLimit){
        tempLimit=devices.length
        setLoadMore(false)
      }
      setLimit(tempLimit)
      setDevicesLoaded(devices.slice(0,tempLimit))
    } 
  };
  // const [skip, setSkip] = useState(0);
  
  return (
    
      <TableContainer component={Paper} className={classes.container}  square>
        <Table stickyHeader aria-label="devices">
          <TableHead>
            <TableRow >
              <TableCell align="left" ></TableCell>
              {providers&&providers
                .map((row, index) => {
                  return (
                    <TableCell
                      align="center"
                      key={`column_${index}`}
                      colSpan={row.id==='sasktel'?2:3}
                      style={{backgroundColor:row.color, width:'170px'}}
                    >
                      <img src={row.logo_url} className={classes.providerLogo}/>
                    </TableCell>
                  );
              })}
            </TableRow>
            <TableRow >
              <TableCell align="center" style={{backgroundColor:'#dddddd'}}>Device</TableCell>
              {providers&&providers
                .map((row, index) => {
                  return (
                    <>
                      <TableCell align="center" style={{backgroundColor:row.color, width:'10px'}}></TableCell>
                      {row.id==='sasktel'?
                        <TableCell align="center" style={{backgroundColor:row.color, width:'160px', color:'#FFF'}}>Save on your device</TableCell>
                      :(<>
                        <TableCell align="center" style={{backgroundColor:row.color, width:'80px', color:'#FFF'}}>Device Return Pricing</TableCell>
                        <TableCell align="center" style={{backgroundColor:row.color, width:'80px', color:'#FFF'}}>Financing Pricing</TableCell>
                        </>)
                      } 
                    </>
                  );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {devicesLoaded &&
              devicesLoaded.map((row, index) => {
                return (
                  <DeviceRow key={index} device={row} providers={providers}/>
                );
              })}
            {loadMore && <TableRowLoadMore doLoadMore={doLoadMore} colSpan={providers.length + 1} />}  
          </TableBody>
        </Table>
      </TableContainer>
  );
}
