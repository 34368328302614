import React, { useState,useEffect,useContext } from "react";

import LineGraph from "../Charts/LineGraph";
import axios from "axios";
import envConfig from "../../bin/env.config";
import Loading from "../Loading/Loading";
import { useSelector } from "react-redux";
export default function LineGraphLoading({row, cart}) {
  const [isLoading, setIsLoading] = useState(true);
  const state = useSelector(state => state);
  const postData = {    
    storeID: row.store_id,
    productMatchingGroupIds: cart.map((product) => product.id),
  };
  
  const [dataSource, setDataSource] = useState([]);
  
  useEffect(() => {
    const fetchDataSource = async () => {      
      // console.log('here'+searchText);
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/cart-comparison-price-history`;
      const result = await axios.post(url, postData,
                                      {
                                        headers: {
                                          Authorization: state.session.token,
                                        },
                                      });
                                      
      let tempDataSource = result.data.data;
      setDataSource(tempDataSource);
      setIsLoading(false);
    };
    
    (dataSource.length===0)&&fetchDataSource();
  }, []);

  return (
    <>
    {isLoading ? (
      <Loading />
    ) : (
     <LineGraph storeProductPrices={dataSource} />
    )}  
    </>
  );
}
