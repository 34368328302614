import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { PriceComponent, printSize } from "../../utility/utility";
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
  TableRow,
  Typography,
} from "@material-ui/core";
import LineGraph from "../Charts/LineGraph";
import Loading from "../Loading/Loading";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import axios from "axios";
import envConfig from "../../bin/env.config";

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    // maxHeight: "calc(100vh - 128px)",
    maxHeight: "calc(100vh - 232px)",
    boxShadow: "none",
    overflow: "auto"
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
  lineGraphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  content: {
    // overflowX: "hidden",
    // overflowY: "auto"
  },
  trHeader: {
    height: "64px",
  },
  thHeader: {
    backgroundColor: "#DDD",
    fontSize: "1rem",
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
  currentSize: {
    whiteSpace: "nowrap",
  },
  currentUpdatedAt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
  },

}));

export default function MainPrice({ }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector((state) => state);
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;

  const storesSelected = state.store.storesSelected
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner
  const stores = state.store.stores
  const productSelected = state.customerProduct.productSelected;
  const user = state.session.user;
  const [dataSource, setDataSource] = useState([]);
  const [tableContainerMaxHeight, setTableContainerMaxHeight] = useState(0);

  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const postData = {
        customerProductID: productSelected
          ? productSelected.id
          : 0,
        storeIDs: storesSelected.map((store) => store.id),
        bannerIDs: bannersSelected,
        byBanner: byBanner,
        fromDate: fromDate,
        toDate: toDate,
      };
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/similar-pricing-tool/similar-price-in-store-electrical`;
      const result = await axios.post(url, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });
        let prepareData = result.data.data.map((row) => {
          // console.log(row)
          let retRow = {
            ...row,
            spps:[],
            currentUpdatedAt:'',           
            currentPrice100g:0,
          }
          let l = row.spp_record.length
          for (let i = 0; i < l; i++) {
            const items = row.spp_record[i].split(";");
            retRow.spps.push({
              created_at: items[0],
              current_price_100g: Math.round(+items[1] * 100) / 100
            });
          }
          const lastRecord = retRow.spps[0]     
          let tempDate = format(
            Date.parse(lastRecord.created_at+"T12:00:00.000Z"),
            "PP"
          ).split(",");                 
          retRow.currentUpdatedAt = tempDate[0] + " '" + tempDate[1].slice(-2);           
          retRow.currentPrice100g = +lastRecord.current_price_100g;
          return retRow; 
        });  
      setDataSource(prepareData);
      setIsLoading(false);
    };
    productSelected.id && fetchDataSource();
  }, [productSelected, storesSelected, bannersSelected, byBanner, fromDate, toDate]);
  // useEffect(() => {
  //   let countCompany = dataSource.length-7?(dataSource.length-7):0 
  //   let tmpChartHeight =  countCompany*25+250
  //   let tmpTableContainerMaxHeight = byBanner?`calc(100vh - ${tmpChartHeight+232}px)`:"calc(100vh - 232px)"
  //   if(byBanner&&(!expanded)){
  //     tmpTableContainerMaxHeight= "calc(100vh - 288px)"
  //   }
  //   setChartHeight(tmpChartHeight)
  //   setTableContainerMaxHeight(tmpTableContainerMaxHeight)
  // },[dataSource,byBanner,expanded])
  
  return (
    <>
     {isLoading ? (
              <Loading />
            ) : (
        <TableContainer component={Paper}
          style={{ maxHeight: `calc(100vh - 288px)`, boxShadow: "none", overflow: "auto" }}
          square>
          <Table stickyHeader aria-label="Price report">
            <TableHead>
              <TableRow className={classes.trHeader}>
                {/* <TableCell align="left" className={classes.thHeader}></TableCell> */}
                <TableCell align="center"className={classes.thHeader}>
                  <Typography variant="inherit">Banner</Typography>
                </TableCell>
                {(!byBanner) && (
                      <TableCell align="center"className={classes.thHeader}>
                        <Typography variant="inherit">Store</Typography>
                      </TableCell>
                )}
                <TableCell align="center"className={classes.thHeader}>
                  <Typography variant="inherit">Pricing Trend 100g</Typography>
                </TableCell>
                <TableCell align="center"className={classes.thHeader}>
                  <Typography variant="inherit">Price $/100g</Typography>
                </TableCell>
                
                <TableCell align="center"className={classes.thHeader}>
                  <Typography variant="inherit">Min $/100g</Typography>
                </TableCell>
                <TableCell align="center"className={classes.thHeader}>
                  <Typography variant="inherit">Max $/100g</Typography>
                </TableCell>
                <TableCell align="center"className={classes.thHeader}>
                  <Typography variant="inherit">Mean $/100g</Typography>
                </TableCell>
                <TableCell align="center"className={classes.thHeader}>
                  <Typography variant="inherit">Std $/100g</Typography>
                </TableCell>
                <TableCell align="center"className={classes.thHeader}>
                  <Typography variant="inherit">Mode</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSource &&
                dataSource.map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      <TableCell align="center">
                        {" "}
                        <img
                          className={classes.storeLogo}
                          src={row.company_logo_link}
                        />
                        { }
                      </TableCell>
                      {(!byBanner) && (
                        <TableCell align="left">
                          <div className={classes.storeName}>{row.store}</div>
                          <div className={classes.storeAddress}>
                            {row.store_address}
                          </div>
                        </TableCell>
                      )}  
                      <TableCell align="center">
                        <div className={classes.lineGraphContainer}>
                          <LineGraph
                            key={index}
                            storeProductPrices={row.spps}
                            columnField='current_price_100g'
                          />
                        </div>
                      </TableCell>                
                      <TableCell align="center">                        
                        <PriceComponent
                          value={row.currentPrice100g}
                          style={{ color: "#204F6C" }}
                        />
                        <div className={classes.currentUpdatedAt}>
                          {row.currentUpdatedAt}
                        </div>
                      </TableCell>                    
                      <TableCell align="center">
                        <PriceComponent
                          value={row.current_min_100g}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <PriceComponent
                          value={row.current_max_100g}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="inherit">
                          <PriceComponent
                          value={row.mean_100g}
                          style={{ color: "#204F6C" }}
                        />
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="inherit">{row.std_price_ea_100g ? `${row.std_price_ea_100g.toFixed(2)}` : '---'}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <PriceComponent
                          value={row.current_mode_100g}
                          style={{ color: "#204F6C" }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}

                {dataSource.length==0 && (
                              <>
                                <TableRow>
                                  <TableCell align="center" colSpan={9} style={{color:'#a39d9d'}} >
                                    <br/>
                                    <br/>
                                    <br/>
                                    <b>No data is currently available for this date range and region.</b>
                                    <br/>
                                    <br/>                    
                                    Please try expanding the date range or changing the region.
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}    
            </TableBody>
          </Table>
        </TableContainer>
        )}
    </>
  );
}
