import {SET_CATEGORIES, CATEGORIES_ERROR} from '../types'
import axios from "axios";
import envConfig from "../../bin/env.config";

export const setCategories = (categories = []) => ({
  type: SET_CATEGORIES,
  payload: categories
});


export const fetchCategories = () => {
  return (dispatch,getState) => {
    return new Promise((resolve, reject) => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/categories`;
      axios.get(url,{
        headers: {
          'Authorization': JSON.stringify(getState().session.token)
        } 
      }).then((result) => {
        let categories = [...result.data.data];
        categories.forEach(function(element) {
          element.selected = false;
        });
        // console.log(categories);
        dispatch({
          type: SET_CATEGORIES,
          payload: categories
        });
        resolve(categories);
      }).catch(error => {
        reject(error);
        dispatch({
          type: CATEGORIES_ERROR,
          payload: error,
        });
      })
    }); 
  };
};

