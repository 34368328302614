import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Checkbox } from "@material-ui/core";
import {DialogTitle,DialogContent,DialogActions } from '../Dialog/DialogCustom';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,  
  TableRow,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import {
  selectBanners
  } from "../../store/actions/storeAction";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgb(0 176 79 / 10%)",
      color: "#fff",
    },
  }, 
  storeLogo: {
    width: "auto",
    height: "30px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
}));
export default function SelectBannersDialog({
  open,
  handleClose,
}) {
  const classes = useStyles();
  const store = useSelector(state => state.store);
  const dispatch = useDispatch()

  const [selected, setSelected] = useState(store.bannersSelected);
  const handleChange = (isChecked, selectId) => {
    if (!isChecked) {
      setSelected([...selected,selectId]);      
    } else {
      setSelected(selected.filter((item) => item !== selectId));
    }
  };
  const handleChangeAll = (isChecked) => { 
    if (isChecked) {
      setSelected([])
    }else{
      setSelected([...store.banners.map(row=>row.company_id)])
    }    
  };
  const handleSave = () => { 
    dispatch(selectBanners(selected));
    handleClose();
  };
  const handleCancel = () =>{
    setSelected(store.bannersSelected);
    handleClose();
  }
  useEffect(() => {   
    setSelected(store.bannersSelected)    
  }, [store]);
  return (    
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Select Banners
        </DialogTitle>
        <DialogContent dividers>
          <Table size="small">
            {/* <TableHead>
              <TableRow>
                <TableCell align="right">#</TableCell>
                <TableCell align="center">
                  <Checkbox
                          id={`chk_banner_all`}
                          checked={selected.length ===store.banners.length}
                          onChange={() => handleChangeAll(selected.length ===store.banners.length)}
                  />
                </TableCell>
                <TableCell align="center">Logo</TableCell> 
                <TableCell align="left">Banner</TableCell> 
              </TableRow>
            </TableHead>              */}
            <TableBody>
              {store.banners.map((row, i) => {
                let isChecked = selected.some(el => el === row.company_id);
                return (
                  <TableRow key={i}
                  className={classes.tableRow} onClick={() => handleChange(isChecked, row.company_id)}>
                    <TableCell align="right">{i + 1}</TableCell>
                    <TableCell align="center">
                      <Checkbox
                        id={`chk_banner_${row.company_id}`}
                        checked={isChecked}
                        onChange={() => handleChange(isChecked, row.company_id)}
                      />
                    </TableCell> 
                    <TableCell align="center">
                        <img
                          className={classes.storeLogo}
                          src={row.company_logo_link}
                        />
                      </TableCell>                   
                    <TableCell>{row.company_name}</TableCell>                                
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button id="btn_banner_cancel" onClick={handleCancel} color="default">
            Cancel
          </Button>
          <Button id="btn_banner_cancel" autoFocus onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
   
  );
}
