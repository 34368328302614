import React, {useState} from "react";
import { MapContainer, TileLayer, CircleMarker, Popup, LayersControl,LayerGroup,FeatureGroup } from 'react-leaflet';
// import { GeoJSON } from 'react-leaflet';

import '../Map/map.css';
// import { Icon } from "leaflet";
import Legend from "../Map/Legend";
import EasyPrintControl from "../Map/EasyPrintControl";
// import canadaJson from "../Map/canada.json";

const popupContent = {
  textAlign: "center",
  height: "120px",
  marginTop: "30px"
};
const popupHead = {
  fontWeight: "bold",
  fontSize: "14px"
};

const popupText = {
  fontSize: "15px",
  marginBottom: "20px"
};

const okText = {
  fontSize: "15px"
};


export default function PricePointMap({points,priceRangeColors}) {
  const [map, setMap] = useState(null);
  // console.log(canadaJson)
  const getColor = (price)=> {
    for (var i=0; i < priceRangeColors.length; i++) {
      if ((+priceRangeColors[i].min <= price) && (+priceRangeColors[i].max >= price)) {
            return priceRangeColors[i].color;
      }
    }
    // let colorIndex =Math.trunc(price)+1;
    return priceRangeColors[0].color;
  }
  return (
    <MapContainer 
      center={[52.146973, -106.647034]} 
      zoom={5} 
      maxZoom={15}
      minZoom={5}
      scrollWheelZoom={true}
      whenCreated={setMap}
      >
      <TileLayer
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        url="https://basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
        // url="https://a.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png"
        // url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {/* 
      https://github.com/PaulLeCam/react-leaflet/issues/350
      this.refs.map.leafletElement.setMaxZoom(max)
      <LayersControl position="topright" >
      <LayersControl.Overlay checked name="Layer group with circles"> */}
      {/* 
      https://stackoverflow.com/questions/65676884/leaflet-how-can-i-show-layer-only-inside-a-polygon
      // import 'leaflet.snogylop/src/leaflet.snogylop.js'
        L.geoJson(data, {
          invert: true
      }).addTo(map); */}
          {/* <GeoJSON data={canadaJson} /> */}
          <LayerGroup>
      {points&&points.map((elem, i) => {
            return (
              <CircleMarker
                key={i}
                center={{lat:elem.lat, lng: elem.long}}
                pathOptions={{ color: getColor(elem.price) }}
                fillColor={getColor(elem.price)}
                fillOpacity={0.8}
                radius={5}>
                <Popup className="request-popup">
                  <div style={popupContent}>
                    <img
                      src={elem.company_logo_link}
                      width="150"
                      max-height="70"
                    />
                    <div className="m-2" style={popupHead}>
                      {elem.store}
                    </div>
                    {/* <span style={popupText}>
                      {elem.address}
                    </span> */}
                    <div className="m-2" style={okText}>
                      {`$`+elem.price.toFixed(2)}
                    </div>
                  </div>
                </Popup>
              </CircleMarker>              
            )
          })}
        </LayerGroup>
      {/* </LayersControl.Overlay>   
      </LayersControl> */}
          <Legend map={map} priceRangeColors={priceRangeColors}/>
          <EasyPrintControl map={map}/>
    </MapContainer>
  );
}