import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";
// import CircularProgress from "@material-ui/core/CircularProgress";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ProductImage from "../Common/ProductImage";
import {
  TableContainer,
  // Paper,
  Table,
  TableBody,
  TableCell,
  // TableHead,
  // TableFooter,
  TableRow,
  Typography,
} from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import envConfig from "../../bin/env.config";
import {
  selectProduct
} from "../../store/actions/productAction";
import Loading from "../Loading/Loading";
import  ProductAttributesInfo  from "../Product/ProductAttributesInfo";
const Accordion = withStyles({
  root: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(246, 246, 246,0.3)',
    padding: '0',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: 'rgba(0, 0, 0, .03)',
      color: "#fff",
    },
  },
}));
export default function ProductsIncluded() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const productSelected = state.customerProduct.productSelected;


  const [dataSource, setDataSource] = useState([]);
  const [expanded, setExpanded] = useState('panel1');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const apiUrl = `${envConfig.API_ROOT}${envConfig.API_VERSION}/similar-pricing-tool/products-included`;
      const postData = {
        customerProductID: productSelected
          ? productSelected.id
          : 0,
      };
      try {
        const result = await axios.post(apiUrl, postData,
          {
            headers: {
              Authorization: state.session.token,
            },
          });
        setDataSource(result.data.data);
      } catch (e) {
        console.log(e)
      }
      setIsLoading(false);
    };
    productSelected.id && fetchDataSource();
  }, [productSelected]);
  const handleRowClick = (product) => {
    let selectedProduct = state.product.products.find(x => x.id == product.id)
    // console.log(product)
    // console.log(state.product.products)
    // console.log(selectedProduct)
    dispatch(selectProduct(selectedProduct))
    history.push(`/single-report-comparison`)
  }
  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="products-included"
        id="products-included"
      >
        <Typography variant="inherit" style={{ fontSize: '1rem', fontWeight: '500' }}>{isLoading ? '...' : dataSource && dataSource.length} products included</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <>
          {isLoading ? (
            <Loading />
          ) : (
            <TableContainer style={{ maxHeight: `calc(100vh - 288px)`, boxShadow: "none", overflow: "auto" }}>
              <Table>
                <TableBody>
                  {dataSource && dataSource.map((productMatchingGroup, i) => (
                    <TableRow key={i} onClick={() => handleRowClick(productMatchingGroup)} className={classes.tableRow}>
                      {/* <TableCell align="center">{productMatchingGroup.id}</TableCell> */}
                      <TableCell align="center" width="20px">{productMatchingGroup.order_no?productMatchingGroup.order_no:(i + 1)}</TableCell>
                      <TableCell align="center" width="150px">
                        <ProductImage
                          image_url={productMatchingGroup.image_url}
                        // onClick={() => handGroupView(productMatching.id)}
                        />
                      </TableCell>
                      <TableCell align="left">{productMatchingGroup.name} {productMatchingGroup.size} {productMatchingGroup.brand_name}
                        <ProductAttributesInfo productId={productMatchingGroup.id} isPmg={true}/>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      </AccordionDetails>
    </Accordion>
  );
}
