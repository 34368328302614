import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../Dialog/DialogCustom";


import { useDispatch, useSelector } from "react-redux";
import {
  selectProvince
  } from "../../store/actions/provinceAction";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
  productNameCell: {
    textTransform: "capitalize",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
  },
}));
const provinces = [{code:" ",name:"Canada"},
{code:"AB",name:"Alberta"},
{code:"BC",name:"British Columbia"},
{code:"MB",name:"Manitoba"},
{code:"NB",name:"New Brunswick"},
{code:"NL",name:"Newfoundland and Labrador"},
{code:"NS",name:"Nova Scotia"},
{code:"ON",name:"Ontario"},
{code:"PE",name:"Prince Edward Island"},
{code:"QC",name:"Quebec"},
{code:"SK",name:"Saskatchewan"},
{code:"NT",name:"Northwest Territories"},
{code:"NU",name:"Nunavut"},
{code:"YT",name:"Yukon"}]
export default function SelectProvinceDialog({    
  open,
  handleClose,
}) {
  const classes = useStyles();
  // const province = useSelector(state => state.province);
  const dispatch = useDispatch()
  
  const [dataSource, setDataSource] = useState(provinces);
 
  const handleSelected = (selected) => {
    dispatch(selectProvince(selected));
    handleClose();
  };

  return (   
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="province-select-title"
        open={open}
      >
        <DialogTitle id="province-select-title" onClose={handleClose}>
          Province
        </DialogTitle>
        <DialogContent dividers>
          <Table stickyHeader size="small">
            <TableBody>             
              {dataSource.length > 0 &&
                dataSource.map((row, i) => {
                  return (
                    <TableRow
                      key={i}
                      onClick={() => handleSelected(row)}
                      className={classes.tableRow}
                    >
                      <TableCell className={classes.productNameCell}>
                        {row.name}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
        
      </Dialog>    
  );
}
