import React, { useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SelectStoresDialog from "./SelectStoresDialog";
import SelectBannersDialog from "./SelectBannersDialog";
import StoreIcon from "@material-ui/icons/Store";
import VisibilityIcon  from "@material-ui/icons/Visibility";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useDispatch,useSelector } from "react-redux";
import {
  selectByBanner
  } from "../../store/actions/storeAction";


const useStyles = makeStyles((theme) => ({
  root: {
    width:"100%",
    // padding:"0px 10px",
    margin:"8px 0px",
    border:"1px solid white",
    '&:hover':{
      border:"1px solid white",
    }    
},
label:{
    textTransform: "capitalize",
    color:"#fff",
    justifyContent:"space-between",
    // fontSize: '1.25rem',       
    // fontWeight: "500",
    '& >.MuiButton-iconSizeMedium > *:first-child ':{
      fontSize: '2rem',
    }
  }
}));
// import StoreIcon from "@material-ui/icons/Store";

export function SelectStores(props) {
  const [openStore, setOpenStore] = React.useState(false);
  const [openBanner, setOpenBanner] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();  
  const store =  useSelector(state => state.store) 
  const dispatch = useDispatch()
  // const [byBannerSelected, setByBannerSelected] = React.useState(store.byBanner);
  
  const handleStoreClickOpen = () => {
    setOpenStore(true);
  };
  const handleBannerClickOpen = () => {
    setOpenBanner(true);
  };
  const handleByBannerSelected = (selectValue) => {
    dispatch(selectByBanner(selectValue));
    closeByBanner();
  };
  const handleByBannerClickOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeByBanner = () => {
    setAnchorEl(null);
  };
  const handleStoreClose = () => {
    setOpenStore(false);
  };
  const handleBannerClose = () => {
    setOpenBanner(false);
  };
  return (
    <>
      <Button
        variant="outlined"
        classes={{root:classes.root, label:classes.label}}
        color="primary"
        onClick={handleByBannerClickOpen}
        endIcon={<ExpandMoreIcon/>}
        startIcon={<VisibilityIcon />}
      >
        {store.byBanner?'By Banner':'By Stores'}
      </Button>
      {store.byBanner?
        <Button
          variant="outlined"
          classes={{root:classes.root, label:classes.label}}
          color="primary"
          onClick={handleBannerClickOpen}
          endIcon={<ExpandMoreIcon/>}
          startIcon={<StoreIcon />}
        >
          {store.bannersSelected.length} Banners
        </Button>
        :
        <Button
          variant="outlined"
          classes={{root:classes.root, label:classes.label}}
          color="primary"
          onClick={handleStoreClickOpen}
          endIcon={<ExpandMoreIcon/>}
          startIcon={<StoreIcon />}
        >
          {store.storesSelected&&store.storesSelected.length} stores
        </Button>
      }
      
      <SelectBannersDialog {...props} open={openBanner} handleClose={handleBannerClose} />
      <SelectStoresDialog {...props} open={openStore} handleClose={handleStoreClose} />
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeByBanner}
      >       
        <MenuItem onClick={()=>handleByBannerSelected(true)} >By Banner</MenuItem>
        <MenuItem onClick={()=>handleByBannerSelected(false)}>By Store</MenuItem>
      </Menu>
    </>
  );
}

export const MemoizedSelectStores = React.memo(SelectStores);
