
import {GET_CITIES, SELECT_CITY, CITY_ERROR} from '../types'
const initialState = {
    cities:[],
    citySelected:{
        id: 890,
        province: "SK",
        name: "Saskatoon",
    },
    loading:true,
}

export default function(state = initialState, action){

    switch(action.type){

        case GET_CITIES:
            return {
                ...state,
                cities:action.cities,
                loading:false
            }
        case SELECT_CITY:
            return {
                ...state,
                citySelected:action.citySelected
            }
        case CITY_ERROR:
            return{
                loading: false, 
                error: action.payload 
            }
        default: return state
    }

}