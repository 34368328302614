import React, { useEffect } from "react";

import { format } from "date-fns";
import  CLine from "./CLine";

export default function LineGraphCompare({dataSource}) {
  // var presets = window.chartColors;
  
  const chartOptions = {
    plugins: { 
      legend: {
        position: 'bottom',
      },
    },  
    responsive: true,
    //aspectRatio:6,
    title: {
      display: true,
    },
    // interaction: {
    //   intersect: false,
    //   axis: 'x'
    // },   
    scales: {
      x:{
          display: true,         
        },
      y:{
          display: true,        
          ticks: { beginAtZero: false },
          title: {
            display: true,
            text: 'Price $'
          }
        },
    },    
  };
  const labels = [];
  const datasets = [];

  dataSource.labels.forEach((row, index) => {
    //each label
    let dataSourceOfLabel = [] 
    row.prices.forEach((p) => {
      let datePoint = format(Date.parse(p.created_at), "PP")
      if(index==0) labels.unshift(datePoint);
      dataSourceOfLabel.unshift(p.avg_price);
    });
    let data = {
      label: row.label,
      data: dataSourceOfLabel,  
      backgroundColor: row.backgroundColor||"rgba(0, 176, 79,0.4)",
      borderColor: row.borderColor||"rgba(0,176,79,1)",    
      // trendlineLinear: {
      //     style: "#2C4F69",
      //     lineStyle: "dotted",
      //     width: 3
      // }            
    } 
    datasets.unshift(data);
  });
  // console.log(dataSource);
  return (
    <CLine
      data={{labels: labels,
        datasets:datasets}}
      options={chartOptions}
      // trendLine={true}
      // width={100}
    />    
  );
}
