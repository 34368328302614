import {GET_PRODUCTS, SELECT_PRODUCT,PRODUCT_ERROR} from '../types'
import axios from 'axios'
import envConfig from "../../bin/env.config";
import {
  resetSingleProductPricing
} from "./reportAction";

export const fetchProducts = (storesSelected,default_pmg_id) => {
    return (dispatch,getState) => {
      return new Promise((resolve, reject) => {
        const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/analytics/products`;
        const postData = {
          storeIDs: storesSelected ? storesSelected.map((store) => store.id) : [],
        };
        // console.log("Loading Product");
        // console.log(postData);
        var products = [];
        axios.post(url, postData, {
          headers: {
            'Authorization': JSON.stringify(getState().session.token)
          } 
        }).then((result) => {
          products = result.data.data.map((item) => {
            return {
              ...item,
              brandName: item.brand || "",
              imageUrl: item.image || "",
            };
          })            
        }).then(()=>{
          dispatch( {
            type: GET_PRODUCTS,
            payload: products
          }) 
          //Set default selected product
          let defaultSelectProduct =  (default_pmg_id&&products.find(p=>p.id===default_pmg_id))||products[0]            
          dispatch( {
            type: SELECT_PRODUCT,
            payload: defaultSelectProduct
          })
          resolve(defaultSelectProduct);        
        }).catch(error => {
          // console.log("Begin"+"-"*100);
          // console.log(postData)
          // console.log(error)
          // console.log("End"+"-"*100);
          dispatch( {
              type: PRODUCT_ERROR,
              payload: error,
          })
          reject(error);
        })
      });
    };
};
export const selectProduct = (product) => {
  return (dispatch)=>{
    // console.log(product)
    dispatch(resetSingleProductPricing());
    return dispatch( {
      type: SELECT_PRODUCT,
      payload: product
    })
  }
}