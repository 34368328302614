import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
// import CircularProgress from "@material-ui/core/CircularProgress";
import {
  LinearProgress,
  Box,
  Paper,
  Popover,
  MenuList,
  MenuItem} from '@material-ui/core';
  
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';  
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  summaryBox: {
    border: "2px solid #DDD",
    boxShadow: 'None',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: `0px 8px`,
    height: "50px",
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    position: "relative" 
  },
  hoverClickPopUp: {
    border: "2px solid #DDD",
    boxShadow: 'None',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    padding: `0px 8px`,
    height: "50px",
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    position: "relative", 
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#DDD",
    },
    '&$selected': {
      backgroundColor: "#DDD",
    },
  },
  badge: {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "block",
    position: "absolute",
    background: "white",
    // background: "rgba(41,102,137,0.5)",
    // border: "2px solid #296689",
    border: "2px solid #DDD",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "-15px",
    right: "-15px",
    transition: "all .3s"
  },
}));
export default function SummaryBox({
  title,
  apiUrl, 
  whichType 
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const state = useSelector(state => state);
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date;
  // console.log(state)
  const productSelected = state.product.productSelected;
  const storesSelected = state.store.storesSelected
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner

  const [dataSource, setDataSource] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const postData = {
        productMatchingGroupID: productSelected
          ? productSelected.id
          : 0,
        storeIDs: storesSelected.map((store) => store.id),
        bannerIDs: bannersSelected,  
        byBanner: byBanner,  
        fromDate: fromDate,
        toDate: toDate,
        whichType:whichType  
      };
      try {
        const result = await axios.post(apiUrl, postData,
        {
          headers: {
            Authorization: state.session.token,
          },
        });
        //Check how many min, how many max
        if(result.data.data.length>0){
          let firstPrice = result.data.data[0].price
          let data =  result.data.data.filter((x)=>x.price==firstPrice);
          setDataSource(data);
        }else{
          setDataSource([]);
        }   
        // console.log(result)      
      } catch (e) {
        // console.log(e)
      }
      setIsLoading(false);
    };
    productSelected.id && fetchDataSource();
    
  // }, [productSelected,storesSelected]);
  }, [productSelected,storesSelected,bannersSelected,byBanner, fromDate, toDate]);
  // }, [productSelected,storesSelected,bannersSelected,byBanner]);
  
  return (
    <>
    <Paper className={dataSource.length>1?classes.hoverClickPopUp:classes.summaryBox} onClick={handleClick} >
      {isLoading ? (
        <>
          <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{title} </div>
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        </>
      ) :(
        <>
          <div style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{title} {`$${dataSource.length>0?dataSource[0].price.toFixed(2):'---'}`}</div>
          <span style={{ whiteSpace: 'nowrap', display:'flex',alignItems:'center' }}>{dataSource.length>0?dataSource[0].company_name:'---'}
              {dataSource.length>1&&(open?<ExpandLessIcon/>:<ExpandMoreIcon/>)}
          </span>
          {dataSource.length>1&&<span className={classes.badge}>{dataSource.length}</span>}
        </>
      )}      
    </Paper>
    
    {dataSource.length>1&&
      <Popover
        id={"summary-box-min-max-menu"}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper>
        <MenuList>
            {dataSource.map((row,i)=>
              <MenuItem key={`li-${i}`}>
              {row.company_name}
              </MenuItem>
            )}
        </MenuList>
        </Paper>
      </Popover>        
      }
    </>  
  );
}
