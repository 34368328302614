import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import {BiggestPriceDropThisWeek} from '../components/Dashboard/BiggestPriceDropThisWeek';
import {BiggestPriceIncreaseThisWeek} from '../components/Dashboard/BiggestPriceIncreaseThisWeek';
import ProductsAddedThisWeek from '../components/Dashboard/ProductsAddedThisWeek';
import ProductsRemovedThisWeek from '../components/Dashboard/ProductsRemovedThisWeek';
import {MemoizedMenu} from '../components/Menu';
import {Copyright} from '../components/Common/Common';
// import ChartReport from "../components/NewDiscontinued/ChartReport";
// import MonthReport from "../components/SingleReport/MonthReport";



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },  
  appBarSpacer: theme.mixins.toolbar, 
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    cursor:"pointer"

  },
  fixedHeight: {
    height: 240,
  },
  fixedHeightChart:{
    width: '92%'
  },
  greenBG:{
    // background: 'linear-gradient(60deg, #66bb6a, #43a047)',
    // color:'#fff'
  },
  blueBG:{
    borderColor: 'linear-gradient(60deg, #66bb6a, #43a047)',
    // color:'#fff'
  },
  dashboardLink:{
    '&:hover':{
      textDecoration: 'none'      
    }
  }

}));

export default function Dashboard(props) {
  const classes = useStyles();  
  const history = useHistory();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const biggestPriceDrop = clsx(classes.paper, classes.fixedHeight,classes.blueBG );
  const biggestPriceIncrease = clsx(classes.paper, classes.fixedHeight,classes.greenBG );
  function preventDefault(event) {
    event.preventDefault();
  }
  const goToURL = (route) => {
    history.push(route);
  };
  return (
    <div className={classes.root}>
      <MemoizedMenu title={"Dashboard"} {...props}
        selectedCityEnable
        selectedStoreEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
           
            {/* Biggest price drops this week */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={biggestPriceDrop} onClick={() => goToURL("/biggest")} >
                {(props.storesSelected.length>0) && <BiggestPriceDropThisWeek storesSelected={props.storesSelected} />}
              </Paper>
            </Grid>
            {/* Biggest price increases this week */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={biggestPriceIncrease} onClick={() => goToURL("/biggest")}>
              {(props.storesSelected.length>0) && <BiggestPriceIncreaseThisWeek storesSelected={props.storesSelected} />}
              </Paper>
            </Grid>
             {/* product removed this week */}
             <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper} onClick={() => goToURL("/new-discontinued")}>
                <ProductsRemovedThisWeek />
              </Paper>
            
            </Grid>
            {/* products added this week */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper className={fixedHeightPaper} onClick={() => goToURL("/new-discontinued")}>
                <ProductsAddedThisWeek />
              </Paper>
            </Grid>
           
             {/* Chart */}
             <Grid item xs={6} md={6} lg={6}>
              <Paper className={fixedHeightPaper} onClick={() => goToURL("/biggest")}>
                <div className={classes.fixedHeightChart}>
                {/* <ChartReport storesSelected={props.storesSelected}/> */}
                </div>                
              </Paper>
            </Grid>
             {/* Chart */}
            
            {/* Recent Orders */}
            {/* <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Orders />
              </Paper>
            </Grid> */}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}