import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {  
  TableCell, 
  TableRow 
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';


import { PriceComponent,randomNumber } from "../../utility/utility";
import {YourPercent,Discrepancy} from "../Charts/Percent";
import RowDetailExpand from "./RowDetailExpand";
import LineGraphLoading from "./LineGraphLoading";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {    
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
   
  },
  rowExpand:{
    '& > *': {
      borderTop:"3px solid green"
    },   
  },
  rowCollapse:{
    '& > *': {
      borderBottom:"3px solid green",
      padding:"0"  
    },
    backgroundColor: "rgba(0, 0, 0, 0.04)",  
      
  },
  showText:{
    textTransform:'capitalize '
  },
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    // marginLeft: "auto",
    // marginRight: "auto",
    display: "block",
  },
  matchedText:{
    color:'#4EAC5A',
    fontSize:'1rem',
    fontWeight:'600'
  },
  missMatchedText:{
    color:'#E03531',
    fontSize:'1rem',
    fontWeight:'600'
  }
}));
function ProductImages({product}){
  const classes = useStyles();
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  return (
    <img className={classes.productImg} src={product.image_url} onError={addDefaultSrc}/>      
    )
} 
export default function RowDetail(props) {
  const classes = useStyles();
  const { row } = props;
  const cart = useSelector(state => state.cart.cart);
  const [open, setOpen] = React.useState(false); 
  const handleClick = ()=>{
    setOpen(!open)
  }
  
  return (
    <>
          <TableRow hover className={clsx(classes.root, open && classes.rowExpand)}>
            <TableCell>
              <IconButton aria-label="expand row" size="small" onClick={handleClick}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="left">
                <img className={classes.storeLogo} src={row.company_logo_link}/>
            </TableCell>
            <TableCell align="left" className={classes.showText}>{row.store_name}</TableCell>
            <TableCell align="center" className={classes.showText}><span className={(row.matched-cart.length>=0)?classes.matchedText:classes.missMatchedText}>{row.matched}/{cart.length}</span></TableCell>
            <TableCell align="center"> 
              <div className={classes.lineGraphContainer}>
                    <LineGraphLoading row={row} cart={cart} />
              </div>
            </TableCell>
            <TableCell align="center"><PriceComponent value={row.total_amount} /></TableCell>
            <TableCell align="center"><YourPercent percent={row.percent_expensive}/></TableCell>                                    
          </TableRow>
          <TableRow className={ open && classes.rowCollapse}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <RowDetailExpand product={row}/>              
              </Collapse>                      
            </TableCell>
          </TableRow>
        </>
  );
}

