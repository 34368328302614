import React,{useEffect, useState} from "react";
import { useTheme } from '@material-ui/core/styles';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { format } from "date-fns";
import CLine from "../Charts/CLine";
import { hexToRgbA } from "../../utility/utility";

export default function BannersLineGraph({ bannerDataLineGraph, companyColours }) {
  // var presets = window.chartColors;
  
  const chartOptions = {
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
		      padding:16
        }
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    // aspectRatio: 6,
    title: {
      display: false,
    },
    // layout: {
    //  padding: 16
    // },
    // interaction: {
    //   intersect: false,
    //   axis: 'x'
    // }, 
    spanGaps: true,
    scales: {
      x: {
        display: true
      },
      y: {
        display: true,
        ticks: {
          beginAtZero: false,// Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return '$' + value.toFixed(2);
          }
        },

      },
    },
  };
  
  const labels = []
  const data = [];
  bannerDataLineGraph.bannerDatas.forEach((bannerData) => {
    const avgPriceMonthData = {};
    bannerDataLineGraph.labels.forEach((label) => {
      avgPriceMonthData[label] = bannerData.avgPriceMonthData[label]
    })
    let bannerDataSource = {
      label: bannerData.companyName,
      data: Object.values(avgPriceMonthData),
      borderColor: companyColours[bannerData.companyId],
      backgroundColor: hexToRgbA(companyColours[bannerData.companyId],'0.5'),
    }
    data.push(bannerDataSource)
  });
  
  // console.log(data)
  bannerDataLineGraph.labels.forEach((label) => {
    labels.push(format(Date.parse(label+"T12:00:00.000Z"), "MMM yyyy"))
  })
  const dataSource = {
    labels: labels,
    datasets: data
  };
  const theme = useTheme();

  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const upMd = useMediaQuery(theme.breakpoints.up('sm'));
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const widthChart = upLg?"1200px":(upMd?"768px":(upSm?"500px":"100%"))
  const countCompany = data.length-7?(data.length-7):0 
  const height =  countCompany*25+250
  return (
  <div style={{
                      width: "100%", backgroundColor: "#f6f6f6", display: "flex",
                      justifyContent: "center", alignItems: "center"
                    }}>
                      <div style={{ width: widthChart, height:`${height}px` }}>
                          <CLine
                            data={dataSource}
                            options={chartOptions}
                          />        
                      </div>
                    </div>
        
  );
}
