import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import  ProductAttributesInfo  from "../Product/ProductAttributesInfo";
import  ProductImage  from "../Common/ProductImage";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  
  titleContainer:{
    display: "flex",
    flexDirection:"column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  productName: {
    textTransform: "capitalize",
    fontWeight:"bold",
    fontFamily: "Roboto"
  },
  productSize: {
    textTransform: "capitalize",
    fontFamily: "Roboto"
  },
}));
// import StoreIcon from "@material-ui/icons/Store";

export default function SelectedProductTitle() {
  const classes = useStyles();
  const productSelected = useSelector(state => state.product.productSelected);  
  return (
    <div className={classes.container}>
      <ProductImage product_matching_group_id={productSelected.id} title={productSelected.name}
       width={'150px'} height={'150px'} hide_multiple_icon={true}/>                                
     
      <div className={classes.titleContainer}>
        <div className={classes.productName}>
        {/* #{productSelected.upc} - {productSelected.brandName&&`[${productSelected.brandName}]`} {productSelected.name} */}
        {productSelected.name}
        </div>
        <div className={classes.productSize}>{productSelected.size}</div>
        <ProductAttributesInfo productId={productSelected.id} isPmg={true}/>
      </div>
    </div>
  );
}

