import React, { useEffect, useRef } from 'react'
import {Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale,Tooltip, Legend } from 'chart.js';
import chartTrendline from "chartjs-plugin-trendline";

Chart.register(chartTrendline, LineController, LineElement, PointElement, LinearScale, Title,CategoryScale,Tooltip, Legend );
export default function CLine({ data ,options}) {
  const chartRef = useRef(null);
  const labelPrice = (tooltipItem) => {   
    return `$${tooltipItem.parsed.y.toFixed(2)}`;
  };
  useEffect(() => {
    let myChart = new Chart(chartRef.current, {
      type: 'line',
      data: data,
      options: {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {  
          tooltip: {
            callbacks: {
              label: labelPrice,
            }
          }
        },
        ...options
      }
    });  
    myChart.update();
  }, []);

  return (
      <canvas ref={chartRef} className={'chartjs-render-monitor-cline'} style={{backgroundColor:"#f6f6f6", paddingBottom:"5px", paddingTop:"5px"}}/>
  )
}
