import React, { useState, useEffect } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import { MemoizedMenu } from "../components/Menu";
import Toolbar from "../components/SimilarProductPricing/Toolbar.js";
import ProductsIncluded from "../components/SimilarProductPricing/ProductsIncluded";
import MainPrice from "../components/SimilarProductPricing/MainPrice";
import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  toolBarSpacer: {
    display: "block",
    minHeight: '168px'    
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },  

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function SimilarProductPricing({goBackButtonEnable}) {
  const classes = useStyles();

  const state = useSelector(state => state);
  const productSelected = state.customerProduct.productSelected;
    
  // if (!productSelected) {
  //   return <Redirect to="/login" />;
  // }
  return (
    <div className={classes.root}>
      <MemoizedMenu goBackButtonEnable={goBackButtonEnable} title={"Similar Product Pricing"} 
          selectedCustomerProductEnable 
          selectedCityEnable
          selectedStoreEnable
          formDateToDateSelectedButtonEnable
          />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {productSelected && (
          <Container maxWidth={false} className={classes.container}>
            <Toolbar columnButton={false}/>
            <div className={classes.toolBarSpacer} />
            <ProductsIncluded/>  
            <Divider />
            
            <Grid container spacing={3}>
              {/* Chart */}

              <Grid item xs={12} md={12} lg={12}>
                
                  <MainPrice/>
              </Grid>
            </Grid>
          </Container>
        )}
      </main>
    </div>
  );
}
