import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import { MemoizedMenu } from "../components/Menu";
import Overview from "../components/CartComparison/Overview";
import CartPanel from "../components/CartComparison/CartPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    padding:'0px',
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function CartComparison() {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <MemoizedMenu title={`Shopping Cart Comparisons for the Week of`} weekSelectedButtonEnable 
      selectedCityEnable
      selectedStoreEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <CartPanel  />
          <Overview />
        </Container>
      </main>
    </div>
  );
}
