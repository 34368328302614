import {SET_MENUS, MENU_ERROR} from '../types'
import axios from "axios";
import envConfig from "../../bin/env.config";

export const fetchMenus =  (userMenu) => {
  return (dispatch) => {
    let menus = userMenu.map(uMenu=>uMenu.code)
    dispatch({
      type: SET_MENUS,
      payload: menus
    });   
  };
};
// export const fetchMenus = () => {
//   return (dispatch,getState) => {
//     return new Promise((resolve, reject) => {
//       const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/analytics/menus`;
//       axios.get(url, {
//         headers: {
//           'Authorization': JSON.stringify(getState().session.token)
//         } 
//       }).then((result) => {
//         let menus = [...result.data.data];
//         dispatch({
//           type: SET_MENUS,
//           payload: menus
//         });
//         resolve(menus);
//       }).catch(error => {
//         reject(error);
//         dispatch({
//           type: MENU_ERROR,
//           payload: error,
//         });
//       })
//     });

//   };
// };

